import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CommitIcon,
  DotsHorizontalIcon,
  CodeIcon,
  Pencil1Icon,
} from '@radix-ui/react-icons'
import {
  DropdownMenu,
  DropdownMenuSub,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuCheckboxItem,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useUpdateDrafts } from 'api/index'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import Loader from '@/components/ui/Loader'
import { Badge } from '@/components/ui/badge'
import { CopyItem } from 'components/ui/DropdownMenu'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import DataTableRowEditorTags from 'components/template-tags'
import { LevelOptions, LabelOptions, ContentLangs } from 'config'
import { cn } from '@/lib/utils'

export const DataTableRowActions = ({ row }) => {
  const data = row.original

  return (
    <div className="flex items-center gap-2">
      <Button asChild>
        <Link to={`/review/${data.id}`}>
          <CommitIcon className="w-4 h-4 mr-2" /> <span>Review</span>
        </Link>
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <CopyItem data={data.id} />
          <CopyItem data={data.shortId}>Copy shortId</CopyItem>
          <DropdownMenuSeparator />
          <DataTableRowUpdateLevel row={row} />
          <DataTableRowUpdateLabels row={row} />
          <DataTableRowEditorTags row={row} useUpdateDrafts={useUpdateDrafts} />
          <DataTableRowEditorLangs row={row} />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const DataTableRowUpdateLevel = ({ row }) => {
  const initData = row.original?.ext?.level
  const { handleUpdate, isMutating } = useUpdateDrafts(row.id)

  const handleUpdateLevel = (e, level) => {
    e.preventDefault()
    handleUpdate('level', level, false)
  }
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <CodeIcon className="w-4 h-4 mr-2" /> Set Level
        {isMutating && 'ing...'}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {LevelOptions.map((level) => (
            <DropdownMenuCheckboxItem
              key={level.name}
              checked={level.code === initData}
              onSelect={(e) => handleUpdateLevel(e, level.code)}
            >
              {level.name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}

const DataTableRowUpdateLabels = ({ row }) => {
  const initData = row.original?.ext?.labels || []
  const { handleUpdate, isMutating } = useUpdateDrafts(row.id)

  const handleAddLabels = (e, value) => {
    e.preventDefault()
    handleUpdate('addLabel', value, false)
  }
  const handleRemoveLabels = (e, value) => {
    e.preventDefault()
    console.log('remove')
    handleUpdate('removeLabel', value, false)
  }
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <CodeIcon className="w-4 h-4 mr-2" /> Set Labels
        {isMutating && 'ing...'}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {LabelOptions.map((label) => (
            <DropdownMenuCheckboxItem
              key={label.name}
              checked={initData.includes(label.code)}
              onSelect={(e) =>
                initData.includes(label.code)
                  ? handleRemoveLabels(e, label.code)
                  : handleAddLabels(e, label.code)
              }
            >
              {label.name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}

const DataTableRowEditorLangs = ({ row }) => {
  const data = row.original
  const curLangs = data?.ext?.contentLangs || []
  const [open, setOpen] = useState(false)
  const { handleUpdate, isMutating } = useUpdateDrafts(data.id)

  const handleUpdateFn = (value) => {
    if (curLangs.includes(value)) {
      handleUpdate('removeLang', value, false)
    } else {
      handleUpdate('addLang', value, false)
    }
  }

  const handleAllFn = () => {
    if (curLangs.length) {
      ContentLangs.slice(1).map((lang) =>
        handleUpdate('removeLang', lang.code, false),
      )
    } else {
      ContentLangs.slice(1).map((lang) =>
        handleUpdate('addLang', lang.code, false),
      )
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger className="w-full">
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <Pencil1Icon className="mr-2 h-4 w-4" /> Edit Langs
          </DropdownMenuItem>
        </DialogTrigger>
        <DialogContent className="max-h-screen overflow-y-auto">
          <DialogHeader className={'flex'}>
            <DialogTitle>Edit Langs</DialogTitle>
          </DialogHeader>
          <div className="d-flex flex-wrap">
            <Badge
              className={'me-1'}
              variant={curLangs.length < 44 ? 'outline' : ''}
              onClick={handleAllFn}
            >
              <small>Slelect All</small>
            </Badge>
            {ContentLangs.slice(1).map((item) => (
              <Badge
                key={item.name}
                className={'me-1'}
                variant={!curLangs.includes(item.code) ? 'outline' : ''}
                onClick={() => {
                  handleUpdateFn(item.code)
                }}
              >
                <small>
                  {item.name}-{item.code}
                </small>
              </Badge>
            ))}
          </div>
          <div
            className={cn(
              'grid grid-cols-4 items-center gap-4',
              isMutating ? '' : 'invisible',
            )}
          >
            <Label></Label>
            <div className="col-span-3 flex items-center gap-2">
              <Loader /> Updating...
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
