import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { columns } from './data-table-column'

const Onlines = () => {
  const { tag } = useParams()
  const { data, isLoading, isValidating } = useSWR(['/frames', { tag }])

  const table = useReactTable({
    data: data,
    columns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  if (isLoading || isValidating) return <Loader />
  if (!data) return <EmptyDatas />
  return (
    <>
      <Card>
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

export default Onlines
