import { cn } from '@/lib/utils'
import logoImg from 'assets/logo.png'
import React from 'react'
import { useRole } from '../hooks/auth'

const Nav = React.forwardRef(({ sticky, className, name, ...props }, ref) => {
  const isAdmin = useRole(`admin`)
  return (
    <header
      ref={ref}
      className={cn(
        'top-0 w-full border-b border-border bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 p-1 gap-2',
        sticky && 'sticky top-0 z-50',
        className,
      )}
    >
      <div className="container mx-auto h-14 flex items-center">
        <a
          className="mr-6 flex items-center space-x-2"
          href={isAdmin ? '/admin' : '/personal'}
        >
          <img
            src={logoImg}
            alt="Logo"
            className="d-inline-block align-text-top w-8 h-8"
          />
          <span className="hidden font-bold sm:inline-block">{name}</span>
        </a>
        <div
          className="flex flex-1 items-center justify-between space-x-2 md:justify-end"
          {...props}
        />
      </div>
    </header>
  )
})

Nav.displayName = 'Nav'

export default Nav
