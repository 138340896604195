import React from 'react'
import MyLink from 'components/ui/MyLink'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'
import { DataTableRowActions } from './data-table-row-action'

export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Note',
    accessorKey: 'note',
  },
  {
    header: 'Level',
    accessorKey: 'level',
  },
  {
    header: 'Dlc',
    accessorKey: 'dlcCount',
    cell: ({ row }) => (
      <>
        <MyLink to={`/admin/taglib/${row.original.name}/dlcs`}>
          ({row.original.dlcCount})
        </MyLink>
      </>
    ),
  },
  {
    header: 'frames',
    accessorKey: 'frameCount',
    cell: ({ row }) => (
      <>
        <MyLink to={`/admin/taglib/${row.original.name}/templates`}>
          ({row.original.frameCount})
        </MyLink>
      </>
    ),
  },
  {
    header: 'UpdateTime',
    accessorKey: 'updateTime',
    cell: ({ row }) => (
      <DisplayUpdateTime updateTime={row?.original?.updateTime} />
    ),
  },
  {
    header: 'Actions',
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
