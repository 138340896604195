import React, { useRef, useState, useCallback } from 'react'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { cn } from '@/lib/utils'
import AvatarPicker from './AvatarPicker'
import Skeleton from '@/components/ui/skeleton'
import { PlusIcon } from '@radix-ui/react-icons'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import useSWRMutation from 'swr/mutation'
import { putFetcher } from 'api/swr'
import { selectFileReader } from 'commons'
import { mutate } from 'swr'
import Loader from '@/components/ui/Loader'
const TemplatePreview = ({ form, template }) => {
  const onSubmit = useCallback(
    (avatar) => {
      form.setValue('preview', avatar.url) // 更新form的preview字段
    },
    [form],
  )
  const onSubmitA = useCallback(
    (avatar) => {
      form.setValue('previewA', avatar.url) // 更新form的preview字段
    },
    [form],
  )
  const previewUrl = form.watch('preview', template.preview)
  const previewAUrl = form.watch('previewA', template.previewA)

  return (
    <FormField
      control={form.control}
      name="preview"
      render={({ field }) => (
        <FormItem className="space-y-1">
          <FormLabel>Preview</FormLabel>
          <FormDescription>Choose a cover for the display.</FormDescription>
          <FormMessage />
          <FormControl>
            <div
              className={cn(
                'flex flex-wrap rounded-md border-2 border-muted bg-popover p-1 gap-2',
                'hover:bg-accent hover:text-accent-foreground',
                '[&:has([data-state=checked])]:border-primary',
              )}
            >
              <AvatarPicker value={previewUrl} onSubmit={onSubmit}>
                {previewUrl ? (
                  <img
                    alt="template preview"
                    className="aspect-square w-48 h-48 rounded-md object-cover"
                    src={previewUrl}
                  />
                ) : (
                  <div>
                    <Skeleton
                      className={cn(
                        'h-48 w-48 rounded-md flex flex-col items-center justify-center',
                        'group text-gray-400 hover:text-primary',
                      )}
                    >
                      <PlusIcon className="w-3/5 h-3/5" />
                      <span className="text-center">Select preivew</span>
                    </Skeleton>
                  </div>
                )}
              </AvatarPicker>
              <AvatarPicker value={previewAUrl} onSubmit={onSubmitA}>
                {previewAUrl ? (
                  <img
                    alt="template preview"
                    className="aspect-square w-48 h-48 rounded-md object-cover"
                    src={previewAUrl}
                  />
                ) : (
                  <div>
                    <Skeleton
                      className={cn(
                        'h-48 w-48 rounded-md flex flex-col items-center justify-center',
                        'group text-gray-400 hover:text-primary',
                      )}
                    >
                      <PlusIcon className="w-3/5 h-3/5" />
                      <span className="text-center">Select preivewA</span>
                    </Skeleton>
                  </div>
                )}
              </AvatarPicker>
              <UpdatePreivew
                template={template}
                onSubmit={onSubmit}
                type={'uploadPreview'}
              />
              <UpdatePreivew
                template={template}
                onSubmit={onSubmitA}
                type={'uploadPreviewA'}
              />
              <Input {...field} className={'hidden'} />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const UpdatePreivew = ({ template, onSubmit, type }) => {
  const ref = useRef(null)
  const { trigger, isMutating } = useSWRMutation(
    `/templates/${template.id}`,
    putFetcher,
  )

  const [selectedFile, setSelectedFile] = useState(null)

  const handleClick = () => {
    if (ref.current) {
      ref.current.value = null
      ref.current.click()
    }
  }

  const handleSelectFile = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const cover = await selectFileReader(file)
      setSelectedFile(cover)
    }
  }

  const handleResetFile = () => {
    if (ref.current) {
      ref.current.value = null
    }
    setSelectedFile(null)
  }

  const handleUpload = () => {
    const value = JSON.stringify({ preview: selectedFile.original })
    trigger(
      { key: type, value },
      {
        onSuccess: (res) => {
          mutate(['/templates/' + template.id])
          handleResetFile()
          if (res.data.preview) onSubmit(res.data.preview)
        },
      },
    )
  }

  if (selectedFile) {
    return (
      <div className="flex flex-col items-center">
        <img
          alt="template cover"
          className="aspect-square w-48 h-48 rounded-md object-cover"
          src={selectedFile.original}
          onClick={handleClick}
        />
        <div className="flex gap-2 mt-2">
          {isMutating ? (
            <Loader>Uploading preview...</Loader>
          ) : (
            <>
              <Button size="sm" onClick={handleResetFile}>
                reset
              </Button>
              <Button variant="outline" size="sm" onClick={handleUpload}>
                Upload
              </Button>
            </>
          )}
        </div>
        <input
          type="file"
          ref={ref}
          className="hidden"
          onChange={handleSelectFile}
          accept=".jpg, .png, .jpeg, .webp"
        />
      </div>
    )
  }
  return (
    <div>
      <Skeleton
        className={cn(
          'h-48 w-48 rounded-md flex flex-col items-center justify-center',
          'group text-gray-400 hover:text-primary',
        )}
        onClick={handleClick}
      >
        <PlusIcon className="w-3/5 h-3/5" />
        <span className="text-center"> Upload {type.slice(6)}</span>
      </Skeleton>
      <input
        type="file"
        ref={ref}
        className="hidden"
        onChange={handleSelectFile}
        accept=".jpg, .png, .jpeg, .webp"
      />
    </div>
  )
}

export default TemplatePreview
