import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Badge } from '@/components/ui/badge'
import { cn } from '@/lib/utils'
import { MagicWandIcon, OpacityIcon, PersonIcon } from '@radix-ui/react-icons'
import { Description, H3 } from 'components/ui/Title'
import { CptGrid } from 'Editor/BottomPanel/LayerPanel/ui'
import React, { useMemo } from 'react'
import useSWR from 'swr'
import Cpt from './Cpt'
import UpdatePrice from './UpdatePrice'
const replacer = (key, value) => {
  if (
    [
      'components',
      'name',
      'cover',
      'id',
      'toning',
      'bodymark',
      'watermark',
      'z',
      'gid',
      'group',
    ].includes(key)
  ) {
    return undefined
  }
  return value
}

const LayerPanel = ({ draft, errors }) => {
  const { data: groups = [] } = useSWR(['/layer-groups'])
  const layers = useMemo(() => JSON.parse(draft?.layers) || [], [draft.layers])
  const withGroups = useMemo(() => {
    return groups
      .map((group) => {
        return layers
          .filter((layer) => layer.gid === group.id)
          .map((layer) => ({ ...layer, group }))
      })
      .filter((g) => g.length)
  }, [groups, layers])

  const proCpts = useMemo(() => {
    return layers
      .map((layer) => {
        return layer.components.filter((component) => component.pro > 0)
      })
      .flat(1)
  }, [layers])
  const allCpts = useMemo(() => {
    return layers
      .map((layer) => {
        return layer.components
      })
      .flat(1)
  }, [layers])
  return (
    <div className="w-full h-full space-y-4 rounded-md border p-4 flex flex-col">
      <Description className="flex justify-between">
        <span>list order by &#34;group&#34; + &#34;play index&#34;</span>
        <span>
          {proCpts.length}/{allCpts.length}
        </span>
      </Description>
      <Accordion type="multiple">
        {layers.map(
          (layer) =>
            !layer.gid && (
              <Item
                key={layer.id}
                layer={layer}
                error={errors.some((item) => item.id === layer.id)}
              />
            ),
        )}
        {withGroups.map((layers) =>
          layers.map((layer) => (
            <Item
              key={layer.id}
              layer={layer}
              error={errors.some((item) => item.id === layer.id)}
            />
          )),
        )}
      </Accordion>
    </div>
  )
}

const Item = ({ layer, error }) => {
  return (
    <AccordionItem value={layer.id}>
      <AccordionTrigger className="py-2">
        <div className="flex items-center gap-4 w-full md:flex-wrap">
          <H3 className="w-6" variant="outline">
            {layer.z}
          </H3>
          <img
            src={`https://img.zthd.io/an1/acs/${layer.cover}`}
            alt={layer.name}
            className="row-span-2 w-16 h-16 mr-2"
          />
          <div className="flex flex-col items-center gap-2 w-24">
            <H3 className={cn(error && 'text-destructive')}>id:{layer.id}</H3>
            {layer.group && <Badge variant="outline">{layer.group.name}</Badge>}
          </div>
          <div className="flex flex-1 items-center gap-2">
            <OpacityIcon
              className={cn(
                'w-5 h-5',
                layer.watermark === 1 ? 'text-primary' : 'text-gray-400',
              )}
            />
            <PersonIcon
              className={cn(
                'w-5 h-5',
                layer.bodymark === 1 ? 'text-primary' : 'text-gray-400',
              )}
            />
            <MagicWandIcon
              className={cn(
                'w-5 h-5',
                layer.toning ? 'text-primary' : 'text-gray-400',
              )}
            />
          </div>

          <div className="flex items-center">
            <Description>{JSON.stringify(layer, replacer, 2)}</Description>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <Cpts layer={layer} />
      </AccordionContent>
    </AccordionItem>
  )
}

const Cpts = ({ layer }) => {
  const cpts = layer.components
  return (
    <CptGrid>
      {cpts.map((cpt) => (
        <div className="flex flex-col" key={cpt.id}>
          <Cpt layer={layer} cpt={cpt} cpts={cpts} />
          <UpdatePrice cpt={cpt} />
        </div>
      ))}
    </CptGrid>
  )
}

export default LayerPanel
