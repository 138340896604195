import React from 'react'
import Dropdown from 'components/ui/Selector'

const ITEMS = [
  { code: null, name: 'select clientVer' },
  { code: '0', name: 'cv-0' },
]

const CustomDropdown = ({ value, update }) => {
  return (
    <Dropdown
      datas={ITEMS}
      showLabel
      labelName="ClientVer"
      value={value}
      update={update}
    />
  )
}

export default CustomDropdown
