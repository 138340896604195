import React from 'react'
import useSWR from 'swr'
import Skeleton from '@/components/ui/skeleton'
import MyLink from 'components/ui/MyLink'

export const Columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: 'Cover',
    cell: ({ row }) => <FrameCover id={row.original.params_val} />,
  },
  {
    header: 'Preivew',
    cell: ({ row }) => <FramePreview id={row.original.params_val} />,
  },
  {
    header: 'Tid',
    cell: ({ row }) => <FrameID id={row.original.params_val} />,
  },
  {
    header: 'Open Rate',
    cell: ({ row }) => row.original.pv_dau_ratio,
  },
  {
    header: 'Complete Rate',
    cell: ({ row }) => row.original.pv_ratio,
  },
  {
    header: 'Publisher',
    cell: ({ row }) => <FramePublisher id={row.original.params_val} />,
  },
  {
    header: 'Publish Time',
    accessorKey: 'min_date',
  },
  {
    header: 'Quality',
    cell: ({ row }) => (
      <>
        {row.original.pv_dau_ratio >= 0.17
          ? '优质'
          : row.original.pv_dau_ratio >= 0.13
            ? '正常'
            : '低质'}
      </>
    ),
  },
]

const FrameID = ({ id }) => {
  return <MyLink to={`/editor/${id}`}>{id}</MyLink>
}
const FrameCover = ({ id }) => {
  const { data } = useSWR([`/frames/${id}`])
  return (
    <>
      {data ? (
        <img
          alt="..."
          className="aspect-square rounded-md object-cover w-24 h-24"
          src={data.cover}
        />
      ) : (
        <Skeleton className="h-24 w-24 rounded-xl" />
      )}
    </>
  )
}
const FramePreview = ({ id }) => {
  const { data } = useSWR([`/frames/${id}`])
  return (
    <>
      {data ? (
        <img
          alt="..."
          className="aspect-square rounded-md object-cover w-24 h-24"
          src={data.preview}
        />
      ) : (
        <Skeleton className="h-24 w-24 rounded-xl" />
      )}
    </>
  )
}
const FramePublisher = ({ id }) => {
  const { data } = useSWR([`/frames/${id}`])
  if (!data) return
  return data.publisher
}
