import React, { useMemo, useState } from 'react'
import useSWR, { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { delFetcher } from 'api/swr'
import { cn } from '@/lib/utils'
import { useUpdateLayerIcons } from 'api'
import {
  DotsHorizontalIcon,
  TrashIcon,
  UpdateIcon,
} from '@radix-ui/react-icons'
import Loader from '@/components/ui/Loader'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import Selector from 'components/ui/Selector'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog'
import { Checkbox } from '../../@/components/ui/checkbox'

export const DataTableRowActions = ({ row }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DataTableRowDelete row={row} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const DataTableRowDelete = ({ row }) => {
  const data = row.original
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation('/layericons', delFetcher, {
    onSuccess: () => {
      mutate(['/layericons'])
      toast({
        title: 'Layer icon Deleted',
        description: 'The layer icon has been successfully deleted.',
      })
    },
  })

  const handleDelete = () => {
    trigger({ id: data.id })
  }

  //TODO: add confirmation dialog
  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete Layer icon',
      description: 'Are you sure you want to delete this layer icon?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem onSelect={confirm}>
      {isMutating ? (
        <>
          <UpdateIcon className="mr-2 h-4 w-4" />
          <span>Deleteing...</span>
        </>
      ) : (
        <>
          <TrashIcon className="mr-2 h-4 w-4" />
          <span>Delete</span>
        </>
      )}
    </DropdownMenuItem>
  )
}

export const DataTableRowText = ({ row, entityKey, type }) => {
  const data = row.original

  const { handleUpdate, isMutating } = useUpdateLayerIcons(data.id)

  const [text, setText] = useState(data[entityKey] || '')
  const [isOpen, setIsOpen] = useState(false)

  const disabled = !text || isMutating || data[entityKey] === text
  const onSubmit = () => {
    if (disabled) {
      return null
    }
    handleUpdate(entityKey, text, false)
    setIsOpen(false)
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <div
            onClick={() => setIsOpen(true)}
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
            )}
          >
            {data[entityKey]}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {entityKey}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {entityKey}
              </Label>
              <Input
                className="col-span-3"
                value={text}
                type={type || 'text'}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || !text || text === data[entityKey]}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const DataTableRowSelect = ({ row, entityKey, type }) => {
  const data = row.original

  const { data: LayerGroups = [] } = useSWR(['/layer-groups'])

  const { handleUpdate, isMutating } = useUpdateLayerIcons(data.id)
  const [text, setText] = useState(data[entityKey] || '')
  const [isOpen, setIsOpen] = useState(false)
  const disabled = isMutating || data[entityKey] === text

  const Options = useMemo(() => {
    if (entityKey == 'gid') {
      return LayerGroups.map((item) => ({
        code: item.id,
        name: item.name,
      }))
    } else if (entityKey == 'max') {
      return Array.from({ length: 4 }, (v, i) => ({
        code: i + 1,
        name: i + 1,
      }))
    }
  }, [entityKey, LayerGroups])

  const onSubmit = () => {
    if (disabled) {
      return null
    }
    handleUpdate(entityKey, text, false)
    setIsOpen(false)
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <div
            onClick={() => setIsOpen(true)}
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
            )}
          >
            {Options?.find((item) => item.code === text)?.name}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {entityKey}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {entityKey}
              </Label>
              <Selector
                id={entityKey}
                className="col-span-3"
                datas={Options}
                update={(e) => setText(e)}
                value={text}
                labelName={entityKey}
                placeholder={`please select ${entityKey}`}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || text === data[entityKey]}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const DataTableRowCheckbox = ({ row, entityKey, type }) => {
  const data = row.original

  const { handleUpdate, isMutating } = useUpdateLayerIcons(data.id)

  const onCheckedChange = (value) => {
    if (isMutating) return
    handleUpdate(entityKey, value, false)
  }

  return (
    <div className="flex justify-center items-center">
      {isMutating ? (
        <Loader className="w-4 h-4" />
      ) : (
        <>
          <Checkbox
            className="border-black/60 data-[state=checked]:border-primary"
            checked={data[entityKey]}
            onCheckedChange={onCheckedChange}
          />
        </>
      )}
    </div>
  )
}

export const DataTableRowOpt = ({ row, entityKey, type }) => {
  const data = row.original

  const { handleUpdate, isMutating } = useUpdateLayerIcons(data.id)

  const onCheckedChange = (value) => {
    if (isMutating) return
    handleUpdate(entityKey, !value, false)
  }

  return (
    <div className="flex justify-center items-center">
      {isMutating ? (
        <Loader className="w-4 h-4" />
      ) : (
        <>
          <Checkbox
            className="border-black/60 data-[state=checked]:border-primary"
            checked={!data[entityKey]}
            onCheckedChange={onCheckedChange}
          />
        </>
      )}
    </div>
  )
}
