'use client'
import React from 'react'

import {
  CopyIcon,
  DotsHorizontalIcon,
  GearIcon,
  RocketIcon,
  TrashIcon,
} from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import Loader from '@/components/ui/Loader'
import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import { delFetcher, fetcher, postFetcher, putFetcher } from 'api/swr'
import { color2Linear } from 'CanvasCore/utils'
import { ColorCard } from 'components/ui/ColorCard'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'

export function DataTableRowActions({ row }) {
  const data = row.original

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(data.id)}
        >
          <CopyIcon className="mr-2 h-4 w-4" />
          Copy toning ID
        </DropdownMenuItem>
        <DuplicateItem toning={data} />
        <InitColor toning={data} />
        <DropdownMenuSeparator />
        <SaveToing2Sys toning={data} />
        <DeleteItem toning={data} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const SaveToing2Sys = ({ toning }) => {
  //TODO: need admin role.
  const { tid } = useParams()
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation(
    `/tonings/save-to-sys`,
    postFetcher,
    {
      onSuccess: () => {
        mutate(['/tonings/template', { tid }])
        toast({
          title: 'Toning Saved to System',
          description: 'The toning has been saved to system.',
        })
      },
    },
  )

  const handleClick = (e) => {
    e.preventDefault()
    trigger({ tid, fromToningId: toning.id })
  }

  return (
    <DropdownMenuItem onSelect={handleClick}>
      <RocketIcon className="mr-2 h-4 w-4" />
      Save to system{isMutating && 'ing...'}
    </DropdownMenuItem>
  )
}

const DuplicateItem = ({ toning }) => {
  const { tid } = useParams()
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation(
    `/tonings/duplicate`,
    postFetcher,
    {
      onSuccess: () => {
        mutate(['/tonings', { tid }])
        toast({
          title: 'Toning Duplicated Successfully',
          description: 'The toning has been duplicated.',
        })
      },
    },
  )

  const handleClick = (e) => {
    e.preventDefault()
    trigger({
      tid,
      fromToningId: toning.id,
    })
  }
  return (
    <DropdownMenuItem onSelect={handleClick}>
      <CopyIcon className="mr-2 h-4 w-4" />
      Duplicate{isMutating && 'ing...'}
    </DropdownMenuItem>
  )
}

const DeleteItem = ({ toning }) => {
  const { tid } = useParams()
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation('/tonings', delFetcher, {
    onSuccess: () => {
      mutate(['/tonings', { tid }])
      toast({
        title: 'Toning Deleted',
        description: 'The toning has been successfully deleted.',
      })
    },
  })

  //TODO: confirm
  const handleDelete = (e) => {
    e.preventDefault()
    if (toning.layers?.length > 0) {
      return toast({
        variant: 'destructive',
        title: 'Cannot Delete Toning',
        description: 'Please unlink the associated layers first.',
      })
    }
    trigger({ id: toning.id, body: { tid } })
  }

  return (
    <DropdownMenuItem
      className="text-destructive focus:bg-destructive focus:text-destructive-foreground"
      onSelect={handleDelete}
    >
      <TrashIcon className="mr-2 h-4 w-4" />
      Delete{isMutating && 'ing...'}
      <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
    </DropdownMenuItem>
  )
}

const InitColor = ({ toning }) => {
  const { tid } = useParams()
  //TODO: loading.
  const { data: sysTonings, isLoading } = useSWR(['/tonings/template'], fetcher)

  const { trigger, isMutating } = useSWRMutation(
    `/tonings/${toning.id}/colors/init`,
    postFetcher,
    {
      onSuccess: () => {
        mutate(['/tonings', { tid }])
        setOpen(false)
      },
    },
  )

  const [open, setOpen] = useState(false)
  const handleSubmit = (targetToning) => {
    trigger({
      tid,
      fromToningId: targetToning.id,
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <GearIcon className="mr-2 h-4 w-4" />
          Init color
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] gap-2">
        <DialogHeader>
          <DialogTitle>Initialize Colors</DialogTitle>
          <DialogDescription>
            Select colors to start the initialization process.
          </DialogDescription>
          <Separator className="my-4" />
          <div className="flex-1 overflow-y-auto max-h-[50vh]">
            {isLoading && <Loader>Loading tonings</Loader>}
            <Accordion type="single">
              {sysTonings?.map((t) => (
                <AccordionItem key={t.id} value={t.id}>
                  <AccordionTrigger className="py-2">{t.name}</AccordionTrigger>
                  <AccordionContent>
                    <div className="grid grid-cols-5 lg:grid-cols-7">
                      {t.colors?.map((color) => (
                        <LinearColor key={color.id} color={color} />
                      ))}
                    </div>
                    <div className="flex w-full">
                      {isMutating ? (
                        <Loader>Initing...</Loader>
                      ) : (
                        <Button
                          variant="outline"
                          className="ml-auto"
                          onClick={() => handleSubmit(t)}
                        >
                          <GearIcon className="mr-2 h-4 w-4" /> Init
                        </Button>
                      )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

const LinearColor = ({ color }) => {
  const { colors } = color

  const linear = useMemo(() => color2Linear(colors), [colors])

  if (!color || !linear) {
    return null
  }
  return (
    <ColorCard className={cn('border-transparent')}>
      <div
        className="rounded-lg w-5/6 h-5/6"
        style={{ backgroundImage: linear }}
      />
    </ColorCard>
  )
}

export const DataTableRowName = ({ row }) => {
  const { tid } = useParams()
  const data = row.original

  const { trigger, isMutating } = useSWRMutation(
    `/tonings/${data.id}`,
    putFetcher,
  )

  const [name, setName] = useState(data.name || '')

  const disabled = !name || isMutating || data.name === name
  const onSubmit = () => {
    if (disabled) {
      return null
    }
    trigger(
      { tid, key: 'name', value: name },
      {
        onSuccess: () => {
          mutate(['/tonings', { tid }])
        },
      },
    )
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog>
        <DialogTrigger asChild>
          <div
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
            )}
          >
            {data.name}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update name</DialogTitle>
            <DialogDescription>update toning name</DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name
              </Label>
              <Input
                className="col-span-3"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || !name || name === data.name}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
