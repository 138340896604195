import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ErrorsNote } from 'components/template-data-table-column'

import { Button } from '@/components/ui/button'
import {
  ClipboardIcon,
  DotsHorizontalIcon,
  Link2Icon,
  Pencil1Icon,
} from '@radix-ui/react-icons'

export const DataTableRowActions = ({ row }) => {
  const data = row.original
  const navigate = useNavigate()

  return (
    <div className="flex items-center gap-3">
      <Button asChild>
        <Link to={'/editor/' + row.original.id}>
          <Pencil1Icon className="h-4 w-4 mr-2" /> Paint now
        </Link>
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[180px]">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onSelect={() => navigator.clipboard.writeText(data.id)}
          >
            <ClipboardIcon className="mr-2 h-4 w-4" /> Copy ID
          </DropdownMenuItem>
          {data.version && (
            <DropdownMenuItem
              onSelect={() => navigator.clipboard.writeText(data.version)}
            >
              <ClipboardIcon className="mr-2 h-4 w-4" /> Copy version code
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onSelect={() => navigate(`/personal/templates/${data.id}/avatars`)}
          >
            <Link2Icon className="w-4 h-4 mr-2" />
            Avatars
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ErrorsNote row={row} />
    </div>
  )
}
