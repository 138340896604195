import { Description, H2 } from 'components/ui/Title'
import React from 'react'

const InviteCodes = () => {
  return (
    <div>
      <H2>可以邀请朋友加入捏咔工坊啦！</H2>
      <Description>Neku对画手实行邀请制，您拥有1个邀请码：</Description>
    </div>
  )
}

export default InviteCodes
