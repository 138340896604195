import React from 'react'
import useSWR from 'swr'
import dayjs from 'dayjs'
import { create } from 'zustand'
import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import { columns } from './data-table-column'
import DataTableToolbar from './data-table-toolbar'

export const useForm = create((set) => ({
  lang: 9,
  date: dayjs().subtract(2, 'day').$d,
  setLang: (lang) => set({ lang }),
  setDate: (date) => set({ date }),
}))

const CommTaglibs = () => {
  const { lang, date } = useForm()

  const { data, isLoading, isValidating, mutate } = useSWR([
    '/tag-clicks',
    { date: dayjs(date).format('YYYYMMDD'), lang },
  ])
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })
  if (isLoading || isValidating) return <Loader />
  if (!data) return <EmptyDatas />
  return (
    <>
      <DataTableToolbar mutate={mutate} />
      <Card className="mt-2">
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

export default CommTaglibs
