'use client'
import React, { useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { cn } from '@/lib/utils'
import useSWR, { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { fetcher, putFetcher, delFetcher, delUrlFetcher } from 'api/swr'
import {
  CopyIcon,
  DotsHorizontalIcon,
  TrashIcon,
  Component1Icon,
  Component2Icon,
  LayersIcon,
} from '@radix-ui/react-icons'
import Skeleton from '@/components/ui/skeleton'
import { Button } from '@/components/ui/button'
import { ToastAction } from '@/components/ui/toast'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog'
import { Checkbox } from '@/components/ui/checkbox'
import Loader from '@/components/ui/Loader'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import Selector from 'components/ui/Selector'
import { toast, useToast } from '@/components/ui/use-toast'
import { LayerCoverPicker } from 'components/LayerCoverPicker'
import ImgWithState from 'components/ui/ImgWithState'
import { useForm } from './index'

export function DataTableRowActions({ row }) {
  const layer = row.original

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(layer.id)}
        >
          <CopyIcon className="mr-2 h-4 w-4" /> Copy layer ID
        </DropdownMenuItem>
        <ChangeLayer layer={layer} />
        <DropdownMenuSeparator />
        {row.original.id !== 'draft' && <DeleteLayer layer={layer} />}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const DeleteLayer = ({ layer }) => {
  const { tid } = useParams()
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation('/layers', delFetcher, {
    onSuccess: () => {
      mutate(['/layers', { tid }])
      toast({
        title: 'Layer Deleted',
        description: 'The layer has been successfully deleted.',
      })
    },
  })

  const handleDelete = () => {
    trigger({ id: layer.id, body: { tid } })
  }

  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete',
      description: 'Are you sure you want to delete this?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem
      className="text-destructive focus:bg-destructive focus:text-destructive-foreground"
      onSelect={confirm}
    >
      <TrashIcon className="mr-2 h-4 w-4" />
      Delete{isMutating && 'ing...'}
      <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
    </DropdownMenuItem>
  )
}

export const DataTableRowOpt = ({ row }) => {
  const { tid } = useParams()
  const layer = row.original
  const { trigger, isMutating } = useSWRMutation(
    `/layers/${layer.id}`,
    putFetcher,
  )

  const onCheckedChange = (value) => {
    if (isMutating) return
    trigger(
      { tid, key: 'optional', value: !value },
      {
        onSuccess: () => {
          mutate(['/layers', { tid }])
        },
      },
    )
  }

  return (
    <div className="flex justify-center items-center">
      {isMutating ? (
        <Loader className="w-4 h-4" />
      ) : (
        <>
          <Checkbox
            className="border-black/60 data-[state=checked]:border-primary"
            checked={!layer.opt}
            onCheckedChange={onCheckedChange}
            aria-label="Select opt"
          />
        </>
      )}
    </div>
  )
}

export const DataTableRowCover = ({ row }) => {
  const { tid } = useParams()
  const data = row.original

  const [cover, setCover] = useState(data.cover || '')

  const { trigger, isMutating } = useSWRMutation(
    `/layers/${data.id}`,
    putFetcher,
    {
      onSuccess: () => {
        toast({
          title: 'Cover Updated',
          description: 'The cover has been successfully updated.',
        })
        mutate(['/layers', { tid }])
      },
    },
  )

  const onSubmit = useCallback(
    (v) => {
      const { id, name, p, z, gid, max, body, water, suit, opt } = v
      setCover(id)
      const data = {
        tid,
        key: 'form',
        value: {
          cover: id,
          name: name || 'new layer',
          playIndex: p || 0,
          zIndex: z || 0,
          maxChoose: max || 1,
          optional: opt ? true : false,
        },
      }
      if (gid) data.value['gid'] = gid
      if (body) data.value['bodymark'] = body
      if (water) data.value['watermark'] = water
      if (suit) data.value['suitmark'] = suit
      trigger(data)
    },
    [trigger, tid],
  )

  return (
    <LayerCoverPicker currCover={{ id: cover }} onSubmit={onSubmit}>
      {cover ? (
        <ImgWithState
          isMutating={isMutating}
          src={`https://img.zthd.io/an1/acs/${cover}`}
        />
      ) : (
        <Skeleton className="w-16 h-16 rounded-xl" />
      )}
    </LayerCoverPicker>
  )
}

export const DataTableRowText = ({ row, entityKey, type }) => {
  const { tid } = useParams()
  const layer = row.original
  const [open, setOpen] = useState(false)
  const { data } = useForm((state) => state)
  const duplicate = data.filter((item) => item[entityKey] === layer[entityKey])

  const { trigger, isMutating } = useSWRMutation(
    `/layers/${layer.id}`,
    putFetcher,
  )

  const [text, setText] = useState(layer?.[entityKey] || '')

  const disabled = !text || isMutating || layer?.[entityKey] === text
  const onSubmit = () => {
    if (disabled) {
      return null
    }
    trigger(
      { tid, key: entityKey, value: text },
      {
        onSuccess: () => {
          mutate(['/layers', { tid }])
          setOpen(false)
        },
      },
    )
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <div
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
              `${duplicate.length > 1 ? 'text-red-600' : ''}`,
            )}
          >
            {layer?.[entityKey]}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {entityKey}</DialogTitle>
            <DialogDescription>update layer {entityKey}</DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {entityKey}
              </Label>
              <Input
                className="col-span-3"
                value={text}
                type={type}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || !text || text === layer?.[entityKey]}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const ComponentCount = ({ row }) => {
  const layer = row.original
  const { data } = useSWR(['/components', { tid: layer.tid }], fetcher)
  const aiCpts =
    data?.filter((cpt) => cpt.lid === layer.id && cpt.ai === 1) || []
  return (
    <div className="flex items-center px-2 text-sm text-black/60">
      <Component1Icon className="mr-2 w-4 h-4" />{' '}
      <span className="me-4">{layer.cptCount}</span>
      <Component2Icon className="mr-2 w-4 h-4" /> <span>{aiCpts.length}</span>
    </div>
  )
}

export const CopyId = ({ row }) => {
  return (
    <div
      className="flex items-center px-2 mt-2 text-sm text-black/60"
      onClick={() => navigator.clipboard.writeText(row.original.id)}
    >
      <CopyIcon className="mr-2 h-4 w-4" />
      {row.original.id}
    </div>
  )
}

export const ChangeLayer = ({ layer }) => {
  const { tid } = useParams()
  const [open, setOpen] = useState(false)
  const [lastSelect, setLastSelect] = useState()
  const [curCpts, setCurCpts] = useState([])
  const [curLayer, setCurLayer] = useState(layer)
  const { data } = useSWR(['/components', { tid }], fetcher)
  const cpts = data?.filter((cpt) => cpt.lid === layer.id) || []
  const { data: layers, isLoading } = useSWR(['/layers', { tid }], fetcher)

  const handleChange = (cpt, shiftKey) => {
    const index = curCpts.findIndex((cur) => cur.id === cpt.id)
    if (index === -1) {
      if (shiftKey && lastSelect) {
        let start = cpts.indexOf(lastSelect)
        let end = cpts.indexOf(cpt)
        setCurCpts([...curCpts, ...cpts.slice(start, end + 1)])
      } else {
        setCurCpts([...curCpts, cpt])
      }
      setLastSelect(cpt)
    } else {
      setCurCpts([...curCpts.filter((item) => item.id !== cpt.id)])
    }
  }

  const handleAll = () => {
    if (curCpts.length < cpts.length) {
      const allCpts = cpts
        .filter((cpt) => !cpts.filter((c) => c.pid === cpt.id).length)
        .filter((cpt) => !cpt.pid)
      setCurCpts(allCpts)
    } else {
      setCurCpts([])
    }
    setLastSelect(null)
  }

  const handleInverse = () => {
    const inverseCpts = cpts
      .filter((cpt) => !cpts.filter((c) => c.pid === cpt.id).length)
      .filter((cpt) => !cpt.pid)
      .filter((cpt) => !curCpts.includes(cpt))
    setCurCpts(inverseCpts)
    setLastSelect(null)
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/components/batch`,
    putFetcher,
  )

  const handleUpdate = () => {
    trigger(
      {
        tid,
        ids: curCpts.map((cur) => cur.id),
        key: 'lid',
        value: curLayer.id,
      },
      {
        onSuccess: () => {
          setOpen(false)
          setCurCpts([])
          mutate(['/components', { tid }])
        },
      },
    )
  }

  if (isLoading && layer.id !== 'draft') {
    return (
      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
        fetch layers...
      </DropdownMenuItem>
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {layer.id == 'draft' ? (
          <div className="text-right flex justify-end items-center">
            <LayersIcon className="w-4 h-4 mr-2" />
          </div>
        ) : (
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <LayersIcon className="w-4 h-4 mr-2" />
            Change layer
          </DropdownMenuItem>
        )}
      </DialogTrigger>
      <DialogContent className="max-w-[80vh] max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Change layer</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <div className="flex flex-col">
          <h3 className="text-xl font-bold">curr layer:</h3>
          <div className="flex justify-between items-end">
            <img
              alt="layer original"
              src={`https://img.zthd.io/an1/acs/${curLayer?.cover}`}
              className={`me-2 mt-2 h-24 w-24 object-cover transition-all hover:scale-105 aspect-square border-2 border-primary hover:border-primary rounded-xl`}
            />
            <div className="flex">
              <Selector
                id="layer"
                datas={
                  layers?.map((item) => {
                    return { name: item.name, code: item }
                  }) || []
                }
                update={setCurLayer}
                value={curLayer}
                labelName="layer"
                className="col-span-3"
              />
              <Button
                disabled={isLoading || isMutating || curCpts.length === 0}
                onClick={handleUpdate}
                className="ml-2"
              >
                Change Layer
                {isMutating && <Loader />}
              </Button>
              {error && <p className="text-red-600 text-right">put error</p>}
            </div>
          </div>
          <h3 className="text-xl font-bold mt-4">curr cpt:</h3>
          <div className="mt-2">
            <Button
              variant={curCpts.length < cpts.length ? 'outline' : ''}
              onClick={() => handleAll(curCpts.length < cpts.length ? '' : '')}
            >
              Check All
            </Button>
            <Button
              className="mx-2"
              variant={curCpts.length < cpts.length ? 'outline' : ''}
              onClick={() => handleInverse()}
            >
              Inverse
            </Button>
          </div>
          <div className="flex flex-wrap gap-2 p-1">
            {cpts?.map((cpt) => (
              <Component
                cpt={cpt}
                cpts={cpts}
                curCpts={curCpts}
                handleChange={handleChange}
                key={cpt.id}
              />
            ))}
          </div>
          <div className="mt-2 text-right">
            <DeleteCpts curCpts={curCpts} setCurCpts={setCurCpts} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export const DeleteCpts = ({ curCpts, setCurCpts }) => {
  const { tid } = useParams()

  const { trigger, isMutating, isLoading, error } = useSWRMutation(
    `/components/batch`,
    delUrlFetcher,
  )

  const handleUpdate = () => {
    trigger(
      {
        ids: curCpts.map((cur) => cur.id),
      },
      {
        onSuccess: () => {
          setCurCpts([])
          mutate(['/components', { tid }])
        },
      },
    )
  }

  return (
    <>
      <Button
        disabled={isMutating || isLoading || curCpts.length === 0}
        variant="destructive"
        onClick={handleUpdate}
      >
        Delete Cpts
        {isMutating && <Loader />}
      </Button>
      {error && <p className="text-red-600 text-right pb-2">put error</p>}
    </>
  )
}

const Component = ({ cpt, cpts, curCpts, handleChange }) => {
  const isSelect = curCpts.findIndex((cur) => cur.id === cpt.id) > -1
  const childs = useMemo(
    () => cpts?.filter((c) => c.pid === cpt.id),
    [cpt.id, cpts],
  )
  if (cpt.pid) {
    return (
      <div className="me-2 mt-2 h-24 w-24 object-cover transition-all aspect-square border rounded-xl flex flex-col items-center justify-around text-sm">
        <LayersIcon className="w-4 h-4 mr-2" /> has pid
      </div>
    )
  }
  if (childs.length > 0) {
    return (
      <div className="me-2 mt-2 h-24 w-24 object-cover transition-all aspect-square border rounded-xl flex flex-col items-center justify-around text-sm">
        <LayersIcon className="w-4 h-4 mr-2" /> has child
      </div>
    )
  }
  return (
    <img
      alt="cpt original"
      onClick={(e) => handleChange(cpt, e.shiftKey)}
      src={`https://img.zthd.io/an1/ats/${cpt.cover ?? cpt.id}`}
      className={`me-2 mt-2 h-24 w-24 object-cover transition-all hover:scale-105 aspect-square border-2 hover:border-primary rounded-xl ${isSelect ? 'border-4 border-primary' : ''}`}
    />
  )
}
