import DisplayAvatars from 'AdminPanel/Avatars/DisplayAvatars'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

const Avatars = () => {
  const { id } = useParams()
  const { data, isLoading } = useSWR(['/avatars', { author: id }])
  return (
    <div>
      Avatars
      {isLoading && <div>Loading avatars.</div>}
      <DisplayAvatars avatars={data} />
    </div>
  )
}

export default Avatars
