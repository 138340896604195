import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import { useReactTable, getCoreRowModel } from '@tanstack/react-table'
import MyDataTable from 'components/ui/my-data-table'
import React from 'react'
import useSWR from 'swr'
import { columns } from './data-table-column'
import DataTableToolbar from './data-table-toolbar'

const LayerGroups = () => {
  const {
    data = [],
    isLoading,
    isValidating,
    mutate,
  } = useSWR(['/layer-groups'])

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })
  if (isLoading || isValidating) return <Loader>Load layer groups</Loader>

  return (
    <div className="flex flex-col gap-4">
      <DataTableToolbar mutate={mutate} />
      <Card>
        <CardContent>
          <MyDataTable table={table} />
        </CardContent>
      </Card>
    </div>
  )
}

export default LayerGroups
