import React from 'react'
import Dropdown from 'components/ui/Selector'

const Items = [
  { code: '0', name: 'local' },
  { code: '401', name: 'public' },
]

const CustomDropdown = ({ value, update }) => {
  return (
    <Dropdown
      datas={Items}
      showLabel
      labelName="State"
      value={value}
      update={update}
    />
  )
}

export default CustomDropdown
