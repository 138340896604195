import { Accordion } from '@/components/ui/accordion'
import Loader from '@/components/ui/Loader'
import { fetcher } from 'api/swr'
import Divider from 'components/ui/Divider'
import { useUI } from 'Editor'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { CptsInDlc, CptsWithoutDlc, DefalutCpts } from './DefalutCpts'
import LayerPicker from './LayerPicker'
import { ChangeLayer } from '../../RightPanel/Layers/data-table-row-actions'

export const LayerPanel = () => {
  return (
    <>
      <LayerPicker />
      <Divider />
      <CptInLayer />
    </>
  )
}

export default LayerPanel

const CptInLayer = () => {
  const { tid } = useParams('tid')
  const lid = useUI((state) => state.lid)
  const { data, isLoading } = useSWR(['/components', { tid }], fetcher)
  const cpts = useMemo(() => {
    if (data) {
      if (lid && lid !== 'draft') {
        return data?.filter((cpt) => cpt.lid === lid)
      } else {
        return data?.filter((cpt) => cpt.lid === 'draft' || !cpt.lid)
      }
    }
    return data
  }, [data, lid])

  const [opens, setOpens] = useState([tid])

  if (isLoading) return <Loader>Load cpts...</Loader>

  //1. with out dlc
  //2. tid is default dlc
  //3. dlc not tid
  return (
    <div className="w-full h-full overflow-y-auto">
      {!lid || lid === 'draft' ? <ChangeLayer layer={{ id: 'draft' }} /> : null}
      <Accordion
        type="multiple"
        value={opens}
        onValueChange={(value) => {
          value !== tid && setOpens([...value, tid])
        }}
      >
        <CptsWithoutDlc cpts={cpts} />
        <DefalutCpts cpts={cpts} />
        <Dlcs cpts={cpts} />
      </Accordion>
    </div>
  )
}
const Dlcs = ({ cpts }) => {
  const { tid } = useParams()
  const { data, isLoading } = useSWR(['/dlcs', { tid }], fetcher)
  if (isLoading) return <Loader />
  if (!data) return null
  return (
    <>
      {data?.map((dlc, idx) => (
        <CptsInDlc
          key={dlc.id}
          dlc={dlc}
          cpts={cpts}
          isLast={idx === data.length - 1}
        />
      ))}
    </>
  )
}
