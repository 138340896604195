import { cn } from '@/lib/utils'
import React from 'react'

const cptCss =
  'h-24 w-24 object-cover transition-all aspect-square rounded-xl border hover:border-2 hover:border-primary hover:scale-105'

const CptGrid = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 grid-cols-9 gap-2 p-1',
      className,
    )}
    {...props}
  />
))

CptGrid.displayName = 'CptGrid'

const CptCard = React.forwardRef(({ children2, className, ...props }, ref) => (
  <div className="flex flex-col items-center" ref={ref}>
    <div
      className={cn(
        'bg-white border-2 rounded-xl flex items-center justify-center group hover:border-primary w-full',
        className,
      )}
      {...props}
    />
    <div className="min-h-8">{children2}</div>
  </div>
))
CptCard.displayName = 'CptCard'

export { CptCard, cptCss, CptGrid }
