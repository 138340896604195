import React, { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { postFetcher } from 'api/swr'
import { Button } from '@/components/ui/button'
import Loader from '@/components/ui/Loader'
import { ReloadIcon, PlusIcon, ClipboardIcon } from '@radix-ui/react-icons'
import { Form } from '@/components/ui/form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { MyFormInput, MyFormSelect } from 'components/ui/MyFormField'
import { useToast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import { z } from 'zod'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { mutate } from 'swr'

const RoleOptions = [
  { name: 'admin', code: 'admin' },
  { name: 'designer', code: 'designer' },
  { name: 'painter', code: 'painter' },
  { name: 'viewer', code: 'viewer' },
]

const DataTableToolbar = ({ mutate }) => {
  return (
    <div className="flex items-center justify-between my-2">
      <Button variant="outline" onClick={() => mutate()}>
        <ReloadIcon className="h-4 w-4 mr-2" />
        Refetch
      </Button>
      <DataTableRowCreate />
    </div>
  )
}

const DataTableRowCreate = () => {
  const { toast } = useToast()
  const [isOpen, setIsOpen] = useState()
  const [defaultValues] = useState({
    username: '',
    nick_name: '',
    role: '',
  })
  const { trigger, isMutating, error } = useSWRMutation(
    '/publishers',
    postFetcher,
  )

  const infoFormSchema = z.object({
    username: z.string().email(),
    nick_name: z.string().min(2).max(30),
    role: z.string(),
  })
  const form = useForm({
    resolver: zodResolver(infoFormSchema),
    defaultValues,
  })

  const onSubmit = (data) => {
    if (isMutating) return
    trigger(data, {
      onSuccess: (res) => {
        mutate(['/publishers'])
        setIsOpen(false)
        if (res.password) {
          toast({
            title: 'Success',
            description: `The publisher ${res.username} password is ${res.password}.`,
            action: (
              <ToastAction
                altText="Try again"
                onClick={() =>
                  navigator.clipboard.writeText(
                    `username:${res.username};password:${res.password};`,
                  )
                }
              >
                <ClipboardIcon className="w-4 h-4 mr-2" />
                Copy ID
              </ToastAction>
            ),
          })
        }
      },
    })
  }
  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <Button variant="default">
            <PlusIcon className="h-4 w-4 mr-2" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
              Create
            </span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <MyFormInput name="username" form={form} label="user name" />
              <MyFormInput name="nick_name" form={form} label="nick name" />
              <MyFormSelect
                name="role"
                form={form}
                label="role"
                items={RoleOptions}
                placeholder={'role'}
              />
              {isMutating ? (
                <Loader>Submit...</Loader>
              ) : (
                <Button type="submit">Submit</Button>
              )}
              {error && <div className="text-red-500">{error.message}</div>}
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DataTableToolbar
