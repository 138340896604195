import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { useUI } from 'Editor'
import React, { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

const ToningPicker = ({ tonings }) => {
  const scrollRef = useRef()
  const toningRefs = useRef({})
  const toningId = useUI((state) => state.toningId)

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' })
    }
  }
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (toningId) {
      handleItemClick(toningId)
    }
  }, [toningId])

  const handleItemClick = (id) => {
    const element = toningRefs.current[id]
    if (element && scrollRef.current) {
      const elementRect = element.getBoundingClientRect()
      const scrollContainerRect = scrollRef.current.getBoundingClientRect()
      const scrollOffset =
        elementRect.left -
        scrollContainerRect.left -
        scrollContainerRect.width / 2 +
        elementRect.width / 2

      scrollRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' })
    }
  }

  const setLayerRef = useCallback((element, id) => {
    if (element) {
      toningRefs.current[id] = element
    }
  }, [])

  return (
    <div className="flex items-center">
      <div onClick={scrollLeft}>
        <ChevronLeftIcon className="w-8 h-8 text-gray-400 hover:text-primary" />
      </div>
      <div
        className="flex overflow-x-scroll w-full gap-4 scrollbar-hide"
        ref={scrollRef}
      >
        {tonings?.map((toning) => (
          <Toning
            key={toning.id}
            toning={toning}
            setRef={(el) => setLayerRef(el, toning.id)}
          />
        ))}
      </div>
      <div className="ml-auto" onClick={scrollRight}>
        <ChevronRightIcon className="w-8 h-8 text-gray-400 hover:text-primary" />
      </div>
    </div>
  )
}

const Toning = ({ toning, setRef }) => {
  const { tid } = useParams()
  const selected = useUI((state) => state.toningId === toning.id)
  const toggleToning = useUI((state) => state.toggleToning)

  const inTonings = useEditorStore(
    (state) => state.editors[tid]?.tonings?.[toning.id],
  )

  return (
    <div
      className="flex flex-col w-full py-1 space-y-1"
      ref={setRef}
      onClick={() => toggleToning(toning.id)}
    >
      <Button
        variant="outline"
        className={cn(selected ? 'border border-primary' : '')}
      >
        {toning.name}
      </Button>
      <div
        className={cn(
          'w-full h-0.5 bg-primary rounded',
          inTonings ? '' : 'invisible',
        )}
      />
    </div>
  )
}

export default ToningPicker
