import CanvasCore from 'CanvasCore'
import React, { useRef } from 'react'
import Overlay from './Overlay'

const CanvasPanel = ({ draft }) => {
  const drawRef = useRef(null)
  return (
    <div className="relative w-full h-full">
      <CanvasCore ow={draft.ow} oh={draft.oh} drawRef={drawRef} />
      <Overlay draft={draft} />
    </div>
  )
}

export default CanvasPanel
