import React, { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { postFetcher } from 'api/swr'
import { Button } from '@/components/ui/button'
import Loader from '@/components/ui/Loader'
import { ReloadIcon, PlusIcon } from '@radix-ui/react-icons'
import { Form } from '@/components/ui/form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { MyFormInput, MyFromTextarea } from 'components/ui/MyFormField'
import { z } from 'zod'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { mutate } from 'swr'
const DataTableToolbar = ({ mutate }) => {
  return (
    <div className="flex items-center justify-between mb-2">
      <Button variant="outline" onClick={() => mutate()}>
        <ReloadIcon className="h-4 w-4 mr-2" />
        Refetch
      </Button>
      <DataTableRowCreate />
    </div>
  )
}

const DataTableRowCreate = () => {
  const [isOpen, setIsOpen] = useState()
  const [defaultValues] = useState({
    level: 2,
    name: '',
    note: '',
  })
  const { trigger, isMutating, error } = useSWRMutation('/taglibs', postFetcher)

  const infoFormSchema = z.object({
    level: z.preprocess((val) => Number(val), z.number().min(2).max(3)),
    name: z.string().min(2).max(30),
    note: z.string().max(640).min(2),
  })
  const form = useForm({
    resolver: zodResolver(infoFormSchema),
    defaultValues,
  })

  const onSubmit = (data) => {
    if (isMutating) return
    trigger(data, {
      onSuccess: () => {
        mutate(['/taglibs'])
        setIsOpen(false)
      },
    })
  }
  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <Button variant="default">
            <PlusIcon className="h-4 w-4 mr-2" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
              Create Tag
            </span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Tag</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <MyFormInput
                type="number"
                name="level"
                form={form}
                label="level"
                placeholder={'tag level'}
              />
              <MyFormInput
                name="name"
                form={form}
                label="Name"
                placeholder={'tag name'}
              />
              <MyFromTextarea
                name="note"
                form={form}
                label="Description"
                placeholder={'tag description'}
              />
              {isMutating ? (
                <Loader>Submit...</Loader>
              ) : (
                <Button type="submit">Submit</Button>
              )}
              {error && <div className="text-red-500">{error.message}</div>}
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DataTableToolbar
