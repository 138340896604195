import { Button } from '@/components/ui/button'
import { CubeIcon, PlusIcon } from '@radix-ui/react-icons'
import { postFetcher } from 'api/swr'
import React from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'

const DataTableToolbar = ({ dlcs }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Button variant="ghost" className="h-8 px-2 lg:px-3">
          <CubeIcon className="mr-2 h-4 w-4" /> {dlcs?.length}
        </Button>
      </div>
      <CreateButton />
    </div>
  )
}

const CreateButton = () => {
  const { tid } = useParams()
  const { trigger } = useSWRMutation('/dlcs', postFetcher, {
    onSuccess: () => {
      mutate(['/dlcs', { tid }])
    },
  })

  const handleClick = () => {
    trigger({ tid, name: 'new dlc' })
  }

  return (
    <Button variant="outline" className="ml-auto" onClick={handleClick}>
      <PlusIcon className="mr-2 h-4 w-4" /> Create dlc
    </Button>
  )
}

export default DataTableToolbar
