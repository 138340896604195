import React from 'react'
import { useParams } from 'react-router-dom'

import {
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@/components/ui/dropdown-menu'
import Skeleton from '@/components/ui/skeleton'
import { CubeIcon } from '@radix-ui/react-icons'
import { fetcher, putFetcher } from 'api/swr'
import { useRole } from 'hooks/auth'
import useSWR, { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'

const ChangeDLC = ({ cpt }) => {
  const isAdmin = useRole(`admin`)
  const { tid } = useParams()
  const { data: dlcs, isLoading } = useSWR(['/dlcs', { tid }], fetcher)

  const { trigger, isMutating } = useSWRMutation(
    `/components/${cpt.id}`,
    putFetcher,
  )

  const handleUpdate = (e, dlcId) => {
    e.preventDefault()
    if (isMutating) return false
    trigger(
      { tid, key: 'dlc', value: dlcId },
      {
        onSuccess: () => {
          mutate(['/components', { tid }])
        },
      },
    )
  }

  //TODO: check child cpts
  // const { cpts: childs } = useChildCpts(cpt)

  if (!isAdmin) {
    return null
  }

  if (
    !dlcs ||
    cpt.pid
    // || childs.length > 0
  ) {
    return null
  }
  if (isLoading) {
    return (
      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
        fetch dlcs...
      </DropdownMenuItem>
    )
  }

  if (!dlcs || dlcs?.length === 0) {
    return null
  }

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <CubeIcon className="w-4 h-4 mr-2" /> move to dlc
        {isMutating && 'ing...'}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <DropdownMenuItem key={tid} onClick={(e) => handleUpdate(e, tid)}>
            {tid} - default
          </DropdownMenuItem>
          {dlcs?.map(
            (dlc) =>
              dlc.id !== cpt.dlc && (
                <DropdownMenuItem
                  key={dlc.id}
                  onClick={(e) => handleUpdate(e, dlc.id)}
                >
                  {dlc.cover ? (
                    <img src={dlc.cover} alt="..." className="w-12 h-12 mr-2" />
                  ) : (
                    <Skeleton className="w-12 h-12 mr-2">DLC ICON</Skeleton>
                  )}
                  {dlc.id} - {dlc.name}
                </DropdownMenuItem>
              ),
          )}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}

export default ChangeDLC
