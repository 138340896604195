import button_apple from 'assets/button_apple.svg'
import button_google from 'assets/button_google.svg'
import Signup from 'Auth/Signup'
import React from 'react'

const Home = () => {
  return (
    <div className="flex flex-col h-full min-h-screen bg-white p-10 text-black gap-4">
      <div className="flex items-center">
        <div className="flex items-center text-lg font-medium">
          Neku - Avatar Maker
        </div>
        {/* <Button className="ml-auto" variant="outline" size="lg" asChild>
          <Link to="/login">
            <RocketIcon className="w-4 h-4 mr-2" />
            GET STARTED
          </Link>
        </Button> */}
      </div>
      <div className="grid grid-cols-2">
        <div className="col-span-1 flex flex-col gap-4">
          <h1 className="col-start-1 row-start-2 mt-4 max-w-[36rem] text-4xl font-extrabold tracking-tight sm:text-7xl xl:max-w-[43.5rem]">
            Create original character generators with your own designs.
          </h1>
          <p className="col-start-1 row-start-3 mt-4 max-w-lg text-lg text-slate-700">
            Join us to expand your imagination.
            <br />
            Share your artwork with everyone.
          </p>

          <div className="flex items-center gap-4">
            <a
              href="https://apps.apple.com/cn/app/neku-oc-avatar-maker/id1630343674"
              target="_blank"
              rel="noreferrer"
            >
              <img src={button_apple} className="cursor-pointer" alt="" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.stickermobi.avatarmaker"
              target="_blank"
              rel="noreferrer"
            >
              <img src={button_google} className="cursor-pointer" alt="" />
            </a>
          </div>
        </div>
        <div className="col-span-1">
          <Signup />
        </div>
      </div>
    </div>
  )
}

export default Home
