import React, { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from '@/components/ui/dialog'
import { postFetcher } from 'api/swr'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import useSWR, { mutate } from 'swr'
import Selector from 'components/ui/Selector'
import { Checkbox } from '@/components/ui/checkbox'
import { makeShortId } from '../../commons'

const maxes = Array.from({ length: 4 }, (v, i) => ({
  code: i + 1,
  name: i + 1,
}))

const zIndexes = Array.from({ length: 400 }, (v, i) => ({
  code: i - 199,
  name: i - 199,
}))

const palyIndexes = Array.from({ length: 200 }, (v, i) => ({
  code: i,
  name: i,
}))

const CreatePage = () => {
  const [open, setOpen] = useState(false)
  const [uploadFiles, setUploadFiles] = useState([])
  const { toast } = useToast()
  const [values, setValues] = useState({
    gid: '',
    z: 0,
    p: 0,
    max: 1,
    suit: 0,
    water: 0,
    body: 0,
    opt: true,
  })
  const handleClose = () => {
    setOpen(false)
  }
  const { trigger, isMutating } = useSWRMutation('/layericons', postFetcher)

  const { data: LayerGroups = [] } = useSWR(['/layer-groups'])

  const selectFile = (fileList) => {
    const files = Array.from(fileList)
    if (!files) return
    setUploadFiles({})
    files.forEach((file) => {
      const img = new Image()
      const name = file.name.split('.')[0]
      img.onload = () => {
        if (img.width !== 256 || img.height !== 256) {
          toast({
            title: 'Error',
            variant: 'destructive',
            description: `the image size must be 256*256.`,
          })
        } else {
          const reader = new FileReader()
          reader.onloadend = () => {
            setUploadFiles((items) => {
              return { ...items, [name]: reader.result }
            })
          }
          reader.readAsDataURL(file)
        }
      }
      img.src = URL.createObjectURL(file)
    })
  }

  const onSubmit = () => {
    if (isMutating) return
    const tasks = Object.keys(uploadFiles).map((key) => {
      trigger({
        id: makeShortId(3),
        author: 'admin',
        name: key,
        code: uploadFiles[key],
        ...values,
      })
    })
    Promise.all(tasks).then((res) => {
      mutate(['/layericons'])
      handleClose()
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="mr-2" />
          Create Layer Icon
        </Button>
      </DialogTrigger>
      <DialogContent className="dialog-content overflow-y-auto max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Create Layer Icon</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="icon">icon</Label>
          <Input
            id="icon"
            type="file"
            multiple
            accept=".jpg, .png, .jpeg, .webp, .gif, .bmp"
            onChange={(e) => selectFile(e.target.files)}
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="group">group</Label>
          <Selector
            id="group"
            datas={LayerGroups.map((item) => ({
              code: item.id,
              name: item.name,
            }))}
            update={(e) =>
              setValues((items) => {
                return { ...items, gid: e }
              })
            }
            value={values.gid}
            labelName="group"
            placeholder={'please select gid'}
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="zIndex">zIndex</Label>
          <Selector
            id="zIndex"
            datas={zIndexes}
            update={(e) =>
              setValues((items) => {
                return { ...items, z: e }
              })
            }
            value={values.z}
            labelName="zIndex"
            placeholder={'please select zIndex'}
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="playIndex">playIndex</Label>
          <Selector
            id="playIndex"
            datas={palyIndexes}
            update={(e) =>
              setValues((items) => {
                return { ...items, p: e }
              })
            }
            value={values.p}
            labelName="playIndex"
            placeholder={'please select playIndex'}
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="maxChoose">maxChoose</Label>
          <Selector
            id="maxChoose"
            datas={maxes}
            update={(e) =>
              setValues((items) => {
                return { ...items, max: e }
              })
            }
            value={values.max}
            labelName="maxChoose"
            placeholder={'please select maxChoose'}
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="bodymark">bodymark</Label>
          <Checkbox
            id="bodymark"
            labelName="bodymark"
            checked={values.body === 1}
            onCheckedChange={(e) =>
              setValues((items) => {
                return { ...items, body: e ? 1 : 0 }
              })
            }
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="watermark">watermark</Label>
          <Checkbox
            id="watermark"
            labelName="watermark"
            checked={values.water === 1}
            onCheckedChange={(e) =>
              setValues((items) => {
                return { ...items, water: e ? 1 : 0 }
              })
            }
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="suitmark">suitmark</Label>
          <Checkbox
            id="suitmark"
            labelName="suitmark"
            checked={values.suit === 1}
            onCheckedChange={(e) =>
              setValues((items) => {
                return { ...items, suit: e ? 1 : 0 }
              })
            }
          />
        </div>
        <div className="grid w-full max-w-sm items-center gap-1.5">
          <Label htmlFor="required">required</Label>
          <Checkbox
            id="required"
            labelName="required"
            checked={!values.opt}
            onCheckedChange={(e) =>
              setValues((items) => {
                return { ...items, opt: !e }
              })
            }
          />
        </div>
        <div className="flex flex-wrap">
          {Object.keys(uploadFiles).map((key, index) => (
            <img
              key={index}
              alt="..."
              className="aspect-square rounded-md border h-24 w-24 me-2"
              src={uploadFiles[key]}
            />
          ))}
        </div>
        <DialogFooter>
          <Button
            disabled={isMutating || uploadFiles.length === 0}
            onClick={onSubmit}
          >
            Submit{isMutating ? '...' : null}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CreatePage
