import {
  DropdownMenu as DropDownMenuRoot,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ClipboardIcon } from '@radix-ui/react-icons'
import React from 'react'

export const DropdownMenu = ({ trigger, label, children }) => {
  return (
    <DropDownMenuRoot>
      <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{label}</DropdownMenuLabel>
        {children}
      </DropdownMenuContent>
    </DropDownMenuRoot>
  )
}

export const CopyItem = React.forwardRef(
  ({ data, children, ...props }, ref) => {
    return (
      <DropdownMenuItem
        ref={ref}
        onSelect={() => navigator.clipboard.writeText(data)}
        {...props}
      >
        <ClipboardIcon className="mr-2 h-4 w-4" />
        <span>{children ?? 'Copy ID'}</span>
      </DropdownMenuItem>
    )
  },
)
CopyItem.displayName = 'CopyItem'
