import produce from 'immer'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { color2Linear } from 'CanvasCore/utils'

export const initialState = {
  drawers: [],
  stage: { w: 512, h: 512, scale: 1, xOffset: 0 },
}

export const useEditorStore = create(
  devtools(
    persist(
      (set, get) => ({
        editors: {}, // 用于存储多个基于tid的编辑器状态
        setEditor: (tid, editor) =>
          set(
            produce((state) => {
              state.editors[tid] = editor
            }),
          ),
        initEditor: (tid, editor, override) =>
          set(
            produce((state) => {
              if (override || !state.editors[tid]) {
                const _editor = state.editors[tid] || {}
                state.editors[tid] = { ..._editor, ...editor }
              }
            }),
          ),
        setStage: (tid, stage) =>
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) return
              editor.stage = stage
            }),
          ),
        setDrawers: (tid, drawers) =>
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) {
                state.editors[tid] = { drawers }
              } else {
                state.editors[tid].drawers = drawers
              }
            }),
          ),
        setTonings: (tid, tonings) =>
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) return
              if (!editor.tonings) {
                editor.tonings = {}
              }
              Object.keys(tonings).map((toningId) => {
                const color = tonings[toningId].default.toning.map((item) => {
                  return { color: item.c, offset: item.o }
                })
                const linear = color2Linear(color)
                if (!editor.tonings[toningId]) {
                  editor.tonings[toningId] = {}
                }
                editor.tonings[toningId].default = {
                  id: tonings[toningId].default.id,
                  color: linear,
                }
              })
            }),
          ),
        selectCpt: (tid, layer, cpt) =>
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) return
              // 只允许一个组件在一个图层中
              // editor.drawers =
              //   editor.drawers?.filter((d) => d.lid !== layer.id) ?? []

              const cpts = editor.drawers?.filter((d) => d.lid === layer.id)
              const index = editor.drawers?.findIndex((d) => d.lid === layer.id)

              if (cpts.length >= layer.max && index > -1) {
                editor.drawers?.splice(index, 1)
              }

              const drawer = {
                cid: cpt.id,
                lid: layer.id,
                x: cpt.crop.x,
                y: cpt.crop.y,
                w: cpt.crop.w,
                h: cpt.crop.h,
                z: layer.z,
                ai: cpt.ai,
              }
              if (cpt.pid) {
                drawer.pid = cpt.pid
              }

              if (layer.toning) {
                drawer.t = layer.toning
              }
              // REVIEW: why?
              // if (cpt.dlc !== cpt.tid) {
              //   drawer.did = cpt.dlc;
              // }
              editor.drawers.push(drawer)
            }),
          ),
        deselectCpt: (tid, cid) =>
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) return
              // 从drawers数组中移除对应的组件
              editor.drawers = editor.drawers?.filter((d) => d.cid !== cid)
            }),
          ),
        deselectCptByLayer: (tid, lid) => {
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) return
              // 从drawers数组中移除对应的组件
              editor.drawers = editor.drawers?.filter((d) => d.lid !== lid)
            }),
          )
        },
        selectColor: (tid, toningId, lid = 'default', color) => {
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) return
              if (!editor.tonings) {
                editor.tonings = {}
              }
              if (!editor.tonings[toningId]) {
                editor.tonings[toningId] = {}
              }
              editor.tonings[toningId][lid] = color
            }),
          )
        },
        deselectColor: (tid, toningId) =>
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) return
              delete editor.tonings?.[toningId]
              editor.currColor = null
            }),
          ),
        resetCanvas: (tid) =>
          set(
            produce((state) => {
              const editor = state.editors[tid]
              if (!editor) return
              state.editors[tid] = { ...initialState, stage: editor.stage }
            }),
          ),
      }),
      {
        name: 'editors-storage', // 存储的名称
        storage: createJSONStorage(() => localStorage), // 使用localStorage来持久化
      },
    ),
  ),
)
