import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import React from 'react'
import DlcPanel from './Dlcs'
import DlcDraftPanel from './DlcDrafts'
import DlcFramePanel from './DlcFrames'
import InReviewTemplates from './InReviews'
import Onlines from './Onlines'
import AllTemplates from './Templates'

const Index = () => {
  return (
    <div className="flex-1 space-y-4 p-8 pt-6">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">Templates</h2>
        <div className="flex items-center space-x-2">xxxx</div>
      </div>
      <Tabs defaultValue="templates" className="space-y-4">
        <TabsList>
          <TabsTrigger value="templates">Templates</TabsTrigger>
          <TabsTrigger value="drafts">Drafts</TabsTrigger>
          <TabsTrigger value="onlines">Onlines</TabsTrigger>
          <TabsTrigger value="dlcs">Dlcs</TabsTrigger>
          <TabsTrigger value="dlc-drafts">Dlc Drafts</TabsTrigger>
          <TabsTrigger value="dlc-onlines">Dlc Onlines</TabsTrigger>
        </TabsList>
        <TabsContent value="templates" className="space-y-4">
          <AllTemplates />
        </TabsContent>
        <TabsContent value="drafts" className="space-y-4">
          <InReviewTemplates />
        </TabsContent>
        <TabsContent value="onlines" className="space-y-4">
          <Onlines />
        </TabsContent>
        <TabsContent value="dlcs" className="space-y-4">
          <DlcPanel />
        </TabsContent>
        <TabsContent value="dlc-drafts" className="space-y-4">
          <DlcDraftPanel />
        </TabsContent>
        <TabsContent value="dlc-onlines" className="space-y-4">
          <DlcFramePanel />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default Index
