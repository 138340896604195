import React from 'react'
import useSWR from 'swr'
import { create } from 'zustand'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import { columns } from './data-table-column'
import DataTableToolbar from './data-table-toolbar'

export const useForm = create((set) => ({
  order: 'updateTime',
  lang: 0,
  author: null,
  after: null,
  afters: [],
  setLang: (lang) => set({ lang }),
  setOrder: (order) => set({ order }),
  setAuthor: (author) => set({ author }),
  goAfter: (nextAfter) =>
    set((state) => {
      if (nextAfter === null) {
        return { after: null }
      }
      console.log(state.afters)
      const index = state.afters.findIndex((a) => a === nextAfter)
      if (index === -1) {
        return { after: nextAfter, afters: [...state.afters, nextAfter] }
      } else {
        return { after: nextAfter }
      }
    }),
}))

const Onlines = () => {
  const params = useForm((s) => {
    const { order, author, after, lang } = s
    return { order, author, after, lang }
  })
  const { data, isLoading, isValidating, mutate } = useSWR(['/frames', params])
  const nextAfter = data?.[data.length - 1]?.id

  const table = useReactTable({
    data: data,
    columns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  if (isLoading || isValidating) return <Loader />
  if (!data) return <EmptyDatas />
  return (
    <>
      <DataTableToolbar mutate={mutate} nextAfter={nextAfter} />
      <Card>
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

export default Onlines
