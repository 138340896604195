import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import React from 'react'
import useSWR from 'swr'
import { columns } from './data-table-column'
import DataTableToolbar from './data-table-toolbar'

const CommTaglibs = () => {
  const { data, isLoading, isValidating, mutate } = useSWR(['/taglibs'])

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })
  if (isLoading || isValidating) return <Loader />
  if (!data) return <EmptyDatas />
  return (
    <>
      <DataTableToolbar mutate={mutate} />
      <Card className="mt-2">
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

export default CommTaglibs
