import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  DotsHorizontalIcon,
  Link2Icon,
  TrashIcon,
  Pencil1Icon,
} from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ErrorsNote } from 'components/template-data-table-column'
import { delFetcher } from 'api/swr'
import useSWRMutation from 'swr/mutation'

export function DataTableRowActions({ row }) {
  const data = row.original
  const navigate = useNavigate()

  return (
    <>
      <div className="flex gap-3 items-center">
        <Button asChild>
          <Link to={'/editor/' + row.original.id}>
            <Pencil1Icon className="h-4 w-4 mr-2" /> Paint now
          </Link>
        </Button>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
            >
              <DotsHorizontalIcon className="h-4 w-4" />
              <span className="sr-only">Open menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="w-[160px]">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onSelect={() => navigate(`/admin/frames/${data.id}/avatars`)}
            >
              <Link2Icon className="w-4 h-4 mr-2" />
              Avatars
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DataTableRowDelete row={row} />
          </DropdownMenuContent>
        </DropdownMenu>
        <ErrorsNote row={row} />
      </div>
    </>
  )
}

export const DataTableRowDelete = ({ row }) => {
  const data = row.original
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation('/templates', delFetcher, {
    onSuccess: () => {
      toast({
        title: 'Template Deleted',
        description: 'The Template has been successfully deleted.',
      })
    },
  })
  const handleDelete = () => {
    trigger({ id: data.id })
  }

  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete',
      description: 'Are you sure you want to delete this?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem
      className="text-destructive focus:bg-destructive focus:text-destructive-foreground"
      onSelect={confirm}
    >
      <TrashIcon className="mr-2 h-4 w-4" />
      Delete{isMutating && 'ing...'}
      <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
    </DropdownMenuItem>
  )
}
