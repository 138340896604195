import { cn } from '@/lib/utils'
import { UpdateIcon } from '@radix-ui/react-icons'
import React from 'react'

const EmptyDatas = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center')} {...props}>
      <UpdateIcon className={cn('h-6 w-6 text-primary mr-2', className)} />
      {children ?? 'No results'}
    </div>
  ),
)
EmptyDatas.displayName = 'EmptyDatas'

export default EmptyDatas
