import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Form } from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { registerFetcher } from 'api/swr'
import { MyFormInput } from 'components/ui/MyFormField'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'
import { z } from 'zod'

const signupSchema = z.object({
  username: z.string().email('Please enter a valid email address'),
  password: z
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(16, 'Password must be at most 16 characters long')
    .regex(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character',
    ),
  inviteCode: z.string().length(6, 'Invite code must be 6 characters long'),
})

const Signup = () => {
  const navigate = useNavigate()
  // const setToken = useAuthStore((state) => state.setToken)

  const [defaultValues] = useState({
    username: '',
    password: '',
    inviteCode: '',
  })

  const form = useForm({
    resolver: zodResolver(signupSchema),
    defaultValues,
  })
  const { handleSubmit, setError } = form
  const { trigger, isMutating } = useSWRMutation('/signup', registerFetcher, {
    onSuccess: () => {
      navigate('/login')
    },
    onError: (error) => {
      const { field, message } = error
      setError(field, { message })
    },
  })

  const onSubmit = async (values) => {
    trigger(values)
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Sign Up</CardTitle>
        <CardDescription>
          create your Neku account for designers.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            <MyFormInput
              name="username"
              form={form}
              label="Email"
              placeholder="m@example.com"
              autoComplete="email"
            />
            <MyFormInput
              name="password"
              form={form}
              label="Password"
              type="password"
              placeholder="********"
              autoComplete="new-password"
            />
            <MyFormInput
              name="inviteCode"
              form={form}
              label="Invite Code"
              type="text"
              placeholder="Enter your invite code"
            />

            <Button type="submit" disabled={isMutating}>
              Sign Up{isMutating ? 'ing' : ''}
            </Button>
            <div className="flex mt-5 gap-2">
              Already have an account?{' '}
              <Link
                className="font-medium underline underline-offset-4"
                to="/login"
              >
                Sign in
              </Link>
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  )
}

export default Signup
