import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import {
  ChevronDownIcon,
  ClipboardIcon,
  Component1Icon,
  DownloadIcon,
  HeartFilledIcon,
  IdCardIcon,
  LayersIcon,
  StarFilledIcon,
} from '@radix-ui/react-icons'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useEditorStore } from 'CanvasCore/uiSlice'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card'
import { optimisticUpdateAvatars, useUpdateAvatars } from 'api'
import { delFetcher, fetcher, putFetcher } from 'api/swr'
import MyLink from 'components/ui/MyLink'
import { useContetLang } from 'hooks/config'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'
import { useCardAtom } from './DisplayAvatars'

const AvatarItem = ({ avatar }) => {
  const showInfo = useCardAtom((state) => state.info)
  const showBadge = useCardAtom((state) => state.badge)
  const showHeader = useCardAtom((state) => state.header)

  if (!avatar) {
    return null
  }

  return (
    <div className="flex flex-col gap-1 border p-1">
      {showHeader && (
        <div className="text-sm">
          <h1 className="">
            <HeaderText data={avatar} />
          </h1>
          <p className="text-xs text-muted-foreground">
            <DisplayUpdateTime updateTime={avatar.updateTime} />
          </p>
        </div>
      )}

      <a href={avatar?.url ?? avatar.thumb}>
        <div>
          <img
            alt="avatar"
            src={avatar?.thumb ?? avatar.url}
            className="h-auto w-auto object-cover transition-all hover:scale-105 aspect-square"
          />
        </div>
      </a>

      {showInfo && (
        <div className="flex flex-col space-y-1">
          <MyLink to={`/admin/users/${avatar.authorId}/avatars`}>
            <p>@{avatar.authorName}</p>
          </MyLink>
          <div className="flex items-center gap-2">
            <LayersIcon />
            {avatar?.layerCount}
            <Component1Icon />
            {avatar?.cptCount}
          </div>
          <UpdateLike avatar={avatar} />

          <div
            className="flex items-center gap-2
          text-lg font-bold leading-tight text-red-600 dark:text-red-500
          "
          >
            <StarFilledIcon className="text-destructive" />
            {avatar.starCount ?? 0}
          </div>

          <div className="flex items-center gap-2">
            <DownloadIcon />
            {avatar.dailyCount?.downloadCount ?? 0}/{avatar.dCount ?? 0}
          </div>
          <div className="flex items-center gap-2">
            Recreate:
            <MyLink to={`/admin/avatars/${avatar.id}/remixed`}>
              {avatar.dailyCount?.reCreateCount}/{avatar.reCreateCount}
            </MyLink>
          </div>
          <div className="flex items-center gap-2">
            Remix:
            <MyLink to={`/admin/avatars/${avatar.id}/remixed`}>
              {avatar.subAvatarCount ?? 0}
            </MyLink>
          </div>
          <OcInfo avatar={avatar} />
        </div>
      )}
      {showBadge && (
        <div className="flex flex-wrap gap-2 items-center">
          {avatar?.simple === 1 && <Badge>Simple</Badge>}
          {avatar?.aid && <Badge>Remix</Badge>}
          {avatar?.tag && <Badge>{avatar?.tag}</Badge>}
          {avatar?.desc && <Badge>OC</Badge>}

          {/* <RenderTags avatar={avatar} /> */}
        </div>
      )}
      <Actions avatar={avatar} />
    </div>
  )
}
const OcInfo = ({ avatar }) => {
  if (!avatar.desc) return null
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div className="flex items-center gap-2">
          <IdCardIcon />
          OC
        </div>
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        <div className="flex flex-col justify-between gap-4">
          {avatar.desc}
          <pre>
            {JSON.stringify(JSON.parse(avatar.avatarInfo ?? '{}'), null, 1)}
          </pre>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}

const ActionSetOffline = ({ avatar }) => {
  //TODO: miss undo offline
  //TODO: miss onSuccess
  const { trigger, isMutating } = useSWRMutation(
    '/avatars/' + avatar.id,
    putFetcher,
    {
      onSuccess: () => {},
    },
  )

  const updateState = (e) => {
    e.preventDefault()
    if (isMutating) return
    const value = avatar.state === 401 ? 0 : 401
    trigger({ key: 'state', value })
  }

  if (avatar.state !== 401) {
    return (
      <DropdownMenuItem onClick={updateState} className="text-destructive">
        set to online
      </DropdownMenuItem>
    )
  }
  return (
    <DropdownMenuItem onClick={updateState} className="text-destructive">
      set to offline
    </DropdownMenuItem>
  )
}

const ActionSetCover = ({ avatar }) => {
  const { trigger, isMutating } = useSWRMutation(
    `/templates/${avatar?.tid}`,
    putFetcher,
  )
  const setCover = (e) => {
    e.preventDefault() // 阻止默认关闭行为
    if (isMutating) return false
    const value = {
      cover: avatar.url,
      initDataId: avatar.id,
    }
    const body = { key: 'cover', value: JSON.stringify(value) }
    trigger(body)
  }
  return (
    <DropdownMenuItem onSelect={setCover}>
      {isMutating ? 'updating...' : 'set cover'}
    </DropdownMenuItem>
  )
}

const ActionSetPreview = ({ avatar }) => {
  const { trigger, isMutating } = useSWRMutation(
    `/templates/${avatar?.tid}`,
    putFetcher,
  )
  const setCover = (e) => {
    e.preventDefault() // 阻止默认关闭行为
    if (isMutating) return false
    const body = { key: 'preview', value: avatar.url }
    trigger(body)
  }
  return (
    <DropdownMenuItem onClick={setCover}>
      {isMutating ? 'updating...' : 'set preview'}
    </DropdownMenuItem>
  )
}

const AvatarGotoEditor = ({ avatar }) => {
  const navigate = useNavigate()
  const { setDrawers, setTonings } = useEditorStore((state) => state)
  const drawers = JSON.parse(avatar.drawData)?.drawers || []
  const tonings = JSON.parse(avatar.drawData)?.tonings || {}
  return (
    <DropdownMenuItem onClick={(e) => e.preventDefault()}>
      <div
        onClick={() => {
          navigate('/editor/' + avatar.tid)
          setDrawers(avatar.tid, drawers)
          setTonings(avatar.tid, tonings)
        }}
      >
        Go to editor
      </div>
    </DropdownMenuItem>
  )
}

const AvatarGotoSimilar = ({ avatar }) => {
  return (
    <DropdownMenuItem onClick={(e) => e.preventDefault()}>
      <Link to={'/admin/frames/' + avatar.tid + '/avatars'}>Go to similar</Link>
    </DropdownMenuItem>
  )
}

const AvatarActionCopyId = ({ avatar }) => {
  return (
    <DropdownMenuItem onSelect={() => navigator.clipboard.writeText(avatar.id)}>
      <ClipboardIcon className="w-4 h-4 mr-2" />
      Copy ID
    </DropdownMenuItem>
  )
}

const AvatarActionCopyShortId = ({ avatar }) => {
  return (
    <DropdownMenuItem
      onSelect={() => navigator.clipboard.writeText(avatar.shortId)}
    >
      <ClipboardIcon className="w-4 h-4 mr-2" />
      Copy Short ID
    </DropdownMenuItem>
  )
}

const AvatarActionCopyImageUrl = ({ avatar }) => {
  return (
    <DropdownMenuItem
      onSelect={() => navigator.clipboard.writeText(avatar.url)}
    >
      <ClipboardIcon className="w-4 h-4 mr-2" /> Copy Image Url
    </DropdownMenuItem>
  )
}

const AvatarActionCopyImageThumbUrl = ({ avatar }) => {
  return (
    <DropdownMenuItem
      onSelect={() => navigator.clipboard.writeText(avatar.thumb)}
    >
      <ClipboardIcon className="w-4 h-4 mr-2" /> Copy Thumb Url
    </DropdownMenuItem>
  )
}

const ActionSetSimple = ({ avatar }) => {
  const { handleUpdate, isMutating } = useUpdateAvatars(avatar.id)

  const updateSimple = (e) => {
    e.preventDefault() // 阻止默认关闭行为
    if (isMutating) {
      return false
    }
    handleUpdate('simple', avatar?.simple ? 0 : 1)
  }
  return (
    <DropdownMenuItem onSelect={updateSimple}>
      {isMutating && 'updating.'}
      {avatar?.simple === 1 ? 'undo simple' : 'Mark as simple'}
    </DropdownMenuItem>
  )
}

const ActionSetDlcCover = ({ avatar }) => {
  const { data: dlcs, isLoading } = useSWR(
    [avatar?.tid ? '/dlcs' : null, { tid: avatar.tid }],
    fetcher,
  )

  const { trigger, isMutating } = useSWRMutation(
    (id) => `/dlcs/${id}`,
    putFetcher,
  )

  const handleUpdate = (e, id) => {
    e.preventDefault()
    if (isMutating) return false
    trigger(id, { tid: avatar.tid, key: 'cover', value: avatar.url })
  }

  if (isLoading) {
    return (
      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
        fetch dlcs...
      </DropdownMenuItem>
    )
  }

  if (!dlcs || dlcs?.length === 0) {
    return (
      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
        no dlcs
      </DropdownMenuItem>
    )
  }

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>set as dlc icon</DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {dlcs?.map((dlc) => {
            return (
              <DropdownMenuItem
                key={dlc.id}
                onClick={(e) => handleUpdate(e, dlc.id)}
              >
                {dlc.cover && (
                  <img src={dlc.cover} alt="..." className="w-16 h-16 mr-2" />
                )}
                {dlc.name}
              </DropdownMenuItem>
            )
          })}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}

const AvatarActionDelete = ({ avatar }) => {
  const { trigger, isMutating } = useSWRMutation('/avatars', delFetcher)
  const handleDelete = (e) => {
    e.preventDefault()
    //TODO: add confirmation dialog
    if (isMutating) return false
    trigger(avatar.id)
  }
  return (
    <DropdownMenuItem onClick={handleDelete} className="text-destructive">
      Delete it
    </DropdownMenuItem>
  )
}

const ActionSetTag = ({ avatar }) => {
  const { data: tags } = useSWR(['/tagLibs'], fetcher)
  const { handleUpdate, isMutating } = useUpdateAvatars(avatar.id)

  const handleRemove = (e) => {
    e.preventDefault()
    if (isMutating) {
      return false
    }
    handleUpdate('addTag', '')
  }

  const handleAdd = (e, tag) => {
    e.preventDefault()
    if (isMutating) {
      return false
    }
    handleUpdate('addTag', tag.name)
  }

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>set tag({avatar?.tag})</DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <DropdownMenuItem onClick={(e) => handleRemove(e)}>
            Revemoe tag
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {tags?.map((tag) => (
            <DropdownMenuItem key={tag.id} onClick={(e) => handleAdd(e, tag)}>
              {tag.id} - {tag.name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}

const UpdateLike = ({ avatar }) => {
  const { trigger } = useSWRMutation(`/avatars/${avatar.id}`, putFetcher)

  const handleDone = () => {
    const body = {
      key: 'likeCount',
      value: avatar.likeCount ? avatar.likeCount + 5 : 5,
    }
    trigger(body, {
      optimisticData: () =>
        optimisticUpdateAvatars(avatar.id, body.key, body.value),
    })
  }

  return (
    <div className="flex items-center gap-2">
      <HeartFilledIcon className="text-destructive" />
      {avatar.likeCount}
      <Badge
        variant="outline"
        className="hover:bg-primary"
        onClick={handleDone}
      >
        +5
      </Badge>
    </div>
  )
}

const HeaderText = ({ data }) => {
  const { clientVer, contentLang } = data
  const { code, short } = useContetLang(contentLang)
  return (
    <>
      {code} - {short} - {clientVer}
    </>
  )
}

export default AvatarItem

const Actions = ({ avatar }) => {
  const [open, setOpen] = useState(false)

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          Actions
          <ChevronDownIcon className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56"
        onInteractOutside={() => setOpen(false)} // 点击外部时关闭菜单
      >
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <ActionSetSimple avatar={avatar} />
          <ActionSetCover avatar={avatar} />
          <ActionSetPreview avatar={avatar} />
          <ActionSetDlcCover avatar={avatar} />
          <ActionSetTag avatar={avatar} />
          <AvatarActionCopyId avatar={avatar} />
          <AvatarActionCopyShortId avatar={avatar} />
          <AvatarActionCopyImageUrl avatar={avatar} />
          <AvatarActionCopyImageThumbUrl avatar={avatar} />
          <AvatarGotoEditor avatar={avatar} />
          <AvatarGotoSimilar avatar={avatar} />
        </DropdownMenuGroup>

        <DropdownMenuLabel className="text-destructive mt-2">
          Please pay attention
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <ActionSetOffline avatar={avatar} />
          <AvatarActionDelete avatar={avatar} />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
