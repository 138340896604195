import { useAuthStore } from 'api/swr'

// const ROLE_ADS = "ROLE_ADS";
const ROLE_ADMIN = 'ROLE_ADMIN'
// const ROLE_OPERATORS = "ROLE_OPERATORS";

export const useRole = (role) => {
  const roles = useAuthStore((state) => state.auth?.user?.roles || [])
  return roles.includes(ROLE_ADMIN) || roles.includes(role)
}
