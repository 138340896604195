import dayjs from 'config/dayjsConfig'
import md5 from 'md5'

export const makeShortId = (length) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const convertStr2Id = (str) => {
  return md5(str.trim().toLowerCase()) ?? ''
}

export const selectFileReader = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = document.createElement('img')
      img.onload = () => {
        const id = makeShortId(6)
        resolve({
          id,
          name: file.name,
          original: e.target.result,
          w: img.width,
          h: img.height,
        })
      }
      img.src = e.target.result
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

export const formatTime = (updateTime) => {
  if (!updateTime) return ['', '']

  const showTime = dayjs(updateTime).format('MM-DD HH:mm')
  const ago = dayjs(updateTime).toNow(true)

  return [showTime, ago]
}
