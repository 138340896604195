import React, { useState, useRef } from 'react'
import { makeShortId } from 'commons'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import useSWRMutation from 'swr/mutation'
import { postFetcher } from 'api/swr'

function Create({ mutateCurrList }) {
  const [results, setResults] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])
  const fileInputRef = useRef(null)

  const { trigger, isMutating } = useSWRMutation('/webimgs', postFetcher)

  const handleClose = () => {
    setUploadFiles([])
    setResults([])
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
    mutateCurrList && mutateCurrList()
  }

  const handleCreate = async () => {
    if (isMutating || uploadFiles.length <= 0) {
      return
    }
    uploadFiles.forEach(({ id, original }) => {
      const data = { original, id }
      return trigger(data, {
        onSuccess: (r) => setResults((rs) => [...rs, r]),
      })
    })
  }

  const handleRemove = (id) => {
    setUploadFiles((items) => items.filter((item) => item.id !== id))
  }

  function setUpReader(file) {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = document.createElement('img')
      img.onload = () => {
        const id = makeShortId(24)
        setUploadFiles((items) => [
          ...items,
          {
            id,
            name: file.name,
            original: e.target.result,
            w: img.width,
            h: img.height,
          },
        ])
      }
      img.src = e.target.result
    }
    reader.readAsDataURL(file)
  }

  const selectFile = (files) => {
    if (files === undefined) {
      return
    }
    setResults([])
    setUploadFiles([])
    for (const file of files) {
      setUpReader(file)
    }
  }

  return (
    <Dialog onOpenChange={handleClose}>
      <DialogTrigger asChild>
        <Button className="ml-auto" variant="outline">
          Upload
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Upload web images</DialogTitle>
          <DialogDescription>
            注意图片安全性，不要上传敏感图片，不要竖中指，抽烟，裸露，虐待小孩，种族歧视等。
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-1 py-1">
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="picture">Picture</Label>
            <Input
              id="picture"
              type="file"
              multiple
              ref={fileInputRef}
              accept=".jpg, .png, .jpeg, .webp, .gif, .bmp"
              onChange={(e) => selectFile(e.target.files)}
            />
          </div>
          <div className="flex flex-col gap-1">
            {uploadFiles.map((data) => (
              <div key={data.id} className="flex gap-1">
                <img
                  src={data.original}
                  alt="..."
                  className="h-24 w-24 object-cover transition-all hover:scale-105 aspect-square"
                />
                <div className="flex flex-col gap-1">
                  {data.id}
                  <div>
                    w:{data.w} h:{data.h}
                  </div>
                  <Result id={data.id} results={results} />
                  <Button
                    variant="outline"
                    className="hover:bg-destructive hover:text-white"
                    onClick={() => handleRemove(data.id)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={handleClose}>
            Reset
          </Button>
          <Button
            disabled={isMutating || uploadFiles.length <= 0}
            onClick={handleCreate}
          >
            Upload {isMutating && 'ing...'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const Result = ({ id, results }) => {
  const match = results?.some((r) => r.data.id === id)
  if (match) return <span className="badge bg-success">{match && 'done'}</span>
  return null
}

export default Create
