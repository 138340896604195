import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import React, { useMemo } from 'react'
import InfoPanel from './Info'
import LayerPanel from './LayerPanel'
import ToningPanel from './ToningPanel'

const datas = [
  { name: 'Info', to: 'info' },
  { name: `Layers`, to: 'layers' },
  { name: `Tonings`, to: 'tonings' },
]

export const useError = (draft) => {
  return useMemo(() => {
    const errors = []
    if (draft.intro?.length < 10) {
      errors.push({ label: 'intro', message: 'Intro is too short' })
    }

    const layers = JSON.parse(draft.layers)
    if (!layers) {
      errors.push({ label: 'layers', message: 'No layers' })
    } else {
      if (
        !layers.some((layer) => layer.watermark === 1) &&
        draft.type === 'template'
      ) {
        errors.push({ label: 'watermark', message: 'No watermark' })
      }

      if (
        !layers.some((layer) => layer.bodymark === 1) &&
        draft.type === 'template'
      ) {
        errors.push({ label: 'bodymark', message: 'No bodymark' })
      }
      layers.forEach((layer, i) => {
        const shortLayers = layers.slice(i + 1)
        const zIndexLayer = shortLayers.find((data) => data.z === layer.z)
        const playIndexLayer = shortLayers.find(
          (data) => data.playIndex === layer.playIndex,
        )
        if (zIndexLayer) {
          errors.push({
            id: layer.id,
            label: 'layer',
            message: `layer ${zIndexLayer.id} and ${layer.id} repeat at zIndex`,
          })
        }
        if (playIndexLayer) {
          errors.push({
            id: layer.id,
            label: 'layer',
            message: `layer ${playIndexLayer.id} and ${layer.id} repeat at playIndex`,
          })
        }
        layer.components.length < 2 &&
          errors.push({
            id: layer.id,
            label: 'layer',
            message: `layer ${layer.id} cpts < 2`,
          })
      })
    }

    if (draft.ow !== draft.oh) {
      errors.push({ label: 'ow', message: 'Width and height are not equal' })
    }

    if (!draft?.ext?.simpleLimit && draft.type === 'template') {
      errors.push({
        label: 'simpleLimit',
        message:
          'the simple limit is required. The value of a double template is 7 (single template is 5)',
      })
    }

    if (!draft?.ext?.contentLangs?.length && draft.type === 'template') {
      errors.push({
        label: 'contentLangs',
        message: 'No contentLangs',
      })
    }

    const layerToningIds = layers.map((layer) => layer.toning).filter(Boolean)
    const tonings = JSON.parse(draft.tonings)
    if (!tonings) {
      errors.push({ label: 'tonings', message: 'No tonings' })
    } else {
      // 将 tonings 数组转换为一个对象，以 id 为键
      const toningObj = tonings.reduce((acc, toning) => {
        acc[toning.id] = toning
        return acc
      }, {})

      // 检查每一个 layer 的 toning 是否在 tonings 中
      const missingTonings = layerToningIds.filter((id) => !toningObj[id])
      if (missingTonings.length > 0) {
        errors.push({
          label: 'tonings',
          message: `Missing tonings for layer ids: ${missingTonings.join(', ')}`,
        })
      }

      // 检查 toning 的 colors 是否少于 3 个
      tonings.forEach((toning) => {
        if (toning.colors.length < 3) {
          errors.push({
            id: toning.id,
            label: 'tonings',
            message: `Toning ${toning.id} has < 3 colors`,
          })
        }
      })
    }

    return errors
  }, [draft])
}

const RightPanel = ({ draft }) => {
  const errors = useError(draft)
  return (
    <Tabs defaultValue="info">
      <TabsList className="lg:gap-4 justify-between">
        {datas.map((data) => (
          <TabsTrigger key={data.to} value={data.to}>
            {data.name}
          </TabsTrigger>
        ))}
      </TabsList>
      <CheckSafety errors={errors} />
      <TabsContent value="info" className="h-full">
        <InfoPanel draft={draft} />
      </TabsContent>
      <TabsContent
        value="layers"
        className="overflow-y-auto h-full max-h-screen"
      >
        <LayerPanel draft={draft} errors={errors} />
      </TabsContent>
      <TabsContent
        value="tonings"
        className="overflow-y-auto h-full max-h-screen"
      >
        <ToningPanel draft={draft} errors={errors} />
      </TabsContent>
    </Tabs>
  )
}

const CheckSafety = ({ errors }) => {
  return (
    <div className="p-4 border rounded-md my-4 text-destructive border-destructive">
      <ul className="list-disc px-4">
        {errors?.map((error, idx) => (
          <li key={idx}>{error.message}</li>
        ))}
      </ul>
    </div>
  )
}

export default RightPanel
