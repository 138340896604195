import React from 'react'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'
import { DataTableRowActions, DataTableRowText } from './data-table-row-action'

export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: 'Id',
    accessorKey: 'id',
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ row }) => <DataTableRowText row={row} entityKey={'name'} />,
  },
  {
    header: 'Update Time',
    accessorKey: 'updateTime',
    cell: ({ row }) => (
      <DisplayUpdateTime updateTime={row.original.updateTime} />
    ),
  },
  {
    header: 'Actions',
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
