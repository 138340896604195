import React, { useState } from 'react'
import useSWR from 'swr'
import { Component1Icon } from '@radix-ui/react-icons'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

export const DataTableRowActions = ({ row }) => {
  const [open, setOpen] = useState(false)
  const { lid, date, tid } = row.original
  const { data } = useSWR([`/frames/${tid}/daily/cpt`, { date, lid }])
  if (!data) return
  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger className="w-full">
          <Component1Icon className="mr-2 h-4 w-4" />
        </DialogTrigger>
        <DialogContent className="max-h-screen overflow-y-auto">
          <DialogHeader className={'flex'}>
            <DialogTitle>cpts</DialogTitle>
          </DialogHeader>
          <div>
            <span className="me-4">
              PRO:{' '}
              {(data.filter((cpt) => cpt.pro > 0).length / data.length) * 100}%
            </span>
            <span>
              AI:{' '}
              {(data.filter((cpt) => cpt.ai > 0).length / data.length) * 100}%
            </span>
          </div>
          <div className="flex flex-wrap">
            {data.map((cpt, index) => (
              <div key={index} className="mb-4 me-4">
                <div
                  className={`border aspect-square rounded-md object-cover flex items-center justify-center w-24 h-24 ${cpt.pro > 0 ? 'border-green-700' : cpt.ai > 0 ? 'border-red-700' : ''}`}
                >
                  <img
                    src={`https://img.zthd.io/an1/ats/${cpt.cid}`}
                    alt={cpt.cid}
                    className="max-h-full"
                  />
                </div>
                <div className="mt-2">
                  <p className="text-sm font-medium leading-none">{cpt.cid}</p>
                  <p className="text-sm text-muted-foreground">{cpt.count}</p>
                </div>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
