import React, { useState } from 'react'
import { create } from 'zustand'
import useSWR from 'swr'
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import { columns } from './data-table-column'
import DataTableToolbar from './data-table-toolbar'

export const useForm = create((set) => ({
  after: null,
  afters: [],
  goAfter: (nextAfter) =>
    set((state) => {
      if (nextAfter === null) {
        return { after: null }
      }
      const index = state.afters.findIndex((a) => a === nextAfter)
      if (index === -1) {
        return { after: nextAfter, afters: [...state.afters, nextAfter] }
      } else {
        return { after: nextAfter }
      }
    }),
}))

const LayerIcons = () => {
  const [sorting, setSorting] = useState([])
  const params = useForm((s) => {
    const { after } = s
    return { after }
  })
  const { data, isLoading, isValidating, mutate } = useSWR([
    '/meme-stickers',
    params,
  ])
  const nextAfter = data?.[data.length - 1]?.id

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  if (isLoading || isValidating) return <Loader />
  if (!data) return <EmptyDatas />

  return (
    <>
      <DataTableToolbar mutate={mutate} nextAfter={nextAfter} />
      <Card>
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

export default LayerIcons
