import Loader from '@/components/ui/Loader'
import Skeleton from '@/components/ui/skeleton'
import { cn } from '@/lib/utils'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { useUI } from 'Editor'
import React, { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

const LayerPicker = () => {
  const { tid } = useParams()
  const { data: layers, isLoading } = useSWR(['/layers', { tid }])

  const lid = useUI((state) => state.lid)

  const scrollRef = useRef(null)
  const layerRefs = useRef({})

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (lid) {
      handleItemClick(lid)
    }
  }, [lid])

  const handleItemClick = (id) => {
    const element = layerRefs.current[id]
    if (element && scrollRef.current) {
      const elementRect = element.getBoundingClientRect()
      const scrollContainerRect = scrollRef.current.getBoundingClientRect()
      const scrollOffset =
        elementRect.left -
        scrollContainerRect.left -
        scrollContainerRect.width / 2 +
        elementRect.width / 2

      scrollRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' })
    }
  }

  const setLayerRef = useCallback((element, id) => {
    if (element) {
      layerRefs.current[id] = element
    }
  }, [])

  if (isLoading) return <Loader>Load layers...</Loader>

  return (
    <div className="flex items-center">
      <div onClick={scrollLeft}>
        <ChevronLeftIcon className="w-8 h-8 text-gray-400 hover:text-primary" />
      </div>
      <div
        className="flex overflow-x-scroll w-full gap-4 scrollbar-hide"
        ref={scrollRef}
      >
        <DraftLayer setRef={(el) => setLayerRef(el, 'draft')} />
        {layers?.map((layer) => (
          <Layer
            key={layer.id}
            layer={layer}
            setRef={(el) => setLayerRef(el, layer.id)}
          />
        ))}
      </div>
      <div className="ml-auto" onClick={scrollRight}>
        <ChevronRightIcon className="w-8 h-8 text-gray-400" />
      </div>
    </div>
  )
}

const DraftLayer = ({ setRef }) => {
  const id = 'draft'
  const selected = useUI((state) => state.lid === id)
  const toggleLayer = useUI((state) => state.toggleLayer)

  const handleClick = () => {
    toggleLayer(id)
  }

  return (
    <div
      className="flex flex-col w-full min-w-12 py-1 space-y-1"
      ref={setRef}
      onClick={handleClick}
    >
      <Skeleton
        className={cn(
          'w-full h-full object-cover border border-transparent rounded-md cursor-pointer flex items-center justify-center',
          'hover:border-primary',
          selected && 'border-primary',
        )}
      >
        Draft
      </Skeleton>
      <div className={cn('w-full h-0.5 bg-primary rounded', 'invisible')} />
    </div>
  )
}

const Layer = ({ layer, setRef }) => {
  const { tid } = useParams()
  const id = layer.id
  const selected = useUI((state) => state.lid === id)

  const inDrawer = useEditorStore((state) =>
    state.editors[tid]?.drawers?.some((drawer) => drawer.lid === id),
  )

  const toggleLayer = useUI((state) => state.toggleLayer)

  const handleClick = () => {
    toggleLayer(id)
  }

  return (
    <div
      className="flex flex-col w-full min-w-12 py-1 space-y-1"
      ref={setRef}
      onClick={handleClick}
    >
      <img
        className={cn(
          'w-full h-full object-cover border border-transparent rounded-md cursor-pointer',
          'hover:border-primary',
          selected && 'border-primary',
        )}
        src={`https://img.zthd.io/an1/acs/${layer.cover}`}
        alt="..."
      />
      <div
        className={cn(
          'w-full h-0.5 bg-primary rounded',
          inDrawer ? '' : 'invisible',
        )}
      />
    </div>
  )
}

export default LayerPicker
