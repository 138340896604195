import React, { useMemo, useState } from 'react'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Checkbox } from '@/components/ui/checkbox'
import Selector from 'components/ui/Selector'
import useSWRMutation from 'swr/mutation'
import useSWR, { mutate } from 'swr'
import { useParams } from 'react-router-dom'
import { fetcher, putFetcher } from 'api/swr'
import Loader from '@/components/ui/Loader'
import { cn } from '@/lib/utils'
import { Pencil1Icon } from '@radix-ui/react-icons'

const maxes = Array.from({ length: 4 }, (v, i) => ({
  code: i + 1,
  name: i + 1,
}))

const zIndexes = Array.from({ length: 400 }, (v, i) => ({
  code: i - 199,
  name: i - 199,
}))

const palyIndexes = Array.from({ length: 200 }, (v, i) => ({
  code: i,
  name: i,
}))

const LayerEditor = ({ layer }) => {
  const { tid } = useParams()

  const { trigger, isMutating } = useSWRMutation(
    `/layers/${layer.id}`,
    putFetcher,
  )

  const { data: tonings } = useSWR(['/tonings', { tid }], fetcher)
  const { data: LayerGroups = [] } = useSWR(['/layer-groups'])

  const [open, setOpen] = useState(false)

  const toningItems = useMemo(
    () => tonings?.map((t) => ({ code: t.id, name: t.name })) ?? [],
    [tonings],
  )

  const triggerLayer = (data) => {
    if (isMutating) return false
    trigger(data, {
      onSuccess: () => {
        mutate(['/layers', { tid }])
      },
    })
  }

  const handleUpdateZIndex = (value) => {
    triggerLayer({ tid, key: 'z', value })
  }

  const handleUpdatePlayIndex = (value) => {
    triggerLayer({ tid, key: 'playIndex', value })
  }

  const handleUpdateToning = (value) => {
    triggerLayer({ tid, key: 'toning', value })
  }

  const handleUpdateGroup = (value) => {
    triggerLayer({ tid, key: 'gid', value })
  }

  const handleUpdateMaxOpt = (value) => {
    triggerLayer({ tid, key: 'maxChoose', value: Number(value) })
  }

  const handleUpdateBodymark = (value) => {
    triggerLayer({ tid, key: 'bodymark', value: layer.bodymark === 1 ? 0 : 1 })
  }

  const handleUpdateSuitmark = (value) => {
    triggerLayer({ tid, key: 'suitmark', value: layer.suitmark === 1 ? 0 : 1 })
  }

  const handleUpdateWatermark = (value) => {
    triggerLayer({
      tid,
      key: 'watermark',
      value: layer.watermark === 1 ? 0 : 1,
    })
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger>
          <Pencil1Icon className="mr-2 h-4 w-4" />
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit layer</DialogTitle>
            <DialogDescription>update layer props</DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="zIndex" className="text-right">
                Z index
              </Label>
              <Selector
                id="zIndex"
                datas={zIndexes}
                update={handleUpdateZIndex}
                value={layer.z}
                labelName="zIndex"
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="playIndex" className="text-right">
                Play index
              </Label>
              <Selector
                id="playIndex"
                datas={palyIndexes}
                update={handleUpdatePlayIndex}
                value={layer.playIndex}
                labelName="playIndex"
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="toning" className="text-right">
                Bind toning
              </Label>
              <Selector
                id="toning"
                datas={toningItems}
                update={handleUpdateToning}
                value={layer.toning}
                showCode
                labelName="toning"
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="group" className="text-right">
                Bind group
              </Label>
              <Selector
                id="group"
                datas={LayerGroups.map((item) => ({
                  code: item.id,
                  name: item.name,
                }))}
                update={handleUpdateGroup}
                value={layer.gid}
                showCode
                labelName="group"
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="maxOpt" className="text-right">
                Max Optional
              </Label>
              <Selector
                id="maxOpt"
                datas={maxes}
                update={handleUpdateMaxOpt}
                value={layer.max}
                labelName="Max Optional"
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="body" className="text-right"></Label>
              <div className="col-span-3 flex items-center gap-2">
                <Checkbox
                  id="body"
                  checked={layer.bodymark === 1}
                  onCheckedChange={handleUpdateBodymark}
                />
                <span>is body</span>
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="watermark" className="text-right"></Label>
              <div className="col-span-3 flex items-center gap-2">
                <Checkbox
                  id="watermark"
                  checked={layer.watermark === 1}
                  onCheckedChange={handleUpdateWatermark}
                />
                <span>Water mark</span>
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="suitmark" className="text-right"></Label>
              <div className="col-span-3 flex items-center gap-2">
                <Checkbox
                  id="suitmark"
                  checked={layer.suitmark === 1}
                  onCheckedChange={handleUpdateSuitmark}
                />
                <span>Suit mark</span>
              </div>
            </div>
          </div>
          <div
            className={cn(
              'grid grid-cols-4 items-center gap-4',
              isMutating ? '' : 'invisible',
            )}
          >
            <Label></Label>
            <div className="col-span-3 flex items-center gap-2">
              <Loader /> Updating...
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LayerEditor
