import React from 'react'
import { cn } from '@/lib/utils'
import { UpdateIcon } from '@radix-ui/react-icons'

const Updating = React.forwardRef(({ className, children, ...props }, ref) => (
  <div ref={ref} className={cn('flex items-center')} {...props}>
    <UpdateIcon className={cn('w-4 h-4 animate-spin mr-2', className)} />
    Updating...{children}
  </div>
))
Updating.displayName = 'Updating'

export default Updating
