import { Button } from '@/components/ui/button'
import { useEditorStore } from 'CanvasCore/uiSlice'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BindStyle from './Cpt/BindStyle'
import ChangeDLC from './Cpt/ChangeDLC'
import ChangeLayer from './Cpt/ChangeLayer'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { ToastAction } from '@/components/ui/toast'
import { useToast } from '@/components/ui/use-toast'
import {
  ChevronDownIcon,
  ClipboardIcon,
  TrashIcon,
} from '@radix-ui/react-icons'
import { delFetcher, putFetcher } from 'api/swr'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import UpdateCoverItem from './Cpt/UpdateCover'
import UpdateParent from './Cpt/UpdateParent'

const DataItemActions = ({ cpt, cpts }) => {
  const [open, setOpen] = useState(false)

  if (!cpt) return null
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="sm">
          Actions
          <ChevronDownIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56"
        onInteractOutside={() => setOpen(false)} // 点击外部时关闭菜单
      >
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onSelect={() => navigator.clipboard.writeText(cpt.id)}
          >
            <ClipboardIcon className="mr-2 h-4 w-4" /> Copy ID
          </DropdownMenuItem>
          {/* <ChangeCover cpt={cpt} /> */}
          <BindStyle cpt={cpt} cpts={cpts} />
          <ChangeDLC cpt={cpt} />
          <ChangeLayer cpt={cpt} cpts={cpts} />
          <UpdateCoverItem cpt={cpt} />
          <UpdateParent cpt={cpt} cpts={cpts} />
        </DropdownMenuGroup>

        <DropdownMenuLabel className="text-destructive mt-2">
          Please pay attention
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DeleteButton cpt={cpt} />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const DeleteButton = ({ cpt }) => {
  const { tid } = useParams()
  const { toast } = useToast()

  //TODO: need check childs

  const { trigger: undo } = useSWRMutation(
    `/components/${cpt.id}`,
    putFetcher,
    {
      onSuccess: () => {
        mutate(['/components', { tid }])
        toast({
          title: 'Undo Successful',
          description: 'The component has been restored.',
        })
      },
    },
  )

  const { trigger, isMutating } = useSWRMutation(`/components`, delFetcher, {
    onSuccess: () => {
      deselectCpt(tid, cpt.id)
      mutate(['/components', { tid }])
      toast({
        title: 'Component Deleted',
        description: 'The component has been successfully deleted.',
        action: (
          <ToastAction
            altText="Undo"
            onClick={() => {
              undo({ tid, key: 'state', value: 1 })
            }}
          >
            Undo
          </ToastAction>
        ),
      })
    },
  })
  const deselectCpt = useEditorStore((state) => state.deselectCpt)

  //TODO: confirm
  const handleDelete = () => {
    trigger({ id: cpt.id })
  }

  return (
    <DropdownMenuItem onClick={handleDelete} className="text-destructive">
      <TrashIcon className="mr-2" /> Delete it{isMutating && 'ing...'}
    </DropdownMenuItem>
  )
}

export default DataItemActions
