import React, { useMemo } from 'react'
import AvatarItem from './AvatarItem'
import { create } from 'zustand'
import Checkbox from 'components/ui/MyCheckbox'
import Divider from 'components/ui/Divider'
import Dropdown from 'components/ui/Selector'

export const useCardAtom = create((set) => ({
  info: true,
  badge: true,
  header: true,
  pid: -1,
  toggleInfo: () => set((state) => ({ info: !state.info })),
  toggleBadge: () => set((state) => ({ badge: !state.badge })),
  toggleHeader: () => set((state) => ({ header: !state.header })),
  updatePid: (value) => set((state) => ({ pid: value })),
}))

function DisplayAvatars({ avatars }) {
  const pid = useCardAtom((state) => state.pid)

  const filterAvatar = useMemo(() => {
    if (pid === -1 || !pid) return avatars
    return avatars.filter((avatar) =>
      avatar.clientVer.startsWith(pid.toString()),
    )
  }, [pid, avatars])
  return (
    <div className="py-2">
      <div className="flex items-center gap-4 py-1">
        <UpdatePid />
        <ToggleHeader />
        <ToggleBadge />
        <ToggleInfo />
      </div>
      <Divider />
      <div className="grid grid-cols-9 lg:grid-cols-6 xl:grid-cols-9 gap-2">
        {filterAvatar?.map((avatar, index) => (
          <AvatarItem avatar={avatar} key={avatar.id} index={index} />
        ))}
      </div>
    </div>
  )
}

const ToggleHeader = () => {
  const header = useCardAtom((state) => state.header)
  const toggle = useCardAtom((state) => state.toggleHeader)
  return (
    <Checkbox checked={header} onCheckedChange={toggle} label={'show header'} />
  )
}

const ToggleBadge = () => {
  const badge = useCardAtom((state) => state.badge)
  const toggle = useCardAtom((state) => state.toggleBadge)
  return (
    <Checkbox
      checked={badge}
      onCheckedChange={toggle}
      label={'show badge'}
    ></Checkbox>
  )
}

const ToggleInfo = () => {
  const info = useCardAtom((state) => state.info)
  const toggleInfo = useCardAtom((state) => state.toggleInfo)
  return (
    <Checkbox
      checked={info}
      onCheckedChange={toggleInfo}
      label={'show info'}
    ></Checkbox>
  )
}

const UpdatePid = () => {
  const pid = useCardAtom((state) => state.pid)
  const updatePid = useCardAtom((state) => state.updatePid)
  return (
    <Dropdown
      datas={[
        { code: -1, name: 'All' },
        { code: 11, name: 'android' },
        { code: 12, name: 'ios' },
        { code: 16, name: 'oc android' },
      ]}
      showLabel
      showCode
      labelName="Pid"
      value={pid}
      update={updatePid}
    />
  )
}

export default DisplayAvatars
