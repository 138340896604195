import React from 'react'
import { Outlet } from 'react-router-dom'
import logoImg from 'assets/logo.png'

const Index = () => {
  return (
    <>
      <TopNavbar />
      <main>
        <Outlet />
      </main>
    </>
  )
}

const TopNavbar = () => {
  return (
    <header className="sticky top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 p-1 gap-2">
      <div className="container mx-auto flex h-14 items-center">
        <a className="mr-6 flex items-center space-x-2" href="/">
          <img
            src={logoImg}
            alt="Logo"
            className="d-inline-block align-text-top w-8 h-8"
          />
          <span className="hidden font-bold sm:inline-block">Neku</span>
        </a>
      </div>
    </header>
  )
}

export default Index
