import React, { useState } from 'react'
import {
  Component1Icon,
  DimensionsIcon,
  HeartFilledIcon,
  LayersIcon,
  QuestionMarkCircledIcon,
} from '@radix-ui/react-icons'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import Skeleton from '@/components/ui/skeleton'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { useUpdateDrafts, useUpdateTemplates } from 'api/index'
import MyLink from 'components/ui/MyLink'
import { LevelOptions } from 'config'

const Dot = ({ className }) => (
  <div
    className={cn(
      'inline-block w-4 h-4 mr-2 bg-secondary rounded-full',
      className,
    )}
  />
)

export const DataTableRowText = ({ row, entityKey }) => {
  const data = row.original

  const { handleUpdate, isMutating } = useUpdateTemplates(data.id)

  const [text, setText] = useState(data[entityKey] || '')
  const [isOpen, setIsOpen] = useState(false)

  const disabled = !text || isMutating || data[entityKey] === text
  const onSubmit = () => {
    if (disabled) {
      return null
    }
    handleUpdate(entityKey, text, false)
    setIsOpen(false)
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <div
            onClick={() => setIsOpen(true)}
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
            )}
          >
            {data[entityKey]}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {entityKey}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {entityKey}
              </Label>
              <Input
                className="col-span-3"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || !text || text === data[entityKey]}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const DataTableRowTextExt = ({ row, entityKey, type }) => {
  const data = row.original
  const initData = data?.ext?.[entityKey] || ''
  const { handleUpdate, isMutating } = useUpdateDrafts(data.id)

  const [text, setText] = useState(initData)
  const [isOpen, setIsOpen] = useState(false)

  const disabled = !text || isMutating || initData === text
  const onSubmit = () => {
    if (disabled) {
      return null
    }
    handleUpdate(entityKey, text, false)
    setIsOpen(false)
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <div
            onClick={() => setIsOpen(true)}
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
            )}
          >
            {initData}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {entityKey}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {entityKey}
              </Label>
              <Input
                className="col-span-3"
                value={text}
                type={type || 'text'}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || !text || text === data?.ext[entityKey]}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const columnInfo = {
  header: 'Props',
  cell: ({ row }) => (
    <div className="flex flex-col space-y-2">
      {row.original.ow && (
        <div className="flex items-center">
          <DimensionsIcon className="w-4 h-4 mr-2" />
          {row.original.ow} x {row.original.oh}
        </div>
      )}
      <div className="flex items-center">
        <LayersIcon className="w-4 h-4 mr-2" />
        {row.original.layerCount}
      </div>
      <div className="flex items-center">
        <Component1Icon className="w-4 h-4 mr-2" />
        {row.original.componentCount}
      </div>
    </div>
  ),
}

export const columnCover = {
  header: 'Cover',
  accessorKey: 'cover',
  cell: ({ row }) =>
    row.original.cover ? (
      <img
        alt="..."
        className="aspect-square rounded-md object-cover w-24 h-24"
        src={row.original.cover}
      />
    ) : (
      <Skeleton className="w-24 h-24 rounded-xl" />
    ),
}

export const columnPreview = {
  header: 'preview',
  accessorKey: 'preivew',
  cell: ({ row }) => (
    <>
      {row.original.preview ? (
        <img
          alt="..."
          className="aspect-square rounded-md object-cover w-24 h-24"
          src={row.original.preview}
        />
      ) : (
        <Skeleton className="h-24 w-24 rounded-xl" />
      )}
    </>
  ),
}

export const columnName = {
  header: 'Name',
  accessorKey: 'name',
  cell: ({ row }) => (
    <>
      <div className="fw-bold">{row.original.name}</div>
      <div className="text-black/90 text-xs">{row.original.id}</div>
      <p className="text-ellipsis w-56 text-black/60 text-xs line-clamp-2">
        {row.original.intro}
      </p>
    </>
  ),
}

export const columnPublisher = {
  header: 'Publisher',
  accessorKey: 'publisher',
  cell: ({ row }) => (
    <MyLink
      to={`/admin/publishers/${row.original.painter || row.original.author}`}
    >
      {row.original.publisher}
    </MyLink>
  ),
}

export const cloumnOnlinePublisher = {
  header: 'Nick name',
  accessorKey: 'onlinePublisher',
}

export const columnLabels = {
  header: 'Labels',
  accessorKey: 'labels',
  cell: ({ row }) => (
    <div className="flex flex-wrap gap-1">
      {row.original.labels?.map((tag) => (
        <Badge variant="outline" key={tag}>
          <small>{tag}</small>
        </Badge>
      ))}
    </div>
  ),
}

export const columnExtLabels = {
  header: 'Labels',
  accessorKey: 'labels',
  cell: ({ row }) => (
    <div className="flex flex-wrap gap-1">
      {row.original.ext?.labels?.map((tag) => (
        <Badge variant="outline" key={tag}>
          <small>{tag}</small>
        </Badge>
      ))}
    </div>
  ),
}

export const columnTags = {
  header: 'Tags',
  accessorKey: 'tags',
  cell: ({ row }) => (
    <>
      <div className="flex flex-wrap gap-1">
        {row.original.tags?.map((tag) => (
          <Badge
            variant={tag === tag.toLowerCase() ? 'outline' : 'default'}
            key={tag}
          >
            <small>{tag}</small>
          </Badge>
        ))}
      </div>
    </>
  ),
}

export const columnExtTags = {
  header: 'Tags',
  accessorKey: 'tags',
  cell: ({ row }) => (
    <>
      <div className="flex flex-wrap gap-1">
        {row.original?.ext?.tags?.map((tag) => (
          <Badge variant="outline" key={tag}>
            <small>{tag}</small>
          </Badge>
        ))}
      </div>
    </>
  ),
}

export const columnLevel = {
  header: 'Level',
  accessorKey: 'level',
  cell: ({ row }) => (
    <>
      {row.original?.level !== undefined && (
        <Badge variant="outline">
          <small>
            {
              LevelOptions.find(
                (element) => element.code === row.original.level,
              )?.name
            }
          </small>
        </Badge>
      )}
    </>
  ),
}

export const columnExtLevel = {
  header: 'Level',
  accessorKey: 'level',
  cell: ({ row }) => (
    <>
      {row.original?.ext?.level && (
        <Badge variant="outline">
          <small>
            {
              LevelOptions.find(
                (element) => element.code === row.original?.ext?.level,
              )?.name
            }
          </small>
        </Badge>
      )}
    </>
  ),
}

export const columnSku = {
  header: 'sku',
  accessorKey: 'sku',
  cell: ({ row }) => (
    <>
      {row.original?.sku && (
        <Badge variant="outline">
          <small>{row.original?.sku}</small>
        </Badge>
      )}
    </>
  ),
}

export const columnExtSku = {
  header: 'sku',
  accessorKey: 'sku',
  cell: ({ row }) => (
    <>
      {row.original?.ext?.sku && (
        <Badge variant="outline">
          <small>{row.original?.ext?.sku}</small>
        </Badge>
      )}
    </>
  ),
}

export const columnAvatars = {
  header: 'Avatars',
  accessorKey: 'avatars',
  cell: ({ row }) => (
    <>
      <div>
        <MyLink to={`/admin/frames/${row.original.id}/avatars`}>
          avatars:{row.original.avatarCount}
        </MyLink>
      </div>
      <div>
        recreate:{row.original.reCreateCount}/
        {row.original?.dailyCount?.reCreateCount}
      </div>
      <div className="flex items-center text-destructive">
        <HeartFilledIcon className="w-4 h-4 mr-2" />
        {row.original?.likeCount}
      </div>
    </>
  ),
}

export const columnState = {
  header: 'State',
  cell: ({ row }) => {
    if (row.original.errors) {
      return (
        <div className="flex items-center text-destructive">
          <Dot className="bg-destructive" />
          Reject
        </div>
      )
    }
    return (
      <div className="flex items-center">
        {row.original.publishTime ? (
          <>
            <Dot className="bg-primary" /> Published
          </>
        ) : row.original.version ? (
          <>
            <Dot className="bg-yellow-400" />
            In review
          </>
        ) : (
          <>
            <Dot className="bg-secondary" /> Draft
          </>
        )}
      </div>
    )
  },
}

export const columnSimpleLimit = {
  header: 'SimpleLimit',
  accessorKey: 'simpleLimit',
  cell: ({ row }) => (
    <DataTableRowTextExt row={row} entityKey={'simpleLimit'} type="number" />
  ),
}

export const columnExtContentLangs = {
  header: 'ContentLangs',
  accessorKey: 'contentLangs',
  cell: ({ row }) => (
    <>
      {row.original.ext?.contentLangs?.map((item) => (
        <>
          <Badge variant="outline" className={'me-1'}>
            <small>{item}</small>
          </Badge>
        </>
      ))}
    </>
  ),
}

export const ErrorsNote = ({ row }) => {
  const errors = row.original?.errors
  if (!errors) return
  const list = JSON.parse(errors)
  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <QuestionMarkCircledIcon color="red" />
        </PopoverTrigger>
        <PopoverContent className="p-4" align="start">
          {list?.map((item) => (
            <div key={item.message} className="text-[red]">
              <span className="me-2">{item.label}:</span>
              <span>{item.message}</span>
            </div>
          ))}
        </PopoverContent>
      </Popover>
    </>
  )
}
