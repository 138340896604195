import React from 'react'
import { CaretSortIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import Skeleton from '@/components/ui/skeleton'
import {
  DataTableRowActions,
  DataTableRowCheckbox,
  DataTableRowOpt,
  DataTableRowSelect,
  DataTableRowText,
} from './data-table-row-action'

export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: '',
    accessorKey: 'cover',
    cell: ({ row }) => (
      <>
        {row.original.code ? (
          <img
            alt="..."
            className="aspect-square rounded-md object-cover h-24 w-24"
            src={'https://img.zthd.io/an1/acs/' + row.original.code}
          />
        ) : (
          <Skeleton className="h-24 w-24 rounded-xl" />
        )}
      </>
    ),
  },
  {
    accessorKey: 'name',
    header: 'name',
    cell: ({ row }) => (
      <>
        <DataTableRowText row={row} entityKey={'name'} />
        <div className="text-[grey] ml-2">{row.original.id}</div>
      </>
    ),
  },
  {
    accessorKey: 'author',
    header: 'author',
    cell: ({ row }) => <DataTableRowText row={row} entityKey={'author'} />,
  },
  {
    accessorKey: 'z',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          zIndex
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <DataTableRowText row={row} entityKey={'z'} type={'number'} />
    ),
  },
  {
    accessorKey: 'p',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          pIndex
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => (
      <DataTableRowText row={row} entityKey={'p'} type={'number'} />
    ),
  },
  {
    accessorKey: 'groupId',
    header: 'gid',
    cell: ({ row }) => <DataTableRowSelect row={row} entityKey={'gid'} />,
  },
  {
    accessorKey: '',
    header: 'maxChoose',
    cell: ({ row }) => <DataTableRowSelect row={row} entityKey={'max'} />,
  },
  {
    accessorKey: 'body',
    header: 'bodyMark',
    cell: ({ row }) => <DataTableRowCheckbox row={row} entityKey={'body'} />,
  },
  {
    accessorKey: 'water',
    header: 'waterMark',
    cell: ({ row }) => <DataTableRowCheckbox row={row} entityKey={'water'} />,
  },
  {
    accessorKey: 'suit',
    header: 'suitMark',
    cell: ({ row }) => <DataTableRowCheckbox row={row} entityKey={'suit'} />,
  },
  {
    accessorKey: 'opt',
    header: 'required',
    cell: ({ row }) => <DataTableRowOpt row={row} entityKey={'opt'} />,
  },
  {
    header: 'Actions',
    cell: ({ row }) => (
      <>
        <DataTableRowActions row={row} />
      </>
    ),
  },
]
