import { cn } from '@/lib/utils'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { color2Linear } from 'CanvasCore/utils'
import { ColorCard } from 'components/ui/ColorCard'
import { useSelectedColor, useUI } from 'Editor'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const useSelected = (tid, id) => {
  const selectedColor = useSelectedColor(tid, 'default')
  return selectedColor?.id === id
}

const LinearColor = ({ color }) => {
  const { tid } = useParams()
  const { id, colors } = color

  const linear = useMemo(() => color2Linear(colors), [colors])
  const toningId = useUI((state) => state.toningId)

  const selected = useSelected(tid, color.id)

  const selectColor = useEditorStore((state) => state.selectColor)
  const deselectColor = useEditorStore((state) => state.deselectColor)

  const handleClick = () => {
    if (selected) {
      //unselected
      deselectColor(tid, toningId)
    } else {
      selectColor(tid, toningId, 'default', { id, color: linear })
    }
  }
  if (!color || !linear) {
    return null
  }
  return (
    <ColorCard
      onClick={handleClick}
      className={cn(
        'border-transparent',
        selected && 'border-2 border-primary',
      )}
    >
      <div
        className="rounded-lg w-5/6 h-5/6"
        style={{ backgroundImage: linear }}
        onClick={handleClick}
      />
    </ColorCard>
  )
}

export default LinearColor
