import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'
// import rehypeRaw from 'rehype-raw'
// import remarkGfm from 'remark-gfm'
import './style.css'

const MarkdownRenderer = () => {
  const { file } = useParams()
  const [content, setContent] = useState('')
  useEffect(() => {
    const loadMarkdown = async () => {
      try {
        const context = require.context('../docs', true, /\.md$/)
        const filePath = context(`./en/${file}.md`)
        const response = await fetch(filePath)
        if (!response.ok) throw new Error('Network response was not ok')
        const text = await response.text()
        setContent(text)
      } catch (err) {
        console.log(err)
      }
    }
    loadMarkdown()
  }, [file])

  return (
    <div className="markdown-container">
      <ReactMarkdown rehypePlugins={[]}>{content}</ReactMarkdown>
    </div>
  )
}

export default MarkdownRenderer
