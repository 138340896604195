import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import React from 'react'
import useSWR from 'swr'
import { columns } from './data-table-column'
import DataTableToolbar from './data-table-toolbar'

const DlcPanel = () => {
  const { data, isLoading, isValidating, mutate } = useSWR(['/dlc-frames'])

  const table = useReactTable({
    data: data,
    columns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  if (isLoading || isValidating) return <Loader>Load dlc frames...</Loader>
  if (!data) return <EmptyDatas />

  return (
    <div>
      <DataTableToolbar mutate={mutate} />
      <Card>
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </div>
  )
}

export default DlcPanel
