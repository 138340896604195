import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { PlusIcon, ResetIcon, UpdateIcon } from '@radix-ui/react-icons'
import { postFetcher } from 'api/swr'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { parseLinearGradient } from 'CanvasCore/utils'
import { makeShortId } from 'commons'
import Title from 'components/ui/Title'
import { useUI } from 'Editor'
import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { PICKER_COLOR } from './ColorPicker'
import AdjustColor from './ColorPicker/AdjustColor'

export const CreateColorPanel = () => {
  const { tid } = useParams()
  const togglePanel = useUI((state) => state.togglePanel)
  const toningId = useUI((state) => state.toningId)
  const id = useMemo(() => makeShortId(10), [])

  const selectColor = useEditorStore((state) => state.selectColor)

  const [color, setColor] = useState(
    `linear-gradient(90deg, #b91616 0.00%,#751212 40.00%,#ffffff 100.00%)`,
  )
  const { trigger, isMutating } = useSWRMutation(
    `/tonings/${toningId}/colors`,
    postFetcher,
    {
      onSuccess: () => {
        toast({ title: 'Color created' })
        mutate(['/tonings', { tid }])
        togglePanel(PICKER_COLOR)
      },
    },
  )

  const handleChange = useCallback(
    (color) => {
      selectColor(tid, toningId, 'default', { id, color })
      setColor(color)
    },
    [selectColor, id, tid, toningId],
  )

  const handleSave = () => {
    const _color = parseLinearGradient(color)
    trigger({ tid, colorId: id, colors: _color })
  }

  return (
    <div className="w-full h-full flex flex-col gap-2">
      <div className="flex items-center">
        <Button variant="ghost" size="sm">
          <ResetIcon
            className="w-4 h-4"
            onClick={() => togglePanel(PICKER_COLOR)}
          />
        </Button>
        <Title className="ml-1">Create new color</Title>
        <div className="flex items-center ml-auto gap-2">
          {isMutating ? (
            <>
              <UpdateIcon className="w-4 h-4 mr-2 animate-spin" />
              <span>Creating...</span>
            </>
          ) : (
            <Button variant="outline" size="sm" onClick={handleSave}>
              <PlusIcon className="mr-2 w-4 h-4" />
              <span>Create</span>
            </Button>
          )}
        </div>
      </div>
      <AdjustColor value={color} onChange={handleChange} />
    </div>
  )
}
