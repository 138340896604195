import { cn } from '@/lib/utils'
import { UpdateIcon } from '@radix-ui/react-icons'
import React from 'react'

const ImgWithState = React.forwardRef(
  ({ className, isMutating, ...props }, ref) => {
    return (
      <div className="relative" ref={ref}>
        <img
          alt="..."
          className={cn(
            'aspect-square rounded-md object-cover w-16 h-16',
            className,
          )}
          {...props}
        />
        {isMutating && (
          <div className="absolute top-0 left-0 w-16 h-16 flex items-center justify-center bg-gray-400 bg-opacity-50">
            <UpdateIcon className="w-3/5 h-3/5 text-primary animate-spin" />
          </div>
        )}
      </div>
    )
  },
)

ImgWithState.displayName = 'ImgWithState'

export default ImgWithState
