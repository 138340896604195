import React from 'react'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { delFetcher } from 'api/swr'
import {
  DotsHorizontalIcon,
  TrashIcon,
  UpdateIcon,
} from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu'

export const DataTableRowActions = ({ row }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DataTableRowDelete row={row} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const DataTableRowDelete = ({ row }) => {
  const data = row.original
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation('/taglibs', delFetcher, {
    onSuccess: () => {
      mutate(['/taglibs'])
      toast({
        title: 'Tag Deleted',
        description: 'The tag has been successfully deleted.',
      })
    },
  })

  const handleDelete = () => {
    trigger({ id: data.id })
  }

  //TODO: add confirmation dialog
  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete Tag',
      description: 'Are you sure you want to delete this tag?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem onSelect={confirm}>
      {isMutating ? (
        <>
          <UpdateIcon className="mr-2 h-4 w-4" />
          <span>Deleteing...</span>
        </>
      ) : (
        <>
          <TrashIcon className="mr-2 h-4 w-4" />
          <span>Delete</span>
        </>
      )}
    </DropdownMenuItem>
  )
}
