import { ContentLangs } from 'config/index'

export const useContetLang = (contentLang) => {
  return (
    ContentLangs.find((lang) => lang.code === contentLang) || {
      code: contentLang,
      name: 'unknow',
      short: 'unknow',
    }
  )
}
