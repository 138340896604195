import React from 'react'
import { Button } from '@/components/ui/button'
import { PlusIcon, ReloadIcon } from '@radix-ui/react-icons'
import useSWRMutation from 'swr/mutation'
import { postFetcher } from 'api/swr'
import Updating from '@/components/ui/Updating'
import { mutate } from 'swr'

const DataTableToolbar = ({ mutate }) => {
  return (
    <div className="flex items-center w-full">
      <Button variant="outline" onClick={() => mutate()}>
        <ReloadIcon className="h-4 w-4 mr-2" />
        Refetch
      </Button>
      <CreateButton />
    </div>
  )
}

const CreateButton = () => {
  const { trigger, isMutating } = useSWRMutation('/layer-groups', postFetcher, {
    onSuccess: () => {
      mutate(['/layer-groups'])
    },
  })
  const handleClick = () => {
    trigger({ name: 'New Layer Group', playIndex: 0 })
  }
  return (
    <Button className="ml-auto" variant="outline" onClick={handleClick}>
      {isMutating ? (
        <Updating />
      ) : (
        <>
          <PlusIcon className="h-4 w-4 mr-2" />
          <span>Create layer group</span>
        </>
      )}
    </Button>
  )
}

export default DataTableToolbar
