import React from 'react'
import { Link } from 'react-router-dom'
import { useUpdateDlcDrafts } from 'api/index'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { CommitIcon, DotsHorizontalIcon, CodeIcon } from '@radix-ui/react-icons'
import { CopyItem } from 'components/ui/DropdownMenu'
import DataTableRowEditorTags from 'components/template-tags'

export const DataTableRowActions = ({ row }) => {
  const data = row.original
  return (
    <div className="flex items-center gap-2">
      <Button asChild>
        <Link to={`/review/dlc/${data.id}`}>
          <CommitIcon className="w-4 h-4 mr-2" /> <span>Review</span>
        </Link>
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <CopyItem data={data.id} />
          <DropdownMenuSeparator />
          <DataTableRowUpdateSku row={row} />
          <DataTableRowEditorTags
            row={row}
            useUpdateDrafts={useUpdateDlcDrafts}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const DataTableRowUpdateSku = ({ row }) => {
  const initData = row.original?.ext?.sku
  const { handleUpdate, isMutating } = useUpdateDlcDrafts(row.id)

  const handleUpdateSku = (e, sku) => {
    e.preventDefault()
    handleUpdate('sku', sku, false)
  }
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <CodeIcon className="w-4 h-4 mr-2" /> Set sku
        {isMutating && 'ing...'}
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          {['DLC_FREE', 'DLC_SUBSCRIBER', `DLC_${row.id}`].map((sku) => (
            <DropdownMenuCheckboxItem
              key={sku}
              checked={sku === initData}
              onSelect={(e) => handleUpdateSku(e, sku)}
            >
              {sku}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  )
}
