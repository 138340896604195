import React, { useState, useRef } from 'react'

import { DropdownMenuItem } from '@/components/ui/dropdown-menu'

import useSWRMutation from 'swr/mutation'
import { mutate } from 'swr'
import { putFetcher } from 'api/swr'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog'
import Divider from 'components/ui/Divider'
import { useParams } from 'react-router-dom'
import Skeleton from '@/components/ui/skeleton'
import { ImageIcon, PlusIcon } from '@radix-ui/react-icons'
import { selectFileReader } from 'commons'
import { cn } from '@/lib/utils'
import { cptCss } from '../ui'
import { Button } from '@/components/ui/button'

const UpdateCoverItem = ({ cpt }) => {
  const { tid } = useParams()
  const [open, setOpen] = useState(false)

  const { trigger, isMutating } = useSWRMutation(
    `/components/${cpt.id}`,
    putFetcher,
  )

  const coverRef = useRef(null)
  const [cover, setCover] = useState(null)
  const [success, setSuccess] = useState(false)

  const coverError = cover && (cover.w !== 512 || cover.h !== 512)

  const handleCoverFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const cover = await selectFileReader(file)
      setCover(cover)
    }
  }

  const handleSelectCover = () => {
    setSuccess(false)
    if (coverRef.current) {
      coverRef.current.value = null
      coverRef.current.click()
    }
  }

  const onOpenChange = (v) => {
    setOpen(v)
    reset()
  }

  const reset = () => {
    setCover(null)
    if (coverRef.current) {
      coverRef.current.value = null
    }
  }

  const handleUpdate = (cover) => {
    if (isMutating) return
    trigger(
      { tid, key: 'cover', value: cover },
      {
        onSuccess: () => {
          setSuccess(true)
          mutate(['/components', { tid }])
          reset()
        },
      },
    )
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ImageIcon className="h-4 w-4 mr-2" /> Update Cover
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>Update cover</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <div className="flex flex-col">
          <div className="flex gap-4">
            <div>
              Original:
              <img
                alt="cpt original"
                src={`https://img.zthd.io/an1/ats/${cpt.id}`}
                className={cn(cptCss)}
              />
            </div>
            {cpt?.cover && (
              <div>
                Curr cover:
                <img
                  alt="cpt cover"
                  src={`https://img.zthd.io/an1/ats/${cpt.cover}`}
                  onClick={handleSelectCover}
                  className={cn(cptCss)}
                />
              </div>
            )}
          </div>
          <Divider className="mt-1" />
          <div className="grid gap-4 py-4">
            {cover ? (
              <img
                alt="cpt cover"
                src={cover.original}
                onClick={handleSelectCover}
                className={cn(cptCss, coverError && 'border-destructive')}
              />
            ) : (
              <Skeleton
                className={cn(
                  cptCss,
                  'flex flex-col items-center justify-center',
                )}
                onClick={handleSelectCover}
              >
                <PlusIcon className="w-4 h-4" />
                <span className="text-center">Select cover</span>
              </Skeleton>
            )}
          </div>
          <input
            type="file"
            ref={coverRef}
            className="hidden"
            onChange={handleCoverFileChange}
            accept="image/*"
          />
          <DialogFooter>
            <div className="flex gap-4">
              <Button variant="outline" onClick={reset}>
                Reset
              </Button>
              <Button
                variant="outline"
                onClick={() => handleUpdate('')}
                disabled={!cpt?.cover || isMutating}
              >
                Remove{isMutating && 'ing...'}
              </Button>
              <Button
                variant="outline"
                className="flex-grow"
                onClick={() => handleUpdate(cover.original)}
                disabled={!cover || isMutating || coverError}
              >
                Update{isMutating && 'ing...'}
              </Button>
            </div>
            {coverError && (
              <div className="text-destructive">size require: 512x512</div>
            )}
            {success && <div className="text-primary">Update success</div>}
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateCoverItem
