import React from 'react'
import Skeleton from '@/components/ui/skeleton'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'
import { DataTableRowActions } from './data-table-row-action'
import { Badge } from '@/components/ui/badge'

export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: '',
    accessorKey: 'thumb',
    cell: ({ row }) => (
      <>
        {row.original.thumb ? (
          <img
            alt="..."
            className="aspect-square rounded-md object-cover h-24 w-24"
            src={row.original.thumb}
          />
        ) : (
          <Skeleton className="h-24 w-24 rounded-xl" />
        )}
      </>
    ),
  },
  {
    accessorKey: 'id',
    header: 'id',
    cell: ({ row }) => (
      <>
        <div className="text-[grey]">{row.original.id}</div>
      </>
    ),
  },
  {
    accessorKey: 'tag',
    header: 'tag',
    cell: ({ row }) => (
      <>
        <Badge>
          <small>{row.original.tag}</small>
        </Badge>
      </>
    ),
  },
  {
    header: 'CreateTime',
    accessorKey: 'createTime',
    cell: ({ row }) => (
      <DisplayUpdateTime updateTime={row.original.createTime} />
    ),
  },
  {
    header: 'Actions',
    cell: ({ row }) => (
      <>
        <DataTableRowActions row={row} />
      </>
    ),
  },
]
