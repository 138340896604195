import React from 'react'
import { cn } from '@/lib/utils'
import { useAuthStore } from 'api/swr'
import { Link, NavLink } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { AvatarIcon } from '@radix-ui/react-icons'
import Navbar from 'components/Navbar'

const NavItem = ({ href, name, end }) => {
  return (
    <NavLink
      to={href}
      end={end}
      className={({ isActive }) =>
        cn(`transition-colors`, {
          'font-bold': isActive,
          'text-foreground/60': !isActive,
        })
      }
    >
      {name}
    </NavLink>
  )
}

const Nav = () => {
  const user = useAuthStore((state) => state.auth?.user)
  const face = useAuthStore((state) => state.auth?.face)
  const logout = useAuthStore((state) => state.removeToken)

  if (!user || !face) {
    return null
  }

  return (
    <Navbar name="Neku">
      <nav className="flex items-center gap-4 lg:gap-6">
        <NavItem href="/admin" name="Templates" end />
        <NavItem href="/admin/avatars" name="Avatars" />
        <NavItem href="/admin/webimgs" name="WebImages" />
        <NavItem href="/admin/publishers" name="Painters" />
        <NavItem href="/admin/users" name="Users" />
        <NavItem href="/admin/comm" name="Comm" />
        <NavItem href="/admin/valid" name="Valid" />
        <NavItem href="/admin/report" name="Report" />
      </nav>
      <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
        <Button variant="ghost" asChild>
          <Link to="/login" onClick={logout}>
            <AvatarIcon className="w-6 h-6 mr-2" />
            {user.username} Log Out
          </Link>
        </Button>
      </div>
    </Navbar>
  )
}

export default Nav
