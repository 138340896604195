import React from 'react'
import { buttonVariants } from '@/components/ui/button'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
} from '@/components/ui/pagination'
import { ChevronRightIcon } from '@radix-ui/react-icons'
import { cn } from '@/lib/utils'

const MyPagination = ({ className, afters, after, nextAfter, goAfter }) => {
  return (
    <Pagination className={cn('justify-normal', className)}>
      <PaginationContent>
        <PaginationItem onClick={() => goAfter(null)}>
          <PaginationLink isActive={after === null}>1</PaginationLink>
        </PaginationItem>
        {afters?.map((_after, index) => (
          <PaginationItem key={_after} onClick={() => goAfter(_after)}>
            <PaginationLink isActive={_after === after}>
              {index + 2}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationEllipsis />
        </PaginationItem>
        {nextAfter && (
          <PaginationItem onClick={() => goAfter(nextAfter)}>
            <PaginationLink size="default" className={cn('gap-1 pr-2.5')}>
              <span>Next</span>
              <ChevronRightIcon className="h-4 w-4" />
            </PaginationLink>
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  )
}

const PaginationLink = ({ className, isActive, size = 'icon', ...props }) => (
  <div
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size,
      }),
      className,
    )}
    {...props}
  />
)

export default MyPagination
