import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import {
  PaperPlaneIcon,
  QuestionMarkCircledIcon,
  UpdateIcon,
} from '@radix-ui/react-icons'
import { postFetcher } from 'api/swr'
import Navbar from 'components/Navbar'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'

const Nav = () => {
  const { tid } = useParams()

  const { trigger, isMutating } = useSWRMutation(
    `/publish/draft`,
    postFetcher,
    {
      onSuccess: () => {
        toast({
          title: 'Review submitted',
          description: 'Your review has been submitted successfully',
        })
      },
      onError: (error) => {
        console.log(error)
        toast({
          variant: 'destructive',
          title: 'Failed to submit review',
          description: error.message,
        })
      },
    },
  )
  const handleClick = () => {
    trigger({ tid })
  }
  return (
    <Navbar name="Neku workspace">
      <div className="remind me-1 d-none d-md-block">
        <QuestionMarkCircledIcon className="me-1 w-4 h-4" />
      </div>
      <Button disabled={isMutating} variant="outline" onClick={handleClick}>
        {isMutating ? (
          <>
            <UpdateIcon className="mr-2 h-4 w-4 animate-spin" />
            <span>Submitting review</span>
          </>
        ) : (
          <>
            <PaperPlaneIcon className="mr-2 h-4 w-4 -rotate-45" />
            <span>Submit review</span>
          </>
        )}
      </Button>
    </Navbar>
  )
}

export default Nav
