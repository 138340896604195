import React from 'react'
import {
  Cover,
  DataTableRowActions,
  PlayIndex,
  DataTableRowText,
} from './data-table-row-action'
import CopyedCode from 'ui/CopyedCode'

//TODO update cover to image
export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: '',
    accessorKey: 'cover',
    cell: ({ row }) => <Cover row={row} />,
  },
  {
    header: 'ID',
    accessorKey: 'id',
    cell: ({ row }) => (
      <CopyedCode copyed={row.original.id}>{row.original.id}</CopyedCode>
    ),
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: ({ row }) => <DataTableRowText row={row} entityKey={'name'} />,
  },
  {
    header: () => (
      <div className="flex items-center justify-center">Play index</div>
    ),
    accessorKey: 'playIndex',
    cell: ({ row }) => <PlayIndex row={row} />,
  },
  {
    id: 'actions',
    enableHide: false,
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
