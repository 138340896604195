import React from 'react'
import ColorPicker from './ColorPicker'
import ToningPicker from './ToningPicker'
import useSWR from 'swr'
import { fetcher } from 'api/swr'
import { useParams } from 'react-router-dom'
import Loader from '@/components/ui/Loader'
import Divider from 'components/ui/Divider'

const ToningPanel = () => {
  const { tid } = useParams()
  const { data: tonings, isLoading } = useSWR(['/tonings', { tid }], fetcher)

  if (isLoading) return <Loader>Load tonings...</Loader>

  return (
    <>
      <ToningPicker tonings={tonings} />
      <Divider />
      <ColorPicker tonings={tonings} />
    </>
  )
}

export default ToningPanel
