import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import TemplateList from 'AdminPanel/Templates/author'
import React from 'react'

const Detail = () => {
  return (
    <div className="flex-1 space-y-4 p-8 pt-6">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-3xl font-bold tracking-tight">Details</h2>
      </div>
      <Tabs defaultValue="templates" className="space-y-4">
        <TabsList>
          <TabsTrigger value="templates">Templates</TabsTrigger>
        </TabsList>
        <TabsContent value="templates" className="space-y-4">
          <TemplateList />
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default Detail
