import Page404 from '404'
import { useRole } from 'hooks/auth'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from './Nav'

const Index = () => {
  const isAdmin = useRole(`admin`)
  if (!isAdmin) return <Page404 />
  return (
    <div className="flex flex-col">
      <Nav />
      <Outlet />
    </div>
  )
}

export default Index
