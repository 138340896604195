export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: 'tag',
    accessorKey: 'tag',
  },
  {
    header: 'count',
    accessorKey: 'count',
  },
]
