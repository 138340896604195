import React from 'react'
import useSWR from 'swr'
import Skeleton from '@/components/ui/skeleton'
import { DataTableRowActions } from './data-table-row-action'

export const frameColumns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    accessorKey: 'avatars',
  },
  {
    accessorKey: 'proAvatars',
  },
  {
    accessorKey: 'dlcAvatars',
  },
  {
    accessorKey: 'payout',
  },
  {
    accessorKey: 'cpts',
  },
  {
    accessorKey: 'layers',
  },
  {
    header: 'pro rate',
    cell: ({ row }) => (
      <span>
        {Math.round((row.original.proAvatars / row.original.avatars) * 10000) /
          100}
        %
      </span>
    ),
  },
  {
    header: 'dlc rate',
    cell: ({ row }) => (
      <span>
        {Math.round((row.original.dlcAvatars / row.original.avatars) * 10000) /
          100}
        %
      </span>
    ),
  },
  {
    header: 'avg pay',
    cell: ({ row }) => (
      <span>{Math.round(row.original.payout / row.original.avatars)}</span>
    ),
  },
  {
    header: 'avg cpts',
    cell: ({ row }) => (
      <span>{Math.round(row.original.cpts / row.original.avatars)}</span>
    ),
  },
  {
    header: 'avg layers',
    cell: ({ row }) => (
      <span>{Math.round(row.original.layers / row.original.avatars)}</span>
    ),
  },
  {
    header: 'avg avatars',
    cell: ({ row }) => (
      <span>
        <span>{Math.round(row.original.payout / row.original.proAvatars)}</span>
      </span>
    ),
  },
]

export const layerColumns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: 'cover',
    accessorKey: 'lid',
    cell: ({ row }) => (
      <LayerCover id={row.original.lid} tid={row.original.tid} />
    ),
  },
  {
    accessorKey: 'lid',
  },
  {
    header: 'name',
    accessorKey: 'lid',
    cell: ({ row }) => (
      <LayerName id={row.original.lid} tid={row.original.tid} />
    ),
  },
  {
    header: 'count',
    accessorKey: 'count',
  },
  {
    header: 'Actions',
    cell: ({ row }) => (
      <>
        <DataTableRowActions row={row} />
      </>
    ),
  },
]

export const tagColumns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  {
    header: 'tag',
    accessorKey: 'tag',
  },
  {
    header: 'count',
    accessorKey: 'count',
  },
]

const LayerName = ({ id, tid }) => {
  const { data } = useSWR([`/layers/${id}`, { tid }])
  return <>{data?.name}</>
}

const LayerCover = ({ id, tid }) => {
  const { data } = useSWR([`/layers/${id}`, { tid }])
  return (
    <>
      {data ? (
        <img
          alt="..."
          className="aspect-square rounded-md object-cover w-24 h-24"
          src={`https://img.zthd.io/an1/acs/${data.cover}`}
        />
      ) : (
        <Skeleton className="h-24 w-24 rounded-xl" />
      )}
    </>
  )
}
