import Page404 from '404'
import Loader from '@/components/ui/Loader'
import { fetcher } from 'api/swr'
import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { initialState, useEditorStore } from '../CanvasCore/uiSlice'
import { LayerPanel } from './BottomPanel/LayerPanel'
import ToningPanel from './BottomPanel/ToningPanel'
import CanvasPanel from './CanvasPanel'
import Nav from './Nav'
import RightPanel from './RightPanel'

export const useUI = create(
  devtools((set) => ({
    panel: 'pickerColor',
    lid: 'draft',
    toningId: null,
    currTab: 'info',
    dlc: null,
    togglePanel: (panel) => set({ panel }),
    toggleLayer: (lid) => set({ lid: lid ?? 'draft' }),
    toggleToning: (toningId) => set({ toningId, panel: 'pickerColor' }),
    toggleTab: (currTab) => set({ currTab }),
    toggleDlc: (dlc) => set({ dlc }),
  })),
)

export const useSelectedColor = (tid, lid) => {
  const tonings = useEditorStore((state) => state.editors[tid]?.tonings)
  const toningId = useUI((state) => state.toningId)

  const selectedColor = useMemo(() => {
    return tonings?.[toningId]?.[lid ?? 'default']
  }, [tonings, toningId, lid])

  return selectedColor
}

const Editor = () => {
  const { tid } = useParams()
  const initEditor = useEditorStore((state) => state.initEditor)
  const tab = useUI((state) => state.currTab)

  useEffect(() => {
    if (tid) {
      initEditor(tid, initialState, false)
    }
  }, [tid, initEditor])

  const { data: template, isLoading } = useSWR([`/templates/${tid}`], fetcher)
  if (isLoading) return <Loader>Load template...</Loader>
  if (!template) return <Page404 />

  return (
    <div className="flex flex-col h-screen">
      <Nav />
      <main className="flex mt-1 gap-4 flex-grow  pb-2">
        <div className="w-1/2 flex flex-col gap-1 max-h-calc">
          <div className="flex-none h-1/2">
            <CanvasPanel template={template} />
          </div>
          <div className="flex-none h-1/2 flex flex-col gap-1 p-1 border rounded-md">
            {tab === 'tonings' ? <ToningPanel /> : <LayerPanel />}
          </div>
        </div>
        <div className="w-1/2 editor-right max-h-calc overflow-y-auto">
          <RightPanel />
        </div>
      </main>
    </div>
  )
}

export default Editor
