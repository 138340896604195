export const parseLinearGradient = (gradientString) => {
  const regex = /(#\w{6}|#\w{3}|rgba?\([^)]*\))\s*([\d.]+)%/g
  const stops = []
  let match
  while ((match = regex.exec(gradientString)) !== null) {
    stops.push({ color: match[1], offset: parseFloat(match[2]) / 100 })
  }
  return stops
}

export const gradient2Toning = (gradient) => {
  const regex = /#([0-9a-f]{6}|[0-9a-f]{3})\s+(\d+\.?\d*)%/gi
  let match
  const colors = []

  while ((match = regex.exec(gradient)) !== null) {
    const color = match[1]
    const offset = parseFloat(match[2]) / 100
    colors.push({ c: `#${color}`, o: offset })
  }

  return colors
}

export const color2Linear = (colors) => {
  const _colors = [...colors]
    .sort((a, b) => a.offset - b.offset)
    .map((t) => `${t.color} ${t.offset * 100}%`)
    .join(', ')
  return `linear-gradient(to right, ${_colors})`
}

const getGradient = (t) => {
  const levels = 256
  const canvas = document.createElement('canvas')
  canvas.width = 1
  canvas.height = levels
  const ctx = canvas.getContext('2d')

  const gradientStops = parseLinearGradient(t)
  const gradient = ctx.createLinearGradient(0, 0, 0, levels)

  gradientStops.forEach((stop) => {
    gradient.addColorStop(stop.offset, stop.color)
  })

  // const gradient = ctx.createLinearGradient(0, 0, 0, levels);
  // const len = t.length;
  // for (let i = 0; i < len; ++i) {
  //   gradient.addColorStop(t[i].o, t[i].c);
  // }
  ctx.fillStyle = gradient
  ctx.fillRect(0, 0, 1, levels)
  const _gradientPixels = ctx.getImageData(0, 0, 1, levels).data
  return _gradientPixels
}

const getGrayscaledImageData = (imagedata) => {
  // NOTE: imageDataのピクセルは[R_1,G_1,B_1,A_1,R_2,...]という形の一次元配列
  // なので、for文のindex変数は毎回+4(Alphaを無視)している
  for (let i = 0; i < imagedata.data.length; i += 4) {
    let r = imagedata.data[i]
    let g = imagedata.data[i + 1]
    let b = imagedata.data[i + 2]
    // let grayscale = r * 0.299 + g * 0.587 + b * 0.114;
    // let grayscale = r * 0.2126 + g * 0.7152 + b * 0.0722;
    let grayscale = r * 0.34 + g * 0.5 + b * 0.16
    imagedata.data[i] = grayscale
    imagedata.data[i + 1] = grayscale
    imagedata.data[i + 2] = grayscale
  }

  return imagedata
}

export const filterIt = (t, imageData) => {
  const gradient = getGradient(t)
  if (!gradient) return
  const grayscaled_imagedata = getGrayscaledImageData(imageData)
  getGradientMappedImageData(gradient, grayscaled_imagedata)
}

/**
 * Composite imageData(pixeldata) with gradient
 * @param {imageData} imagedata pixel datas to composite with gradient
 * @returns imageData
 */
const getGradientMappedImageData = (gradient, imagedata) => {
  for (let i = 0; i < imagedata.data.length; i += 4) {
    let v =
      (Math.max(
        imagedata.data[i],
        imagedata.data[i + 1],
        imagedata.data[i + 2],
      ) +
        Math.min(
          imagedata.data[i],
          imagedata.data[i + 1],
          imagedata.data[i + 2],
        )) /
      2
    // let new_r = Math.floor(gradient[v * 4] * 1.025);
    // let new_g = Math.floor(gradient[v * 4 + 1] * 1.035);
    // let new_b = Math.floor(gradient[v * 4 + 2] * 1.035);

    let new_r = Math.floor(gradient[v * 4] * 1)
    let new_g = Math.floor(gradient[v * 4 + 1] * 1)
    let new_b = Math.floor(gradient[v * 4 + 2] * 1)

    imagedata.data[i] = new_r
    imagedata.data[i + 1] = new_g
    imagedata.data[i + 2] = new_b
  }

  return imagedata
}
