import React, { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { useUI } from 'Editor'
import DataTableToolbar from './data-table-toolbar'

import MyDataTable from 'components/ui/my-data-table'
import { columns } from './data-table-columns'

//TODO: publish

const DlcPanel = () => {
  const { tid } = useParams()

  const { data: allDlcs } = useSWR(['/dlcs', { tid }])
  const { data: cpts } = useSWR(['/components', { tid }])
  const dlcId = useUI((state) => state.dlc)
  const toggleDlc = useUI((state) => state.toggleDlc)

  const [rowSelection, setRowSelection] = useState({ [dlcId]: true })

  const dlcs = useMemo(() => {
    return (
      allDlcs?.map((dlc) => ({
        ...dlc,
        cpts: cpts?.filter((cpt) => cpt.dlc === dlc.id),
      })) ?? []
    )
  }, [allDlcs, cpts])

  useEffect(() => {
    if (dlcId) {
      setRowSelection({ [dlcId]: true })
    }
  }, [dlcId])

  useEffect(() => {
    const firstKey = Object.keys(rowSelection)[0]
    if (firstKey) {
      toggleDlc(firstKey)
    }
  }, [rowSelection, toggleDlc])

  const table = useReactTable({
    data: dlcs,
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="w-full space-y-4">
      <DataTableToolbar table={table} dlcs={dlcs} />
      <div className="rounded-md border">
        <MyDataTable table={table} />
      </div>
    </div>
  )
}

export default DlcPanel
