import React from 'react'
import useSWR from 'swr'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import { useAuthStore } from 'api/swr'
import { columns } from './data-table-column'
import DataTableToolbar from './data-table-toolbar'

const Templates = () => {
  const userId = useAuthStore((state) => state.auth?.user?.id)
  const {
    data = [],
    mutate,
    isValidating,
    isLoading,
  } = useSWR(['/templates', { author: userId }])

  const table = useReactTable({
    data,
    columns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })
  if (isLoading || isValidating) return <Loader>Load templates</Loader>
  if (!data) return <EmptyDatas />

  return (
    <>
      <DataTableToolbar mutate={mutate} />
      <Card>
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

export default Templates
