import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  DotsHorizontalIcon,
  TrashIcon,
  UpdateIcon,
  Link2Icon,
} from '@radix-ui/react-icons'
import useSWRMutation from 'swr/mutation'
import { delFetcher } from 'api/swr'
import { mutate } from 'swr'
import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu'

export const DataTableRowActions = ({ row, path }) => {
  const navigate = useNavigate()
  return (
    <div className="flex gap-3 items-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onSelect={() => navigate(`/editor/${row.original}`)}
          >
            <Link2Icon className="w-4 h-4 mr-2" />
            editor
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DataTableRowDelete row={row} path={path} />
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const DataTableRowDelete = ({ row, path }) => {
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation(path, delFetcher, {
    onSuccess: () => {
      mutate([path])
      toast({
        title: 'Ab Test Deleted',
        description: 'The ab test has been successfully deleted.',
      })
    },
  })

  const handleDelete = () => {
    trigger({ id: row.original })
  }

  //TODO: add confirmation dialog
  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete Ab Test',
      description: 'Are you sure you want to delete this ab test?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem onSelect={confirm}>
      {isMutating ? (
        <>
          <UpdateIcon className="mr-2 h-4 w-4" />
          <span>Deleteing...</span>
        </>
      ) : (
        <>
          <TrashIcon className="mr-2 h-4 w-4" />
          <span>Delete</span>
        </>
      )}
    </DropdownMenuItem>
  )
}
