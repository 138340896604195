import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import React from 'react'
import LayerGroups from './LayerGroups'
import LayerIcons from './LayerIcons'
import CommTaglibs from './Taglibs'
import CommTonings from './Tonings'
import MemeStickers from './MemeStickers'
import TagClicks from './TagClicks'
import AbTests from './AbTests'

const Index = () => {
  return (
    <>
      <div className="flex-1 space-y-4 p-8 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <h2 className="text-3xl font-bold tracking-tight">Comm data</h2>
        </div>
        <Tabs defaultValue="layer-icons" className="space-y-4">
          <TabsList>
            <TabsTrigger value="layer-icons">Layer icons</TabsTrigger>
            <TabsTrigger value="tonings">Tonings</TabsTrigger>
            <TabsTrigger value="layer-groups">Layer groups</TabsTrigger>
            <TabsTrigger value="tag-libs">Taglibs</TabsTrigger>
            <TabsTrigger value="meme-stickers">Meme stickers</TabsTrigger>
            <TabsTrigger value="tag-clicks">Tag clicks</TabsTrigger>
            <TabsTrigger value="ab-tests">Ab tests</TabsTrigger>
          </TabsList>
          <TabsContent value="layer-icons" className="space-y-4">
            <LayerIcons />
          </TabsContent>
          <TabsContent value="tonings" className="space-y-4">
            <CommTonings />
          </TabsContent>
          <TabsContent value="layer-groups" className="space-y-4">
            <LayerGroups />
          </TabsContent>
          <TabsContent value="tag-libs" className="space-y-4">
            <CommTaglibs />
          </TabsContent>
          <TabsContent value="meme-stickers" className="space-y-4">
            <MemeStickers />
          </TabsContent>
          <TabsContent value="tag-clicks" className="space-y-4">
            <TagClicks />
          </TabsContent>
          <TabsContent value="ab-tests" className="space-y-4">
            <AbTests />
          </TabsContent>
        </Tabs>
      </div>
    </>
  )
}

export default Index
