import { Button } from '@/components/ui/button'
import { LayersIcon, PlusIcon, UpdateIcon } from '@radix-ui/react-icons'
import { postFetcher } from 'api/swr'
import React from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'

const DataTableToolbar = ({ layers }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Button variant="ghost" className="h-8 px-2 lg:px-3">
          <LayersIcon className="mr-2 h-4 w-4" /> {layers?.length}
        </Button>
      </div>
      <CreateButton />
    </div>
  )
}

const CreateButton = () => {
  const { tid } = useParams()
  const { trigger, isMutating } = useSWRMutation('/layers', postFetcher)

  const handleClick = () => {
    trigger(
      { tid, name: 'new layer', cover: 'UWj' },
      { onSuccess: () => mutate(['/layers', { tid }]) },
    )
  }

  return (
    <Button
      variant="outline"
      className="ml-auto h-8 px-2 lg:px-3"
      disabled={isMutating}
      onClick={handleClick}
    >
      {isMutating ? (
        <>
          <UpdateIcon className="mr-2 h-4 w-4 animate-spin" /> Creating...
        </>
      ) : (
        <>
          <PlusIcon className="mr-2 h-4 w-4" /> Add layer
        </>
      )}
    </Button>
  )
}

export default DataTableToolbar
