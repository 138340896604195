import React from 'react'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { ReloadIcon } from '@radix-ui/react-icons'

const MyRefetchButton = React.forwardRef(
  ({ className, children, ...props }, ref) => (
    <Button ref={ref} {...props} variant="outline" className={cn(className)}>
      <ReloadIcon className="mr-2" />
      Refetch
      {children}
    </Button>
  ),
)

MyRefetchButton.displayName = 'MyRefetchButton'

export default MyRefetchButton
