import Loader from '@/components/ui/Loader'
import DisplayAvatars from 'AdminPanel/Avatars/DisplayAvatars'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

const Remixed = () => {
  const { aid } = useParams()
  const { data, isLoading } = useSWR(['/avatars', { aid, state: 401 }])
  return (
    <div>
      <div>Remixed:{aid}</div>
      {isLoading && <Loader />}
      <DisplayAvatars avatars={data} />
    </div>
  )
}

export default Remixed
