import React, { useMemo } from 'react'
import Cpt from './Cpt'
import { CptCard } from './ui'

import BatchUpload from '../BatchUpload'

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import Skeleton from '@/components/ui/skeleton'
import { useEditorStore } from 'CanvasCore/uiSlice'

import { cn } from '@/lib/utils'
import { CircleBackslashIcon } from '@radix-ui/react-icons'
import { useUI } from 'Editor'
import { useParams } from 'react-router-dom'
import { CptGrid } from './ui'

export const DefalutCpts = ({ cpts }) => {
  const { tid } = useParams()

  const data = useMemo(
    () => cpts?.filter((cpt) => cpt.dlc === tid),
    [cpts, tid],
  )
  return (
    <AccordionItem value={tid}>
      <AccordionContent className="pt-1 pb-1">
        <CptGrid>
          <BatchUpload dlc={tid} />
          <CleanButton />
          {data?.map((cpt) => (
            <Cpt key={cpt.id} cpt={cpt} cpts={data} />
          ))}
        </CptGrid>
      </AccordionContent>
    </AccordionItem>
  )
}

const CleanButton = () => {
  const { tid } = useParams()
  const lid = useUI((state) => state.lid)
  const deselectAll = useEditorStore((state) => state.deselectCptByLayer)

  return (
    <CptCard onClick={() => deselectAll(tid, lid)} className="aspect-square">
      <CircleBackslashIcon className="w-3/5 h-3/5 text-gray-400 group-hover:text-primary" />
    </CptCard>
  )
}

export const CptsWithoutDlc = ({ cpts }) => {
  const data = useMemo(() => cpts?.filter((cpt) => !cpt.dlc), [cpts])
  if (data?.length === 0) {
    return null
  }
  return (
    <AccordionItem value={'withoutdlc'}>
      <AccordionTrigger>
        <div className="text-destructive">
          Miss DLC, please add DLC - ({data?.length})
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <CptGrid>
          {data?.map((cpt) => (
            <Cpt key={cpt.id} cpt={cpt} />
          ))}
        </CptGrid>
      </AccordionContent>
    </AccordionItem>
  )
}

export const CptsInDlc = ({ dlc, cpts, isLast }) => {
  const _cpts = useMemo(
    () => cpts?.filter((cpt) => cpt.dlc === dlc.id),
    [cpts, dlc.id],
  )
  return (
    <AccordionItem
      value={dlc.id}
      className={cn(isLast && 'border-b-transparent')}
    >
      <AccordionTrigger>
        <div className="flex items-center gap-2 ml-2">
          {dlc.cover ? (
            <img className="w-12 h-12" src={dlc.cover} alt="dlc icon" />
          ) : (
            <Skeleton className="w-12 h-12">DLC ICON</Skeleton>
          )}
          <span className="text-bold text-lg">{dlc.name}</span>
          <span className="text-xs">cpts:{_cpts?.length}</span>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <CptGrid>
          {_cpts?.map((cpt) => (
            <Cpt key={cpt.id} cpt={cpt} cpts={cpts} />
          ))}
        </CptGrid>
      </AccordionContent>
    </AccordionItem>
  )
}
