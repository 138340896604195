import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import { create } from 'zustand'
import { putFetcher } from 'api/swr'
import useSWRMutation from 'swr/mutation'
import Loader from '@/components/ui/Loader'
import { Button } from '@/components/ui/button'
import MyPagination from 'components/ui/MyPagination'
import { Card } from '@/components/ui/card'

export const useForm = create((set) => ({
  lang: -1,
  order: 'dailyCount.reCreateCount',
  simple: 1,
  state: '401',
  after: null,
  afters: [],
  goAfter: (nextAfter) =>
    set((state) => {
      if (nextAfter === null) {
        return { after: null }
      }
      const index = state.afters.findIndex((a) => a === nextAfter)
      if (index === -1) {
        return { after: nextAfter, afters: [...state.afters, nextAfter] }
      } else {
        return { after: nextAfter }
      }
    }),
}))

const Index = () => {
  const params = useForm((s) => {
    const { lang, simple, order, state, after } = s
    return { lang, simple, order, state, after }
  })
  const { afters, after, goAfter } = useForm((s) => s)

  const {
    data: avatars,
    isLoading,
    isValidating,
  } = useSWR(['/avatars', params])
  const nextAfter = avatars?.[avatars.length - 1]?.id

  return (
    <div className="my-4">
      {(isLoading || isValidating) && <Loader />}
      <MyPagination
        className={'my-2'}
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
      <div className="grid grid-cols-9 lg:grid-cols-6 xl:grid-cols-9 gap-2">
        {avatars?.map((avatar) => (
          <AvatarCard avatar={avatar} key={avatar.id} />
        ))}
      </div>
      <MyPagination
        className={'my-2'}
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
    </div>
  )
}

const AvatarCard = ({ avatar }) => {
  const [show, setShow] = useState(true)
  const { trigger, isMutating } = useSWRMutation(
    '/avatars/' + avatar.id,
    putFetcher,
    {
      onSuccess: () => {
        setShow(false)
      },
    },
  )

  useEffect(() => {
    setShow(true)
  }, [avatar.id])

  const updateState = (e) => {
    if (isMutating) return
    const value = avatar.state === 401 ? 0 : 401
    trigger({ key: 'state', value })
  }

  if (!show) return

  return (
    <>
      <Card>
        <img
          className="rounded-md"
          src={avatar?.thumb || avatar?.url}
          alt={avatar.shortId}
        />
        <div className="m-2 flex flex-col">
          <span className="text-center">{avatar.shortId}</span>
          <Button className="mt-2" variant="destructive" onClick={updateState}>
            offline
          </Button>
        </div>
      </Card>
    </>
  )
}

export default Index
