import { Button } from '@/components/ui/button'
import { useToast } from '@/components/ui/use-toast'
import { MagicWandIcon, PlusIcon, UpdateIcon } from '@radix-ui/react-icons'
import { postFetcher } from 'api/swr'
import React from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'

const DataTableToolbar = ({ tonings }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Button variant="ghost" className="h-8 px-2 lg:px-3">
          <MagicWandIcon className="mr-2 h-4 w-4" /> {tonings?.length}
        </Button>
      </div>
      <CreateButton />
    </div>
  )
}

const CreateButton = () => {
  const { tid } = useParams()
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation('/tonings', postFetcher, {
    onSuccess: () => {
      mutate(['/tonings', { tid }])
      toast({
        title: 'Toning Created',
        description: 'The toning has been successfully created.',
      })
    },
  })

  const handleClick = () => {
    trigger({ tid })
  }
  return (
    <Button
      variant="outline"
      className="ml-auto h-8 px-2 lg:px-3"
      disabled={isMutating}
      onClick={handleClick}
    >
      {isMutating ? (
        <>
          <UpdateIcon className="mr-2 h-4 w-4 animate-spin" /> Creating...
        </>
      ) : (
        <>
          <PlusIcon className="mr-2 h-4 w-4" /> Create toning
        </>
      )}
    </Button>
  )
}

export default DataTableToolbar
