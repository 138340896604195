// import { useGetUserQuery } from "api/shield/users";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import MyLink from 'components/ui/MyLink'
import React from 'react'
import InviteCodes from './InviteCodes'
import Templates from './Templates'
import Report from '../AdminPanel/Frames/Report'

const PersonalPanel = () => {
  return (
    <>
      <Card className="sm:col-span-2" x-chunk="dashboard-05-chunk-0">
        <CardHeader className="pb-3">
          <CardTitle>User profile</CardTitle>
          <CardDescription className="max-w-lg text-balance leading-relaxed">
            Introducing Our Dynamic Orders Dashboard for Seamless Management and
            Insightful Analysis.
          </CardDescription>
          <div className="flex items-center gap-4">
            <MyLink target="_blank" to={`/docs/get-started`}>
              《编辑器教程》
            </MyLink>
            <MyLink to={`/docs/onsale-rules`}>《作品上架规则》</MyLink>
            <MyLink to={`/docs/copyright-statement`}>《作品版权说明》</MyLink>
          </div>
        </CardHeader>
      </Card>
      <Tabs defaultValue="templates" className="h-full space-y-6 mt-4">
        <div className="space-between flex items-center">
          <TabsList>
            <TabsTrigger value="templates" className="relative">
              Templates
            </TabsTrigger>
            <TabsTrigger value="report" className="relative">
              Report
            </TabsTrigger>
            <TabsTrigger value="inviteCodes">Invite code</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="templates" className="border-none p-0 outline-none">
          <Templates />
        </TabsContent>
        <TabsContent value="report" className="border-none p-0 outline-none">
          <Report />
        </TabsContent>
        <TabsContent
          value="inviteCodes"
          className="border-none p-0 outline-none"
        >
          <InviteCodes />
        </TabsContent>
      </Tabs>
    </>
  )
}

export default PersonalPanel
