import React from 'react'
import DropdownLang from 'components/DropdownLang'
import MyRefetchButton from 'components/ui/MyRefetchButton'
import { DatePicker } from 'components/ui/date-picker'
import { useForm } from './index'

const DataTableToolbar = ({ mutate }) => {
  const { lang, setLang, date, setDate } = useForm()
  return (
    <>
      <div className="flex gap-4 mb-2">
        <DropdownLang value={lang} update={setLang} />
        <DatePicker date={date} onSelect={setDate} />
        <MyRefetchButton onClick={() => mutate()} />
      </div>
    </>
  )
}

export default DataTableToolbar
