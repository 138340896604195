import React from 'react'
import { Button } from '@/components/ui/button'
import {
  ArrowUpIcon,
  MagicWandIcon,
  OpacityIcon,
  PersonIcon,
} from '@radix-ui/react-icons'
import { DataTableColumnHeader } from 'components/data-table-column-header'

import { cn } from '@/lib/utils'
import {
  DataTableRowActions,
  DataTableRowCover,
  DataTableRowOpt,
  DataTableRowText,
  ComponentCount,
  CopyId,
} from './data-table-row-actions'
import EditLayer from './layer-editor'

export const columns = [
  {
    accessorKey: 'cover',
    header: '',
    cell: ({ row }) => <DataTableRowCover row={row} />,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: <div></div>,
    cell: ({ row }) => (
      <>
        <DataTableRowText row={row} entityKey="name" type="text" />
        <ComponentCount row={row} />
        <CopyId row={row} />
      </>
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'opt',
    header: <div>Required</div>,
    cell: ({ row }) => <DataTableRowOpt row={row} />,
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'mark',
    header: <div className="flex items-center justify-center">Mark</div>,
    cell: ({ row }) => {
      const layer = row.original
      return (
        row.original.id !== 'draft' && (
          <div className="flex justify-center items-center">
            {layer.watermark === 1 ? (
              <OpacityIcon className={cn('w-5 h-5 text-primary')} />
            ) : (
              <div></div>
            )}
            {layer.bodymark === 1 ? (
              <PersonIcon className={cn('w-5 h-5 text-primary')} />
            ) : (
              <div></div>
            )}
            {layer.toning ? (
              <MagicWandIcon className={cn('w-5 h-5 text-primary')} />
            ) : (
              <div></div>
            )}
          </div>
        )
      )
    },
  },
  {
    accessorKey: 'z',
    header: ({ column }) => (
      <DataTableColumnHeader
        className="space-x-0"
        column={column}
        title="Z index"
      />
    ),
    cell: ({ row }) => {
      return <DataTableRowText row={row} entityKey="z" type="number" />
    },
  },
  {
    accessorKey: 'playIndex',
    header: ({ column }) => (
      <div className="flex items-center justify-center">
        <Button
          variant="ghost"
          size="sm"
          className="h-8 data-[state=open]:bg-accent px-1"
          onClick={() => column.toggleSorting(false)}
        >
          <span>Play index</span>
          <ArrowUpIcon className="ml-1 h-4 w-4" />
        </Button>
      </div>
    ),
    cell: ({ row }) => {
      return <DataTableRowText row={row} entityKey="playIndex" type="number" />
    },
  },
  {
    id: 'edit',
    enableHiding: false,
    cell: ({ row }) => <EditLayer layer={row.original} />,
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
