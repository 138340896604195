import React from 'react'
import {
  columnCover,
  columnInfo,
  columnName,
  columnPreview,
  columnPublisher,
  columnState,
} from 'components/template-data-table-column'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'
import { DataTableRowActions } from './data-table-row-action'

export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  columnCover,
  columnPreview,
  columnName,
  columnInfo,
  columnState,
  columnPublisher,
  {
    header: 'Publish Time',
    accessorKey: 'publishTime',
    cell: ({ row }) => (
      <DisplayUpdateTime updateTime={row.original.publishTime} />
    ),
  },
  {
    header: 'Actions',
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
