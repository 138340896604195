import React from 'react'
import Dropdown from 'components/ui/Selector'
import { useForm } from '.'
const OrderBys = [
  { code: 'updateTime', name: 'Update Time' },
  { code: 'dailyCount.downloadCount', name: 'daily top -download' },
  { code: 'weeklyCount.downloadCount', name: 'weekly top -download' },
  { code: 'monthlyCount.downloadCount', name: 'monthly top -download' },
]

const CustomDropdown = () => {
  const value = useForm((state) => state.order)
  const update = useForm((state) => state.setOrder)
  return (
    <Dropdown
      datas={OrderBys}
      showLabel
      labelName="Order"
      value={value}
      update={update}
    />
  )
}

export default CustomDropdown
