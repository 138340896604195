import { Button } from '@/components/ui/button'
import {
  DownloadIcon,
  HeartFilledIcon,
  StarFilledIcon,
} from '@radix-ui/react-icons'
import { delFetcher } from 'api/swr'
import MyLink from 'components/ui/MyLink'
import React from 'react'
import useSWRMutation from 'swr/mutation'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'

const Item = ({ data }) => {
  const { trigger, isMutating } = useSWRMutation(
    '/avatars/webimgs/',
    delFetcher,
  )

  if (!data) {
    return null
  }

  const onDelete = () => {
    //TODO: add a confirm dialog
    if (isMutating) return
    trigger(data.id)
  }

  return (
    <div className="flex flex-col gap-1 border p-1">
      <div className="text-sm">
        <p className="text-xs text-muted-foreground">
          <DisplayUpdateTime updateTime={data?.updateTime} />
        </p>
      </div>
      <div>
        <a href={data?.url}>
          <img
            src={data.url}
            alt="..."
            className="h-auto w-auto object-cover transition-all hover:scale-105 aspect-square"
          />
        </a>
      </div>
      <div className="flex flex-col space-y-1">
        <div className="flex items-center gap-2">
          <HeartFilledIcon className="text-destructive" />
          {data.likeCount ?? 0}
          <StarFilledIcon className="text-destructive" />
          {data.starCount ?? 0}
        </div>
        <div className="flex items-center gap-2">
          <DownloadIcon className="text-destructive" />
          {data?.dailyCount?.downloadCount ?? 0}/{data?.dCount}
        </div>
      </div>
      <FirebaseLink id={data.id} name={data.authorName} />
      <Button
        variant="outline"
        onClick={onDelete}
        className="hover:bg-destructive hover:text-white"
      >
        Delete
      </Button>
    </div>
  )
}

const FirebaseLink = ({ id, name }) => {
  const link = `https://console.firebase.google.com/project/avatar-maker-23583/firestore/data/~2FwebAvatars~2F${id}`
  return (
    <MyLink href={link} target="_blank">
      @{name}
    </MyLink>
  )
}

// const HeaderText = ({ data }) => {
//     const { clientVer, contentLang } = data;
//     const { code, short } = useContetLang(contentLang);
//     return (
//         <>
//             {code} - {short} - {clientVer}
//         </>
//     );
// };

export default Item
