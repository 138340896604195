import React from 'react'
import MyRefetchButton from 'components/ui/MyRefetchButton'
import TemplateCreate from 'components/template-create'

const DataTableToolbar = ({ mutate }) => {
  return (
    <div className="flex items-center justify-between mb-2">
      <MyRefetchButton onClick={() => mutate()} className="ml-2" />
      <TemplateCreate mutate={mutate} />
    </div>
  )
}

export default DataTableToolbar
