import React, { useState } from 'react'
import useSWR from 'swr'
import { Pencil1Icon } from '@radix-ui/react-icons'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  DropdownMenuSeparator,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { Label } from '@/components/ui/label'
import Loader from '@/components/ui/Loader'
import { Badge } from '@/components/ui/badge'
import { cn } from '@/lib/utils'

const DataTableRowEditorTags = ({ row, useUpdateDrafts }) => {
  const data = row.original
  const curTags = data?.ext?.tags || []
  const [open, setOpen] = useState(false)
  const { data: tags = [] } = useSWR(['/tagLibs'])
  const { handleUpdate, isMutating } = useUpdateDrafts(data.id)

  const handleAddTags = (value) => {
    handleUpdate('addTag', value, false)
  }
  const handleRemoveTags = (value) => {
    handleUpdate('removeTag', value, false)
  }

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger className="w-full">
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <Pencil1Icon className="mr-2 h-4 w-4" /> Edit Tags
          </DropdownMenuItem>
        </DialogTrigger>
        <DialogContent className="max-h-screen overflow-y-auto">
          <DialogHeader className={'flex'}>
            <DialogTitle>Edit Tags</DialogTitle>
          </DialogHeader>
          <div className="d-flex flex-wrap">
            {curTags.map((tag) => (
              <Badge
                key={tag}
                className={'me-1'}
                onClick={() => handleRemoveTags(tag)}
              >
                <small>{tag}</small>
              </Badge>
            ))}
          </div>
          <DropdownMenuSeparator />
          <div className="d-flex flex-wrap">
            {tags
              .filter((item) => item.level === 2)
              .filter((item) => !curTags.includes(item.name))
              .map((item) => (
                <Badge
                  key={item.name}
                  className={'me-1'}
                  variant="outline"
                  onClick={() => {
                    handleAddTags(item.name)
                  }}
                >
                  <small>
                    {item.name}
                    {item.note && '-'}
                    {item.note}
                  </small>
                </Badge>
              ))}
          </div>
          <DropdownMenuSeparator />
          <div className="d-flex flex-wrap">
            {tags
              .filter((item) => item.level === 3)
              .filter((item) => !curTags.includes(item.name))
              .map((item) => (
                <Badge
                  key={item.name}
                  className={'me-1'}
                  variant="outline"
                  onClick={() => {
                    handleAddTags(item.name)
                  }}
                >
                  <small>
                    {item.name}
                    {item.note && '-'}
                    {item.note}
                  </small>
                </Badge>
              ))}
          </div>
          <div
            className={cn(
              'grid grid-cols-4 items-center gap-4',
              isMutating ? '' : 'invisible',
            )}
          >
            <Label></Label>
            <div className="col-span-3 flex items-center gap-2">
              <Loader /> Updating...
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DataTableRowEditorTags
