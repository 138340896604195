import React from 'react'
import Dropdown from 'components/ui/Selector'
import { ContentLangs } from 'config'

const CustomDropdown = ({ value, update }) => {
  return (
    <Dropdown
      datas={ContentLangs}
      showLabel
      showCode
      labelName="Lang"
      value={value}
      update={update}
    />
  )
}

export default CustomDropdown
