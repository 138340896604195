import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import Loader from '@/components/ui/Loader'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import Skeleton from '@/components/ui/skeleton'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { cn } from '@/lib/utils'
import {
  PaperPlaneIcon,
  PlusIcon,
  TrashIcon,
  UploadIcon,
} from '@radix-ui/react-icons'
import { fetcher, postFetcher } from 'api/swr'
import { selectFileReader } from 'commons'
import { useUI } from 'Editor'
import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { CptCard } from '../LayerPanel/ui'

const BatchUpload = () => {
  const { tid } = useParams()
  const lid = useUI((state) => state.lid)
  const { data: template } = useSWR(['/templates/' + tid], fetcher)
  const { trigger, isMutating, error } = useSWRMutation(
    '/components/batch',
    postFetcher,
  )
  const [uploadFiles, setUploadFiles] = useState([])
  const [open, setOpen] = useState(false)
  const fileInputRef = useRef(null)

  const onOpenChange = (open) => {
    if (!open) {
      resetSelected()
      setUploadFiles([])
      mutate(['/components', { tid }])
    }
    setOpen(open)
  }

  const resetSelected = () => {
    setUploadFiles([])
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }
  const resetTrim = () => {
    setUploadFiles((items) =>
      items.map((item) => ({ ...item, trim: item.trim ? false : true })),
    )
  }
  const resetAI = () => {
    setUploadFiles((items) =>
      items.map((item) => ({ ...item, ai: item.ai ? false : true })),
    )
  }
  const resetAutoCover = () => {
    setUploadFiles((items) =>
      items.map((item) => ({
        ...item,
        autoCover: item.autoCover ? false : true,
      })),
    )
  }
  const setDlc = (dlc) => {
    setUploadFiles((items) => items.map((item) => ({ ...item, dlc })))
  }
  const handleRemove = (id) => {
    const files = uploadFiles.filter((item) => item.id !== id)
    files.length === 0 ? onOpenChange(false) : setUploadFiles(files)
  }
  const handleChange = (id, key, value) => {
    setUploadFiles((items) =>
      items.map((item) => (item.id === id ? { ...item, [key]: value } : item)),
    )
  }
  const selectFile = async (files) => {
    if (files === undefined) {
      return
    }
    setUploadFiles([])
    const filesArray = Array.from(files)
    const tasks = filesArray.map((file) => selectFileReader(file))
    const datas = (await Promise.all(tasks)).map((item) => ({
      ...item,
      trim: true,
      ai: false,
      dlc: tid,
    }))
    setUploadFiles((state) => [...state, ...datas])
  }
  const handleUploadAll = () => {
    const uploadData = uploadFiles.map((item) => {
      const data = {
        tid,
        lid,
        original: item.original,
        trim: item.trim,
        ai: item.ai,
        dlc: item.dlc,
        fileName: item.name,
      }
      if (item.trim) {
        if (item.autoCover) {
          data.autoCover = item.autoCover
        } else {
          data.cover = item?.cover?.original
        }
      }
      return data
    })
    trigger(uploadData, {
      onSuccess: () => {
        setTimeout(() => {
          onOpenChange(false)
        }, 1500)
      },
    })
  }
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <CptCard className="aspect-square">
          <UploadIcon className="w-3/5 h-3/5 text-gray-400 group-hover:text-primary" />
        </CptCard>
      </DialogTrigger>
      <DialogContent
        className="max-w-screen-xl max-h-screen overflow-y-auto"
        onPointerDownOutside={(event) => event.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>Upload cpts</DialogTitle>
          <DialogDescription>
            select cpts for upload. <br />
            accept: jpg, png, jpeg, webp; <br />
            require:
            <span className="text-destructive font-bold ">
              {template?.ow} x{template?.oh}
            </span>
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-1 py-1">
          <div className="flex w-full max-w-sm items-center gap-1.5">
            <Input
              id="picture"
              type="file"
              multiple
              ref={fileInputRef}
              accept=".jpg, .png, .jpeg, .webp"
              className="w-auto"
              onChange={(e) => selectFile(e.target.files)}
            />
            {uploadFiles.length > 0 && (
              <div className="flex gap-1.5">
                <Button variant="outline" onClick={resetSelected}>
                  Reset
                </Button>
                <SelectDlc tid={tid} update={(e) => setDlc(e)} />
                <Button variant="outline" onClick={resetTrim}>
                  Trim
                </Button>
                <Button variant="outline" onClick={resetAI}>
                  AI
                </Button>
                <Button variant="outline" onClick={resetAutoCover}>
                  Auto Cover
                </Button>
              </div>
            )}
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>#</TableHead>
                <TableHead>Cpt</TableHead>
                <TableHead>Cover</TableHead>
                <TableHead>Info</TableHead>
                <TableHead>DLC</TableHead>
                <TableHead>Trim</TableHead>
                <TableHead>AI</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {uploadFiles.map((cpt, idx) => (
                <UploadItem
                  key={cpt.id}
                  idx={idx}
                  cpt={cpt}
                  isBatchMutating={isMutating}
                  handleChange={handleChange}
                  handleRemove={handleRemove}
                  template={template}
                />
              ))}
            </TableBody>
          </Table>
          <div className="text-end">
            {uploadFiles.length > 0 && (
              <Button
                variant="default"
                disabled={isMutating}
                onClick={handleUploadAll}
              >
                <PaperPlaneIcon className="mr-2 h-4 w-4 -rotate-45" />
                {isMutating ? 'Uploading...' : 'Upload All'}
              </Button>
            )}
            {error}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const UploadItem = ({
  cpt,
  handleRemove,
  handleChange,
  template,
  isBatchMutating,
  idx,
}) => {
  const { tid } = useParams()
  const lid = useUI((state) => state.lid)
  const [isSuccess, setIsSuccess] = useState(false)
  const { trigger, isMutating, error } = useSWRMutation(
    '/components',
    postFetcher,
  )

  const coverRef = useRef(null)

  const handleSelectCover = () => {
    if (coverRef.current) {
      coverRef.current.value = null
      coverRef.current.click()
    }
  }

  const handleFileChange = async (event) => {
    setIsSuccess(false)
    const file = event.target.files[0]
    if (file) {
      const cover = await selectFileReader(file)
      handleChange(cpt.id, 'cover', cover)
    }
  }

  const handleUpload = () => {
    const uploadData = {
      tid,
      lid,
      original: cpt.original,
      trim: cpt.trim,
      ai: cpt.ai,
      dlc: cpt.dlc,
      fileName: cpt.name,
    }
    if (cpt.trim) {
      if (cpt.autoCover) {
        uploadData.autoCover = cpt.autoCover
      } else {
        uploadData.cover = cpt?.cover?.original
      }
    }

    trigger(uploadData, {
      onSuccess: () => {
        setIsSuccess(true)
        setTimeout(() => {
          handleRemove(cpt.id)
        }, 1500) // Delay of 1000ms
      },
    })
  }

  const coverError =
    cpt?.cover && (cpt?.cover?.w !== 80 || cpt?.cover?.h !== 80)
  const imgError = cpt.w !== template?.ow || cpt.h !== template?.oh

  const hasError = imgError || coverError
  return (
    <TableRow>
      <TableCell>{idx}</TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <input
            type="file"
            ref={coverRef}
            className="hidden"
            onChange={handleFileChange}
            accept="image/*"
          />
          <img
            src={cpt.original}
            alt="..."
            className={cn(
              'h-20 w-20 object-cover transition-all hover:scale-105 aspect-square border bg-white hover:border-primary hover:border-2 rounded-xl',
              imgError && 'border-destructive',
            )}
          />
        </div>
      </TableCell>
      <TableCell>
        {cpt.trim && (
          <div className="flex items-center space-x-2">
            {!cpt.autoCover && cpt?.cover && (
              <img
                src={cpt?.cover?.original}
                onClick={handleSelectCover}
                alt="..."
                className={cn(
                  'h-20 w-20 object-cover transition-all hover:scale-105 aspect-square border bg-white hover:border-primary hover:border-2 rounded-xl',
                  coverError && 'border-destructive',
                )}
              />
            )}
            {!cpt.autoCover && !cpt.cover && (
              <Skeleton
                className="h-20 w-20 flex flex-col items-center justify-center border rounded-xl hover:border-primary"
                onClick={handleSelectCover}
              >
                <PlusIcon className="w-4 h-4" />
                <span className="text-center">Upload Cover</span>
              </Skeleton>
            )}
            <Checkbox
              id={`cover-${cpt.id}`}
              checked={cpt.autoCover}
              onCheckedChange={(e) => handleChange(cpt.id, 'autoCover', e)}
            />
            <label
              htmlFor="cover"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Auto Cover
            </label>
          </div>
        )}
      </TableCell>
      <TableCell>
        <div className="flex flex-col gap-1">
          <div>ID: {cpt.id}</div>
          <div>
            {cpt.w} x {cpt.h}
          </div>
          <div>{cpt.name}</div>
          <div className="bg-destructive">
            {imgError && (
              <span className="text-destructive-foreground">
                {' '}
                img size error
              </span>
            )}
            {coverError && (
              <span className="text-destructive-foreground ">
                cover size error
              </span>
            )}
          </div>
          {isSuccess && (
            <div className="bg-primary text-primary-foreground">success</div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <SelectDlc
          tid={tid}
          value={cpt.dlc}
          update={(e) => handleChange(cpt.id, 'dlc', e)}
        />
      </TableCell>
      <TableCell>
        <div className="flex items-center space-x-2">
          <Checkbox
            id={`trim-${cpt.id}`}
            checked={cpt.trim}
            onCheckedChange={(e) => handleChange(cpt.id, 'trim', e)}
          />
          <label
            htmlFor="trim"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Trim
          </label>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center space-x-2">
          <Checkbox
            id={`ai-${cpt.id}`}
            checked={cpt.ai}
            onCheckedChange={(e) => handleChange(cpt.id, 'ai', e)}
          />
          <label
            htmlFor="ai"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            AI
          </label>
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center gap-2">
          <Button variant="outline" onClick={() => handleRemove(cpt.id)}>
            <TrashIcon className="h-4 w-4" />
          </Button>

          {error ? (
            <>post error</>
          ) : (
            <Button
              variant="default"
              disabled={isMutating || hasError || isBatchMutating}
              onClick={handleUpload}
            >
              <PaperPlaneIcon className="mr-2 h-4 w-4 -rotate-45" />
              {isMutating ? 'Uploading...' : 'Upload'}
            </Button>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

const SelectDlc = ({ tid, update, value }) => {
  const { data: dlcs, isLoading } = useSWR(['/dlcs', { tid }], fetcher)

  if (isLoading) return <Loader />
  if (!dlcs) return <div>{tid}</div>
  return (
    <Select onValueChange={update} defaultValue={value} value={value}>
      <SelectTrigger className="w-auto max-w-xs">
        <SelectValue placeholder={`Select a dlc`} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Select Dlc</SelectLabel>
          <SelectItem value={tid}>{tid} - default</SelectItem>
          {dlcs?.map((dlc) => (
            <SelectItem key={dlc.id} value={dlc.id}>
              {dlc.id} - {dlc.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default BatchUpload
