import DisplayAvatars from 'AdminPanel/Avatars/DisplayAvatars'
import { H3 } from 'components/ui/Title'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

const Avatars = () => {
  const { tid } = useParams()
  const { data: avatars } = useSWR(['/avatars', { tid, state: 401 }])
  //TODO: 需要裁剪avatars列表，把一些功能给收缩起来，不放给个人用户
  return (
    <div className="flex flex-col">
      <div>
        <H3>template info: area{tid}</H3>
      </div>
      <DisplayAvatars avatars={avatars} />
    </div>
  )
}

export default Avatars
