import { cn } from '@/lib/utils'
import React from 'react'
export const ColorCard = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex items-center justify-center',
      'bg-white aspect-square rounded-xl group cursor-pointer',
      'border-2 hover:border-primary',
      className,
    )}
    {...props}
  />
))

ColorCard.displayName = 'ColorCard'
