import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import dayjs from 'dayjs'
import Loader from '@/components/ui/Loader'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { DatePicker } from 'components/ui/date-picker'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import { frameColumns, layerColumns, tagColumns } from './data-table-column'
import Chart from './chart'

const Index = () => {
  const { id } = useParams()
  return (
    <>
      <FrameDaily tid={id} />
      <FrameDetailCount tid={id} />
    </>
  )
}

const FrameDaily = ({ tid }) => {
  const {
    data: frame,
    isLoading: frameLoading,
    isValidating: frameValidating,
  } = useSWR([`/templates/${tid}`])
  const { data, isLoading, isValidating } = useSWR([`/frames/daily`, { tid }])

  if (isLoading || isValidating || frameLoading || frameValidating)
    return <Loader />
  if (!frame) return <EmptyDatas />
  return (
    <>
      <div className="flex flex-wrap my-4">
        <Card className="w-[350px] mt-2 me-2">
          <CardHeader>
            <CardTitle>
              <img src={frame?.preview} alt={frame.id} />
            </CardTitle>
          </CardHeader>
          <CardContent>
            <CardTitle>{frame.name}</CardTitle>
          </CardContent>
        </Card>
        <Chart
          title="recreate count"
          data={data?.reCreateCount}
          xDataKey={'date'}
          yDataKey={'reCreateCount'}
        />
        <Chart
          title="avatar count"
          data={data?.avatarCount}
          xDataKey={'date'}
          yDataKey={'avatars'}
        />
        <Chart
          title="reCreate avatars"
          data={data?.avatarCount}
          xDataKey={'date'}
          yDataKey={'reCreateAvatars'}
        />
        <Chart
          title="originate avatars"
          data={data?.avatarCount}
          xDataKey={'date'}
          yDataKey={'originateAvatars'}
        />
      </div>
    </>
  )
}

const FrameDetailCount = ({ tid }) => {
  const [date, setDate] = useState(dayjs().subtract(2, 'day').$d)
  const { data, isLoading, isValidating } = useSWR([
    `/frames/${tid}/daily`,
    {
      date: dayjs(date).format('YYYYMMDD'),
    },
  ])

  const { data: tagclicks } = useSWR([
    `/tag-clicks/${tid}/daily`,
    {
      date: dayjs(date).format('YYYYMMDD'),
    },
  ])
  if (isLoading || isValidating) return <Loader />
  if (!data && !tagclicks) return <EmptyDatas />
  return (
    <>
      <DatePicker date={date} onSelect={setDate} />
      <TemplateData data={data?.templates} />
      <LayerData data={data?.layers} />
      <TagData data={tagclicks} />
    </>
  )
}

const TemplateData = ({ data }) => {
  const table = useReactTable({
    data: data,
    columns: frameColumns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })
  if (!data) return
  return (
    <>
      <Card className="mt-4">
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

const LayerData = ({ data }) => {
  const table = useReactTable({
    data: data,
    columns: layerColumns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })
  if (!data) return
  return (
    <>
      <Card className="mt-4">
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

const TagData = ({ data }) => {
  const table = useReactTable({
    data: data,
    columns: tagColumns,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })
  if (!data) return
  return (
    <>
      <Card className="mt-4">
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </>
  )
}

export default Index
