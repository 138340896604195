import React, { useRef, useState, useCallback } from 'react'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { PlusIcon } from '@radix-ui/react-icons'
import Skeleton from '@/components/ui/skeleton'
import { Button } from '@/components/ui/button'

import { cn } from '@/lib/utils'
import useSWRMutation from 'swr/mutation'
import { putFetcher } from 'api/swr'
import { selectFileReader } from 'commons'
import { mutate } from 'swr'
import Loader from '@/components/ui/Loader'
import AvatarPicker from './AvatarPicker'

const TemplateCover = ({ form, template }) => {
  return (
    <FormField
      control={form.control}
      name="coverField"
      render={({ field }) => (
        <FormItem className="space-y-1">
          <FormLabel>Cover</FormLabel>
          <FormDescription>Choose a cover for the display.</FormDescription>
          <FormMessage />
          <RadioGroup
            onValueChange={field.onChange}
            defaultValue={field.value}
            className="grid grid-cols-3 gap-2 pt-2"
          >
            <FormItem>
              <RadioCover nameText="Cover" value="cover">
                <PickerCover form={form} template={template} type={'cover'} />
              </RadioCover>
            </FormItem>
            {/* <FormItem>
              <CoverDiv>
                <PickerCover form={form} template={template} type={'coverA'} />
                <div className="font-normal flex items-center justify-center p-2 gap-2">
                  <FormLabel>{'CoverA'}</FormLabel>
                </div>
              </CoverDiv>
            </FormItem> */}
            <FormItem>
              <UpdateSketch template={template} />
            </FormItem>
          </RadioGroup>
        </FormItem>
      )}
    />
  )
}

const RadioCover = ({ nameText, value, children }) => {
  return (
    <CoverDiv>
      {children}
      <div className="font-normal flex items-center justify-center p-2 gap-2">
        <FormControl>
          <RadioGroupItem value={value} />
        </FormControl>
        <FormLabel>{nameText}</FormLabel>
      </div>
    </CoverDiv>
  )
}

const PickerCover = ({ form, template, type }) => {
  const onSubmit = useCallback(
    (avatar) => {
      form.setValue(type, avatar.url)
      console.log(type, avatar.url)
      if (type === 'cover') {
        form.setValue('initDataId', avatar.id)
      }
    },
    [form, type],
  )

  const coverUrl = form.watch(type, template[type])
  return (
    <AvatarPicker original={template.initDataId} onSubmit={onSubmit}>
      <img
        alt="template cover"
        className="aspect-square w-48 h-48 rounded-md object-cover"
        src={coverUrl}
      />
    </AvatarPicker>
  )
}

const UpdateSketch = ({ template }) => {
  const ref = useRef(null)
  const { trigger, isMutating } = useSWRMutation(
    `/templates/${template.id}`,
    putFetcher,
  )

  const [selectedFile, setSelectedFile] = useState(null)

  const handleClick = () => {
    if (ref.current) {
      ref.current.value = null
      ref.current.click()
    }
  }

  const handleSelectFile = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const cover = await selectFileReader(file)
      setSelectedFile(cover)
    }
  }

  const handleResetFile = () => {
    if (ref.current) {
      ref.current.value = null
    }
    setSelectedFile(null)
  }

  const handleUpload = () => {
    const value = JSON.stringify({ sketch: selectedFile.original })
    trigger(
      { key: 'sketch', value },
      {
        onSuccess: () => {
          mutate(['/templates/' + template.id])
          handleResetFile()
        },
      },
    )
  }

  if (selectedFile) {
    return (
      <CoverDiv>
        <img
          alt="template cover"
          className="aspect-square w-48 h-48 rounded-md object-cover"
          src={selectedFile.original}
          onClick={handleClick}
        />
        <div className="flex gap-2">
          {isMutating ? (
            <Loader>Uploading sketch...</Loader>
          ) : (
            <>
              <Button size="sm" onClick={handleResetFile}>
                reset
              </Button>
              <Button variant="outline" size="sm" onClick={handleUpload}>
                Upload
              </Button>
            </>
          )}
        </div>
        <input
          type="file"
          ref={ref}
          className="hidden"
          onChange={handleSelectFile}
          accept=".jpg, .png, .jpeg, .webp"
        />
      </CoverDiv>
    )
  }

  if (template?.sketch) {
    return (
      <RadioCover nameText="Sketch" value="sketch">
        <img
          alt="template cover"
          className="aspect-square w-48 h-48 rounded-md object-cover"
          src={template?.sketch}
          onClick={handleClick}
        />
        <input
          type="file"
          ref={ref}
          className="hidden"
          onChange={handleSelectFile}
          accept=".jpg, .png, .jpeg, .webp"
        />
      </RadioCover>
    )
  }
  //update
  return (
    <CoverDiv>
      <Skeleton
        className={cn(
          'h-48 w-48 rounded-md flex flex-col items-center justify-center',
          'group text-gray-400 hover:text-primary',
        )}
        onClick={handleClick}
      >
        <PlusIcon className="w-3/5 h-3/5" />
        <span className="text-center">Select sketch file</span>
      </Skeleton>
      <input
        type="file"
        ref={ref}
        className="hidden"
        onChange={handleSelectFile}
        accept=".jpg, .png, .jpeg, .webp"
      />
    </CoverDiv>
  )
}

const CoverDiv = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex flex-col items-center rounded-md border-2 border-muted bg-popover p-1 gap-2',
      'hover:bg-accent hover:text-accent-foreground',
      '[&:has([data-state=checked])]:border-primary',
    )}
    {...props}
  />
))

CoverDiv.displayName = 'CoverDiv'

export default TemplateCover
