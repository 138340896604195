import React from 'react'
import MyPagination from 'components/ui/MyPagination'
import MyRefetchButton from 'components/ui/MyRefetchButton'
import TemplateCreate from 'components/template-create'
import { useForm } from './index'

const DataTableToolbar = ({ mutate, nextAfter }) => {
  const afters = useForm((s) => s.afters)
  const after = useForm((s) => s.after)
  const goAfter = useForm((s) => s.goAfter)
  return (
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center gap-4 ">
        <MyPagination
          nextAfter={nextAfter}
          after={after}
          goAfter={goAfter}
          afters={afters}
        />
        <MyRefetchButton onClick={mutate} />
      </div>
      <TemplateCreate mutate={mutate} />
    </div>
  )
}

export default DataTableToolbar
