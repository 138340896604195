export const ContentLangs = [
  { code: 0, name: 'All', short: 'All' },
  { code: 3, name: 'BR User', short: 'BR' },
  { code: 4, name: 'Indonesia', short: 'ID' },
  { code: 5, name: 'India', short: 'IN' },
  { code: 6, name: 'Pakistan', short: 'PK' },
  { code: 7, name: 'Arab', short: 'EG' },
  { code: 8, name: 'Africa', short: 'AF' },
  { code: 9, name: 'US User', short: 'US' },
  { code: 10, name: 'South Africa', short: 'ZA' },
  { code: 11, name: 'Thailand', short: 'TH' },
  { code: 12, name: 'Mexico', short: 'MX' },
  { code: 13, name: 'Nigeria', short: 'NG' },
  { code: 14, name: 'zh-TW', short: 'TW' },
  { code: 15, name: 'Singapore', short: 'SG' },
  { code: 16, name: 'Malaysia', short: 'MY' },
  { code: 17, name: 'Colombia', short: 'CO' },
  { code: 18, name: 'HongKong', short: 'HK' },
  { code: 19, name: 'Turkey', short: 'TR' },
  { code: 20, name: 'Japan', short: 'JP' },
  { code: 21, name: 'Argentina', short: 'AR' },
  { code: 22, name: 'Russian', short: 'RU' },
  { code: 23, name: 'Portugal', short: 'PT' },
  { code: 24, name: 'Spain', short: 'ES' },
  { code: 25, name: 'France', short: 'FR' },
  { code: 26, name: 'Germany', short: 'DE' },
  { code: 27, name: 'United Kingdom', short: 'GB' },
  { code: 28, name: 'Senegal', short: 'SN' },
  { code: 29, name: 'Ghana', short: 'GH' },
  { code: 30, name: 'Italy', short: 'IT' },
  { code: 31, name: 'Chile', short: 'CL' },
  { code: 32, name: 'Philippines', short: 'PH' },
  { code: 33, name: 'Canada', short: 'CA' },
  { code: 34, name: 'Korea', short: 'KR' },
  { code: 35, name: 'Australia', short: 'AU' },
  { code: 36, name: 'News Land', short: 'NZ' },
  { code: 37, name: 'Uruguay', short: 'UY' },
  { code: 38, name: 'Croatia', short: 'HR' },
  { code: 39, name: 'Poland', short: 'PL' },
  { code: 40, name: 'Austria', short: 'AT' },
  { code: 41, name: 'Netherlands', short: 'NL' },
  { code: 42, name: 'Belgium', short: 'BE' },
  { code: 43, name: 'Ireland', short: 'IE' },
  { code: 44, name: 'Finland', short: 'FI' },
  { code: 45, name: 'Isral', short: 'IL' },
  { code: 46, name: 'Switzerland', short: 'CH' },
]

export const LevelOptions = [
  { code: 0, name: 'Miss Level' },
  { code: 1, name: 'SUPER' },
  { code: 2, name: 'Special' },
  { code: 4, name: 'Pro' },
  { code: 4.1, name: 'Pro-Android' },
  { code: 4.2, name: 'Pro-IOS' },
  { code: 5, name: 'NEW' },
]

export const LabelOptions = [
  { code: 'Non-commercial', name: 'Non-commercial' },
  { code: 'Personal', name: 'Personal' },
  { code: 'OC', name: 'OC' },
  { code: 'Custom', name: 'Custom' },
  { code: 'DLC', name: 'DLC' },
]

export const CptPrices = [
  { code: 0, name: 0 },
  { code: 900, name: 900 },
  { code: 950, name: 950 },
  { code: 1200, name: 1200 },
  { code: 1300, name: 1300 },
  { code: 1000, name: 1000 },
  { code: 1500, name: 1500 },
  { code: 2000, name: 2000 },
]
