import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRole } from 'hooks/auth'
import useSWRMutation from 'swr/mutation'
import { mutate } from 'swr'
import { putFetcher } from 'api/swr'
import { Pencil1Icon } from '@radix-ui/react-icons'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog'
import Divider from 'components/ui/Divider'
import Loader from '@/components/ui/Loader'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import { cn } from '@/lib/utils'
import { cptCss } from '../ui'

const UpdateCpt = ({ cpt, cpts = [] }) => {
  const { tid } = useParams()
  const isAdmin = useRole(`admin`)
  const [open, setOpen] = useState(false)
  const { trigger, isMutating } = useSWRMutation(
    `/components/${cpt.id}`,
    putFetcher,
  )
  const parentCpt = useMemo(() => {
    return cpts?.find((item) => item.id === cpt.pid)
  }, [cpt, cpts])

  const handleUpdate = (key, value) => {
    if (isMutating) return false
    trigger(
      { tid, key, value: value },
      {
        onSuccess: () => {
          mutate(['/components', { tid }])
        },
      },
    )
  }

  if (!isAdmin) {
    return null
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <Pencil1Icon className="h-4 w-4 mr-2" /> Update Parent
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Update parent component</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <div className="flex flex-col">
          <div className="flex items-end">
            <div className="flex flex-col me-2">
              <h3 className="mb-2">Current Cpt</h3>
              <img
                alt="cpt original"
                src={`https://img.zthd.io/an1/ats/${cpt.id}`}
                className={cn(cptCss)}
              />
            </div>
            <div className="flex flex-col">
              <h3 className="mb-2">Parent Cpt</h3>
              {parentCpt ? (
                <img
                  alt="cpt original"
                  src={`https://img.zthd.io/an1/ats/${parentCpt?.id}`}
                  className={cn(cptCss)}
                />
              ) : (
                <div className={cn(cptCss)}></div>
              )}
            </div>
          </div>
          <Divider className="mt-1" />
          <div className="grid sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 grid-cols-9 gap-2 p-1">
            {cpts?.map((item) => (
              <img
                key={item.id}
                onClick={() => handleUpdate('pid', item.id)}
                src={`https://img.zthd.io/an1/ats/${item.id}`}
                alt="layer cover"
                className={`me-2 mt-2 h-16 w-16 object-cover transition-all hover:scale-105 aspect-square border hover:border-primary rounded-xl ${item.id === cpt.pid ? 'border-primary' : ''}`}
              />
            ))}
            <div
              className={`flex justify-center items-center text-gray-500 me-2 mt-2 h-16 w-16 object-cover transition-all hover:scale-105 aspect-square border hover:border-primary rounded-xl ${!cpt.pid ? 'border-primary' : ''}`}
              onClick={() => handleUpdate('pid', '')}
            >
              NULL
            </div>
          </div>
          <div className="grid gap-4 py-4">
            <div className="flex items-center justify-center">
              {isMutating && <Loader> updateing</Loader>}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default UpdateCpt
