import React, { useCallback, useState } from 'react'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { delFetcher, putFetcher } from 'api/swr'
import { Button } from '@/components/ui/button'
import { toast, useToast } from '@/components/ui/use-toast'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'
import { ToastAction } from '@/components/ui/toast'
import Skeleton from '@/components/ui/skeleton'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  ClipboardIcon,
  DotsHorizontalIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
  UpdateIcon,
} from '@radix-ui/react-icons'
import { LayerCoverPicker } from 'components/LayerCoverPicker'
import ImgWithState from 'components/ui/ImgWithState'
import { DropdownMenu } from 'components/ui/DropdownMenu'
import { cn } from '@/lib/utils'

export const DataTableRowActions = ({ row }) => {
  const data = row.original
  return (
    <div className="p-4">
      <DropdownMenu
        label={'Actions'}
        trigger={
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
          </Button>
        }
      >
        <DropdownMenuItem
          onSelect={() => navigator.clipboard.writeText(data.id)}
        >
          <ClipboardIcon className="mr-2 h-4 w-4" /> Copy ID
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DataTableRowDelete data={data} />
      </DropdownMenu>
    </div>
  )
}

const DataTableRowDelete = ({ data }) => {
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation('/layer-groups', delFetcher, {
    onSuccess: () => {
      mutate(['/layer-groups'])
      toast({
        title: 'Layer group Deleted',
        description: 'The layer group has been successfully deleted.',
      })
    },
  })

  const handleDelete = () => {
    trigger({ id: data.id })
  }

  //TODO: add confirmation dialog
  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete Layer Group',
      description: 'Are you sure you want to delete this layer group?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem onSelect={confirm}>
      {isMutating ? (
        <>
          <UpdateIcon className="mr-2 h-4 w-4" />
          <span>Deleteing...</span>
        </>
      ) : (
        <>
          <TrashIcon className="mr-2 h-4 w-4" />
          <span>Delete</span>
        </>
      )}
    </DropdownMenuItem>
  )
}

export const PlayIndex = ({ row }) => {
  const data = row.original
  const { trigger } = useSWRMutation(`/layer-groups/${data.id}`, putFetcher, {
    onSuccess: () => {
      mutate([`/layer-groups`])
    },
  })

  const onUp = () => {
    trigger({ playIndex: data.playIndex + 1 })
  }
  const onDown = () => {
    trigger({ playIndex: data.playIndex - 1 })
  }

  //TODO: span was input??
  return (
    <div className="flex items-center justify-center gap-1">
      <Button
        className={cn('h-5 w-5')}
        variant="outline"
        size="icon"
        onClick={onDown}
      >
        <MinusIcon className="h-2.5 w-2.5" />
      </Button>

      <span className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 dark:text-white">
        {data.playIndex}
      </span>
      <Button
        variant="outline"
        className={cn('h-5 w-5')}
        size="icon"
        onClick={onUp}
      >
        <PlusIcon className=" h-2.5 w-2.5" />
      </Button>
    </div>
  )
}

export const DataTableRowText = ({ row, entityKey }) => {
  const data = row.original

  const { trigger, isMutating } = useSWRMutation(
    `/layer-groups/${data.id}`,
    putFetcher,
    {
      onSuccess: () => {
        mutate([`/layer-groups`])
      },
    },
  )

  const [text, setText] = useState(data[entityKey] || '')
  const [isOpen, setIsOpen] = useState(false)

  const disabled = !text || isMutating || data[entityKey] === text
  const onSubmit = () => {
    if (disabled) {
      return null
    }
    trigger({ [entityKey]: text })
    setIsOpen(false)
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <div
            onClick={() => setIsOpen(true)}
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
            )}
          >
            {data[entityKey]}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {entityKey}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {entityKey}
              </Label>
              <Input
                className="col-span-3"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || !text || text === data[entityKey]}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const Cover = ({ row }) => {
  const data = row.original
  const [cover, setCover] = useState(data.cover || '')
  const { trigger, isMutating } = useSWRMutation(
    `/layer-groups/${data.id}`,
    putFetcher,
    {
      onSuccess: () => {
        toast({
          title: 'Cover Updated',
          description: 'The cover has been successfully updated.',
        })
      },
    },
  )

  const onSubmit = useCallback(
    (v) => {
      const { id } = v
      setCover(id)
      trigger({ cover: id })
    },
    [trigger],
  )
  return (
    <LayerCoverPicker currCover={{ id: cover }} onSubmit={onSubmit}>
      {cover ? (
        <ImgWithState
          isMutating={isMutating}
          src={`https://img.zthd.io/an1/acs/${cover}`}
        />
      ) : (
        <Skeleton className="w-16 h-16 rounded-xl" />
      )}
    </LayerCoverPicker>
  )
}
