import {
  columnCover,
  columnLabels,
  columnName,
  columnPublisher,
  columnLevel,
  columnTags,
  columnAvatars,
  columnPreview,
} from 'components/template-data-table-column'
import React from 'react'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'
import { DataTableRowActions } from './data-table-row-action'

export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  columnCover,
  columnPreview,
  columnName,
  columnPublisher,
  columnLevel,
  columnTags,
  columnLabels,
  columnAvatars,
  {
    header: 'CreateTime',
    accessorKey: 'createTime',
    cell: ({ row }) => (
      <DisplayUpdateTime updateTime={row.original.createTime} />
    ),
  },
  {
    header: 'Actions',
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
