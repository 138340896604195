'use client'
import {
  UpdateIcon,
  PaperPlaneIcon,
  Component1Icon,
  CopyIcon,
  DotsHorizontalIcon,
  TrashIcon,
} from '@radix-ui/react-icons'
import React from 'react'
import { ToastAction } from '@/components/ui/toast'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { toast } from '@/components/ui/use-toast'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import Skeleton from '@/components/ui/skeleton'
import { Textarea } from '@/components/ui/textarea'
import { useToast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import { fetcher, putFetcher, postFetcher, delFetcher } from 'api/swr'
import { useEditorStore } from 'CanvasCore/uiSlice'
import Divider from 'components/ui/Divider'
import { useUI } from 'Editor'
import { CptCard } from 'Editor/BottomPanel/LayerPanel/ui'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'

export function DataTableRowActions({ row }) {
  const dlc = row.original

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuItem onClick={() => navigator.clipboard.writeText(dlc.id)}>
          <CopyIcon className="mr-2 h-4 w-4" /> Copy id
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <SubmitReview dlc={dlc} />
        <DropdownMenuSeparator />
        <DeleteDlc dlc={dlc} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const SubmitReview = ({ dlc }) => {
  const { tid } = useParams()

  const { trigger, isMutating } = useSWRMutation(
    `/publish/draft`,
    postFetcher,
    {
      onSuccess: () => {
        toast({
          title: 'Review submitted',
          description: 'Your review has been submitted successfully',
        })
      },
      onError: (error) => {
        console.log(error)
        toast({
          variant: 'destructive',
          title: 'Failed to submit review',
          description: error.message,
        })
      },
    },
  )
  const handleClick = () => {
    trigger({ tid, dlc: dlc.id })
  }
  return (
    <DropdownMenuItem onClick={() => handleClick()}>
      {isMutating ? (
        <>
          <UpdateIcon className="mr-2 h-4 w-4 animate-spin" />
          <span>Submitting review</span>
        </>
      ) : (
        <>
          <PaperPlaneIcon className="mr-2 h-4 w-4 -rotate-45" />
          <span>Submit review</span>
        </>
      )}
    </DropdownMenuItem>
  )
}

export const DataTableRowCover = ({ row }) => {
  const { tid } = useParams()
  const dlc = row.original

  //TODO: fetch avatars by user
  const swr = useSWRMutation(`/dlcs/${dlc.id}`, putFetcher, {
    onSuccess: () => {
      mutate(['/dlcs', { tid }])
    },
  })

  return (
    <DialogPickerCover swr={swr} value={dlc.cover}>
      {dlc.cover ? (
        <div className="w-16">
          <img
            alt="..."
            className="aspect-square rounded-md object-cover w-16 h-16 ml-1"
            src={dlc.cover}
          />
        </div>
      ) : (
        <Skeleton className="w-16 h-16 rounded-xl" />
      )}
    </DialogPickerCover>
  )
}

export const DialogPickerCover = ({ value, children, swr }) => {
  const { tid } = useParams()
  const { data: avatars } = useSWR(['/avatars', { tid, state: 0 }], fetcher)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState({ id: value })

  const onSubmit = () => {
    swr.trigger({ key: 'cover', value: selected.url })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-h-[80vh] gap-4">
        <DialogHeader>
          <DialogTitle>Update Cover</DialogTitle>
          <DialogDescription>
            Modify and update your cover image.
          </DialogDescription>
        </DialogHeader>
        <Divider />
        <div className="flex-1 overflow-y-auto max-h-[50vh]">
          <div className="grid grid-cols-4 gap-2 px-1">
            {avatars?.map((avatar) => (
              <AvatarItem
                key={avatar.id}
                avatar={avatar}
                selected={selected?.id === avatar.id}
                onClick={() => setSelected(avatar)}
              />
            ))}
          </div>
        </div>
        <DialogFooter>
          <Button
            disabled={swr.isMutating || selected?.url === value}
            onClick={onSubmit}
          >
            Update{swr.isMutating && 'ing...'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const AvatarItem = ({ avatar, selected, ...props }) => {
  return (
    <div className="flex flex-col items-center" {...props}>
      <div
        className={cn(
          'bg-white border-2 border-transparent rounded-xl w-full',
          'flex items-center justify-center group',
          'hover:border-primary',
          selected && 'border-primary',
        )}
      >
        <div className="w-full h-full relative">
          <img
            alt="..."
            className="w-full h-full aspect-square object-contain rounded-xl"
            src={avatar?.thumb ?? avatar.url}
          />
        </div>
      </div>
    </div>
  )
}

export const DataTableRowCptCount = ({ row }) => {
  const dlc = row.original
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost">
          <Component1Icon className={cn('w-4 h-4 mr-2')} />
          {dlc.cpts?.length || 0}
        </Button>
      </DialogTrigger>
      <DialogContent className="h-4/5 overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Components</DialogTitle>
          <DialogDescription>
            The components belong to the DLC package.
          </DialogDescription>
        </DialogHeader>
        <div className="flex-1 overflow-y-auto">
          <div className="grid grid-cols-5 gap-2 px-1">
            {dlc.cpts?.map((cpt) => (
              <CptItem key={cpt.id} cpt={cpt} />
            ))}
          </div>
        </div>
        <DialogFooter>
          <Button>Submit</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const CptItem = ({ cpt }) => {
  const { tid } = useParams()
  const { data: layers } = useSWR(['/layers', { tid }])
  const { toast } = useToast()

  const toggleLayer = useUI((state) => state.toggleLayer)

  const selected = useEditorStore((state) =>
    state.editors[tid]?.drawers?.some((d) => d.cid === cpt.id),
  )

  const selectCpt = useEditorStore((state) => state.selectCpt)
  const deselectCpt = useEditorStore((state) => state.deselectCpt)

  const handleClick = () => {
    const layer = layers?.find((layer) => layer.id === cpt.lid)
    if (!layer) {
      toast({
        variant: 'destructive',
        title: 'Oops! Layer Not Found',
        description:
          'The specified layer could not be located. Please verify the layer configuration.',
      })
      return
    }
    if (selected) {
      deselectCpt(tid, cpt.id)
    } else {
      selectCpt(tid, layer, cpt)
    }
    toggleLayer(layer.id)
  }
  return (
    <CptCard
      onClick={handleClick}
      className={cn(selected && 'border-2 border-primary')}
    >
      <img
        className="w-full h-full aspect-square object-contain rounded-xl"
        src={`https://img.zthd.io/an1/ats/${cpt.cover ?? cpt.id}`}
        alt=""
      />
    </CptCard>
  )
}

export const DataTableRowName = ({ row }) => {
  const { tid } = useParams()
  const dlc = row.original

  const [open, setOpen] = useState(false)
  const { trigger, isMutating } = useSWRMutation(`/dlcs/${dlc.id}`, putFetcher)

  const [name, setName] = useState(dlc.name || '')
  const [intro, setIntro] = useState(dlc.intro || '')

  const needUpdate =
    (name && name !== dlc.name) || (intro && intro !== dlc.intro)

  const onSubmit = () => {
    const value = JSON.stringify({ name, intro })
    trigger(
      { key: 'name', value },
      {
        onSuccess: () => {
          mutate(['/dlcs', { tid }])
          setOpen(false)
        },
      },
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div className="w-56 flex flex-col">
          <div
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
              'pl-0',
            )}
          >
            {dlc.name}
          </div>
          <span className="truncate w-56 text-gray-500 ">{dlc.intro}</span>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update name</DialogTitle>
          <DialogDescription>update dlc name</DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              className="col-span-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Description
            </Label>
            <Textarea
              className="col-span-3"
              value={intro}
              onChange={(e) => setIntro(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <Button disabled={isMutating || !needUpdate} onClick={onSubmit}>
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const DeleteDlc = ({ dlc }) => {
  const { tid } = useParams()
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation('/dlcs', delFetcher, {
    onSuccess: () => {
      mutate(['/dlcs', { tid }])
      toast({
        title: 'Dlc Deleted',
        description: 'The dlc has been successfully deleted.',
      })
    },
  })

  const handleDelete = () => {
    trigger({ id: dlc.id, body: { tid } })
  }

  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete',
      description: 'Are you sure you want to delete this?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem
      className="text-destructive focus:bg-destructive focus:text-destructive-foreground"
      onSelect={confirm}
    >
      <TrashIcon className="mr-2 h-4 w-4" />
      Delete{isMutating && 'ing...'}
      <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
    </DropdownMenuItem>
  )
}
