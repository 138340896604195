import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Form } from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { tokenFetcher, useAuthStore } from 'api/swr'
import { MyFormInput } from 'components/ui/MyFormField'
import MyLink from 'components/ui/MyLink'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'
import { z } from 'zod'

const loginFromSchema = z.object({
  username: z.string().email('Please enter a valid email address'),
  password: z
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(16, 'Password must be at most 16 characters long')
    .regex(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character',
    ),
})

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/personal'

  const [defaultValues] = useState({
    username: '',
    password: '',
  })

  const isLoggedIn = useAuthStore((state) => state.auth?.token)

  const form = useForm({
    resolver: zodResolver(loginFromSchema),
    defaultValues,
  })

  const { setError } = form

  const { trigger, isMutating } = useSWRMutation('/login', tokenFetcher, {
    onSuccess: () => {
      navigate(from)
    },
    onError: () => {
      setError('username', {
        message: 'Incorrect username or password. Please try again.',
      })
    },
  })

  const onSubmit = async (values) => {
    trigger(values)
  }

  if (isLoggedIn) {
    return <Navigate to="/personal" />
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Card className="mx-auto max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>
            Enter your email below to login to your account NeKu for designer
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <MyFormInput
                name="username"
                form={form}
                label="Email"
                placeholder="m@example.com"
                autoComplete="email"
              />
              <MyFormInput
                name="password"
                form={form}
                label="Password"
                type="password"
                placeholder="********"
                autoComplete="current-password"
              />

              <Button type="submit" disabled={isMutating}>
                Sign in{isMutating ? 'ing' : ''}
              </Button>
              <div className="flex items-center gap-2">
                New to Neku? <MyLink to="/">Create an account</MyLink>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </div>
  )
}

export default Login
