import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUpdatePublisher } from 'api'
import { cn } from '@/lib/utils'
import { DotsHorizontalIcon, Link2Icon, TrashIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuShortcut,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog'

export const DataTableRowActions = ({ row }) => {
  const navigate = useNavigate()
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuItem
          onSelect={() => navigate(`/admin/publishers/${row.original.id}`)}
        >
          <Link2Icon className="w-4 h-4 mr-2" />
          Template
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DataTableRowDelete row={row} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export const DataTableRowText = ({ row, entityKey, type }) => {
  const data = row.original

  const { handleUpdate, isMutating } = useUpdatePublisher(data.id)

  const [text, setText] = useState(data[entityKey] || '')
  const [isOpen, setIsOpen] = useState(false)

  const disabled = !text || isMutating || data[entityKey] === text
  const onSubmit = () => {
    if (disabled) {
      return null
    }
    handleUpdate(entityKey, text, false)
    setIsOpen(false)
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <div
            onClick={() => setIsOpen(true)}
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
            )}
          >
            {data[entityKey]}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {entityKey}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {entityKey}
              </Label>
              <Input
                className="col-span-3"
                value={text}
                type={type || 'text'}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || !text || text === data[entityKey]}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const DataTableRowDelete = ({ row }) => {
  const data = row.original
  const { toast } = useToast()
  const { handleUpdate, isMutating } = useUpdatePublisher(data.id)
  const handleDelete = () => {
    handleUpdate('state', -1, false)
  }

  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete',
      description: 'Are you sure you want to delete this?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem
      className="text-destructive focus:bg-destructive focus:text-destructive-foreground"
      onSelect={confirm}
    >
      <TrashIcon className="mr-2 h-4 w-4" />
      Delete{isMutating && 'ing...'}
      <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
    </DropdownMenuItem>
  )
}
