import React from 'react'
import useSWR from 'swr'
import { create } from 'zustand'
import { useParams } from 'react-router-dom'
import Loader from '@/components/ui/Loader'
import { Button } from '@/components/ui/button'
import MyPagination from 'components/ui/MyPagination'
import DropdownClientV0 from 'components/DropdownClientV0'
import DropdownLang from 'components/DropdownLang'
import DropdownOrder from 'components/DropdownOrder'
import DropdownState from 'components/DropdownState'
import DisplayAvatars from 'AdminPanel/Avatars/DisplayAvatars'

export const useForm = create((set) => ({
  lang: 9,
  order: 'updateTime',
  state: '401',
  clientVer: null,
  after: null,
  afters: [],
  updateLang: (value) => set({ lang: value, after: null, afters: [] }),
  updateOrder: (value) => set({ order: value, after: null, afters: [] }),
  updateState: (value) => set({ state: value, after: null, afters: [] }),
  updateClientVer: (value) =>
    set({ clientVer: value, after: null, afters: [] }),
  setOrder: (order) => set({ order, after: null, afters: [] }),
  goAfter: (nextAfter) =>
    set((state) => {
      if (nextAfter === null) {
        return { after: null }
      }
      const index = state.afters.findIndex((a) => a === nextAfter)
      if (index === -1) {
        return { after: nextAfter, afters: [...state.afters, nextAfter] }
      } else {
        return { after: nextAfter }
      }
    }),
}))

const Avatars = () => {
  const { id } = useParams()
  const {
    afters,
    after,
    goAfter,
    lang,
    updateLang,
    order,
    updateOrder,
    state,
    updateState,
    clientVer,
    updateClientVer,
  } = useForm((s) => s)

  const params = useForm((s) => {
    const { lang, order, state, clientVer, after } = s
    return { lang, order, state, clientVer, after, tid: id }
  })
  const { data, isLoading, isValidating, mutate } = useSWR(['/avatars', params])

  const nextAfter = data?.[data.length - 1]?.id
  return (
    <div>
      <div className="flex items-center gap-4 py-2">
        <DropdownLang value={lang} update={updateLang} />
        <DropdownOrder value={order} update={updateOrder} />
        <DropdownState value={state} update={updateState} />
        <DropdownClientV0 value={clientVer} update={updateClientVer} />
        <Button variant="outline" onClick={() => mutate()}>
          Fetch
        </Button>
      </div>
      <MyPagination
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
      {(isLoading || isValidating) && <Loader />}
      {data?.length <= 0 && <div>no avatars</div>}
      <DisplayAvatars avatars={data} />
      <MyPagination
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
    </div>
  )
}

export default Avatars
