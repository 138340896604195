import CanvasCore from 'CanvasCore'
import React, { useRef } from 'react'
import Overlay from './Overlay'

const CanvasPanel = ({ template }) => {
  const drawRef = useRef(null)
  return (
    <div className="relative w-full h-full">
      <CanvasCore ow={template.ow} oh={template.oh} drawRef={drawRef} />
      <Overlay drawRef={drawRef} />
    </div>
  )
}

export default CanvasPanel
