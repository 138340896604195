import React, { useState } from 'react'
import {
  DotsHorizontalIcon,
  TrashIcon,
  UpdateIcon,
} from '@radix-ui/react-icons'
import useSWRMutation from 'swr/mutation'
import { delFetcher } from 'api/swr'
import { mutate } from 'swr'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { cn } from '@/lib/utils'
import { useUpdateTonings } from 'api'

export function DataTableRowActions({ row }) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DataTableRowDelete row={row} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const DataTableRowDelete = ({ row }) => {
  const data = row.original
  const { toast } = useToast()
  const { trigger, isMutating } = useSWRMutation(
    '/tonings/template',
    delFetcher,
    {
      onSuccess: () => {
        mutate(['/tonings/template'])
        toast({
          title: 'Toning Deleted',
          description: 'The toning has been successfully deleted.',
        })
      },
    },
  )

  const handleDelete = () => {
    trigger({ id: data.id })
  }

  //TODO: add confirmation dialog
  const confirm = (e) => {
    e.preventDefault()
    toast({
      variant: 'destructive',
      title: 'Delete Toning',
      description: 'Are you sure you want to delete this toning?',
      action: (
        <ToastAction altText="Try again" onClick={handleDelete}>
          Delete
        </ToastAction>
      ),
    })
  }

  return (
    <DropdownMenuItem onSelect={confirm}>
      {isMutating ? (
        <>
          <UpdateIcon className="mr-2 h-4 w-4" />
          <span>Deleteing...</span>
        </>
      ) : (
        <>
          <TrashIcon className="mr-2 h-4 w-4" />
          <span>Delete</span>
        </>
      )}
    </DropdownMenuItem>
  )
}

export const DataTableRowText = ({ row, entityKey }) => {
  const data = row.original

  const { handleUpdate, isMutating } = useUpdateTonings(data.id)

  const [text, setText] = useState(data[entityKey] || '')
  const [isOpen, setIsOpen] = useState(false)

  const disabled = !text || isMutating || data[entityKey] === text
  const onSubmit = () => {
    if (disabled) {
      return null
    }
    handleUpdate(entityKey, text, false)
    setIsOpen(false)
  }

  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <DialogTrigger asChild>
          <div
            onClick={() => setIsOpen(true)}
            className={cn(
              'flex h-8 w-full rounded-md border border-transparent bg-transparent px-2 py-1 text-sm transition-colors',
              'hover:outline-none hover:ring-1 hover:ring-ring hover:border-input hover:shadow-sm',
            )}
          >
            {data[entityKey]}
          </div>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update {entityKey}</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {entityKey}
              </Label>
              <Input
                className="col-span-3"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || !text || text === data[entityKey]}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
