import { mutate } from 'swr'
import { putFetcher } from './swr'
import useSWRMutation from 'swr/mutation'
import { useCallback } from 'react'

export const optimisticUpdateAvatars = (id, key, value) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/avatars'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? { ...item, [key]: value } : item,
      )
    },
    { revalidate: true },
  )
}

export const optimisticUpdateTemplates = (id, key, value, revalidate) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/templates'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? { ...item, [key]: value } : item,
      )
    },
    { revalidate },
  )
}

export const optimisticUpdateDrafts = (id, key, value, revalidate) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/drafts'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? draftCase(item, key, value, revalidate) : item,
      )
    },
    { revalidate },
  )
  // mutate(
  //   [`/drafts/${id}`],
  //   (data) => {
  //     if (!data) return null
  //     const newData = draftCase(data, key, value, revalidate)
  //     return { ...newData }
  //   },
  //   { revalidate },
  // )
}

export const optimisticUpdateDlcDrafts = (id, key, value, revalidate) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/dlc/drafts'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? draftCase(item, key, value, revalidate) : item,
      )
    },
    { revalidate },
  )
}

export const optimisticUpdateFrames = (id, key, value, revalidate) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/frames'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? { ...item, [key]: value } : item,
      )
    },
    { revalidate },
  )
}

const draftCase = (draft, key, value) => {
  if (key === 'removeTag') {
    draft.ext.tags = draft.ext.tags.filter((tag) => tag !== value)
  } else if (key === 'addTag') {
    if (!draft?.ext?.tags) {
      draft.ext.tags = [value]
    } else {
      draft.ext.tags.push(value)
    }
  } else if (key === 'removeLabel') {
    if (draft?.ext?.labels) {
      draft.ext.labels = draft.ext.labels.filter((label) => label !== value)
    }
  } else if (key === 'addLabel') {
    if (!draft?.ext?.labels) {
      draft.ext.labels = [value]
    } else {
      draft.ext.labels.push(value)
    }
  } else if (key === 'removeLang') {
    draft.ext.contentLangs = draft.ext.contentLangs.filter(
      (lang) => lang !== value,
    )
  } else if (key === 'addLang') {
    if (!draft?.ext?.contentLangs) {
      draft.ext.contentLangs = [value]
    } else {
      draft.ext.contentLangs.push(value)
    }
  } else if (key === 'level' || key === 'simpleLimit') {
    draft.ext[key] = Number(value)
  } else if (key === 'sku') {
    draft.ext[key] = value
  } else if (key === 'pro' || key === 'iOSPro') {
    const { cid, price } = value
    let layers = JSON.parse(draft.layers).map((layer) => {
      const index = layer.components.findIndex(
        (component) => component.id === cid,
      )
      if (index > -1) layer.components[index][key] = price
      return layer
    })
    draft.layers = JSON.stringify(layers)
  } else {
    draft[key] = value
  }
  return draft
}

export const optimisticUpdateTonings = (id, key, value, revalidate) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/tonings/template'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? { ...item, [key]: value } : item,
      )
    },
    { revalidate },
  )
}

export const optimisticUpdateDlcs = (id, key, value, revalidate) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/dlcs'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? dlcCase(item, key, value) : item,
      )
    },
    { revalidate },
  )
}

const dlcCase = (draft, key, value) => {
  if (key === 'removeTag') {
    draft.tags = draft.tags.filter((tag) => tag !== value)
  } else if (key === 'addTag') {
    if (!draft.tags) {
      draft.tags = [value]
    } else {
      draft.tags.push(value)
    }
  } else {
    draft[key] = value
  }
  return draft
}

export const optimisticUpdateLayerIcons = (id, key, value, revalidate) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/layericons'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? { ...item, [key]: value } : item,
      )
    },
    { revalidate },
  )
}

export const optimisticUpdatePublishers = (id, key, value, revalidate) => {
  mutate(
    (key) => {
      return Array.isArray(key) && key[0] === '/publishers'
    },
    (data) => {
      return data.map((item) =>
        item.id === id ? { ...item, [key]: value } : item,
      )
    },
    { revalidate },
  )
}

export const useUpdateAvatars = (avatarId) => {
  const { trigger, ...swr } = useSWRMutation(`/avatars/${avatarId}`, putFetcher)

  const handleUpdate = useCallback(
    (key, value) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdateAvatars(avatarId, key, value)
          return currentData
        },
      })
    },
    [trigger, avatarId],
  )

  return { handleUpdate, ...swr }
}

export const useUpdateTemplates = (id) => {
  const { trigger, ...swr } = useSWRMutation(`/templates/${id}`, putFetcher)

  const handleUpdate = useCallback(
    (key, value, revalidate) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdateTemplates(id, key, value, revalidate)
          return currentData
        },
      })
    },
    [trigger, id],
  )

  return { handleUpdate, ...swr }
}

export const useUpdateDrafts = (id) => {
  const { trigger, ...swr } = useSWRMutation(`/drafts/${id}`, putFetcher)

  const handleUpdate = useCallback(
    (key, value, revalidate) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdateDrafts(id, key, value, revalidate)
          return currentData
        },
      })
    },
    [trigger, id],
  )

  return { handleUpdate, ...swr }
}

export const useUpdateDlcDrafts = (id) => {
  const { trigger, ...swr } = useSWRMutation(`/dlc/drafts/${id}`, putFetcher)

  const handleUpdate = useCallback(
    (key, value, revalidate) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdateDlcDrafts(id, key, value, revalidate)
          return currentData
        },
      })
    },
    [trigger, id],
  )

  return { handleUpdate, ...swr }
}

export const useUpdateFrames = (id) => {
  const { trigger, ...swr } = useSWRMutation(`/frames/${id}`, putFetcher)

  const handleUpdate = useCallback(
    (key, value, revalidate) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdateFrames(id, key, value, revalidate)
          return currentData
        },
      })
    },
    [trigger, id],
  )

  return { handleUpdate, ...swr }
}

export const useUpdateTonings = (id) => {
  const { trigger, ...swr } = useSWRMutation(
    `/tonings/template/${id}`,
    putFetcher,
  )

  const handleUpdate = useCallback(
    (key, value, revalidate) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdateTonings(id, key, value, revalidate)
          return currentData
        },
      })
    },
    [trigger, id],
  )

  return { handleUpdate, ...swr }
}

export const useUpdateDlcs = (id) => {
  const { trigger, ...swr } = useSWRMutation(`/dlcs/${id}`, putFetcher)

  const handleUpdate = useCallback(
    (key, value, revalidate) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdateDlcs(id, key, value, revalidate)
          return currentData
        },
      })
    },
    [trigger, id],
  )

  return { handleUpdate, ...swr }
}

export const useUpdateLayerIcons = (id) => {
  const { trigger, ...swr } = useSWRMutation(`/layericons/${id}`, putFetcher)

  const handleUpdate = useCallback(
    (key, value, revalidate) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdateLayerIcons(id, key, value, revalidate)
          return currentData
        },
      })
    },
    [trigger, id],
  )

  return { handleUpdate, ...swr }
}

export const useUpdatePublisher = (id) => {
  const { trigger, ...swr } = useSWRMutation(`/publishers/${id}`, putFetcher)

  const handleUpdate = useCallback(
    (key, value, revalidate) => {
      const body = { key, value }
      trigger(body, {
        optimisticData: (currentData) => {
          optimisticUpdatePublishers(id, key, value, revalidate)
          return currentData
        },
      })
    },
    [trigger, id],
  )

  return { handleUpdate, ...swr }
}
