import React, { useState } from 'react'
import { z } from 'zod'
import useSWRMutation from 'swr/mutation'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { PlusIcon } from '@radix-ui/react-icons'
import Loader from '@/components/ui/Loader'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { postFetcher, useAuthStore } from 'api/swr'
import {
  MyFormInput,
  MyFormSelect,
  MyFromTextarea,
} from 'components/ui/MyFormField'

const CreatePage = ({ mutate }) => {
  const [open, setOpen] = useState(false)
  const face = useAuthStore((state) => state.auth.face)
  const [defaultValues] = useState({
    name: '',
    intro: '',
    ow: 2048,
    oh: 2048,
    character: '1',
  })
  const handleClose = () => {
    setOpen(false)
  }
  const { trigger, isMutating, error } = useSWRMutation(
    '/templates',
    postFetcher,
  )

  const onSubmit = (data) => {
    if (isMutating) return
    const uploadData = { ...data, publisher: face.nick_name, author: face.id }
    trigger(uploadData, {
      onSuccess: () => {
        handleClose()
        mutate()
      },
    })
  }

  const infoFormSchema = z.object({
    name: z
      .string()
      .min(2, {
        message: 'name must be at least 2 characters.',
      })
      .max(30, {
        message: 'name must not be longer than 30 characters.',
      }),
    intro: z.string().max(640).min(4),
    ow: z.preprocess((val) => Number(val), z.number().min(512).max(3072)),
    oh: z.preprocess((val) => Number(val), z.number().min(512).max(3072)),
    character: z.preprocess((val) => Number(val), z.number().min(1).max(2)),
  })

  const form = useForm({
    resolver: zodResolver(infoFormSchema),
    defaultValues,
  })
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <PlusIcon className="mr-2" />
          Create template
        </Button>
      </DialogTrigger>
      <DialogContent className="dialog-content">
        <DialogHeader>
          <DialogTitle>Create template</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <MyFormInput
              name="name"
              form={form}
              label="Name"
              placeholder={'template name'}
            />
            <MyFromTextarea
              name="intro"
              form={form}
              label="Description"
              placeholder={'template description'}
            />
            <MyFormInput
              name="ow"
              type="number"
              form={form}
              label="ow"
              placeholder={'template width'}
            />
            <MyFormInput
              name="oh"
              type="number"
              form={form}
              label="oh"
              placeholder={'template height'}
            />
            <MyFormSelect
              name="character"
              form={form}
              label="character"
              items={[
                { code: '1', name: '1' },
                { code: '2', name: '2' },
              ]}
              placeholder={'character number'}
            />
            {isMutating ? (
              <Loader>Updating...</Loader>
            ) : (
              <Button type="submit">Update</Button>
            )}
            {error && <div className="text-red-500">{error.message}</div>}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default CreatePage
