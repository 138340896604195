import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { ImageIcon, StackIcon } from '@radix-ui/react-icons'
import { useEditorStore } from 'CanvasCore/uiSlice'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { CptCard } from '../ui'

// import { Badge } from '@/components/ui/badge'
import DataItemActions from '../data-item-actions'

const Cpt = ({ cpt, cpts }) => {
  const { id } = cpt

  const childs = useMemo(() => cpts?.filter((c) => c.pid === id), [id, cpts])

  if (cpt.pid) return null
  if (childs?.length > 0) {
    return <CptStack cpt={cpt} cpts={cpts} childs={childs} />
  }

  return <CptNormal cpt={cpt} cpts={cpts} />
}

const CptNormal = ({ cpt, cpts, childs }) => {
  const { tid } = useParams()
  const { id, cover } = cpt

  const selected = useEditorStore((state) =>
    state.editors[tid]?.drawers?.some((d) => d.cid === id),
  )
  const selectCpt = useEditorStore((state) => state.selectCpt)
  const deselectCpt = useEditorStore((state) => state.deselectCpt)

  const { data: layers } = useSWR(['/layers', { tid }])

  const handleClick = () => {
    let layer = layers?.find((layer) => layer.id === cpt.lid)
    if (!layer) layer = { id: 'draft', max: 1 }
    if (selected) {
      deselectCpt(tid, cpt.id)
    } else {
      selectCpt(tid, layer, cpt)
    }
  }

  return (
    <CptCard
      onClick={handleClick}
      className={cn(selected && 'border-2 border-primary')}
      children2={
        <div className="mt-2 flex flex-col items-center">
          <DataItemActions cpt={cpt} cpts={cpts} />
        </div>
      }
    >
      <div className="w-full h-full relative">
        <img
          className="w-full h-full aspect-square object-contain rounded-xl"
          src={`https://img.zthd.io/an1/ats/${cover ?? id}`}
          alt=""
        />
        {cpt.cover && (
          <ImageIcon className="w-4 h-4 text-primary absolute top-1 left-1" />
        )}
        {/* <Badge variant="outline" className="absolute bottom-1 left-1 px-1">
          {cpt.pro}
        </Badge>
        <Badge variant="outline" className="absolute bottom-1 right-1 px-1">
          {cpt.iOSPro}
        </Badge> */}
      </div>
    </CptCard>
  )
}

const CptStack = ({ cpt, cpts, childs }) => {
  const { tid } = useParams()
  const { id, cover } = cpt

  const selected = useEditorStore((state) =>
    state.editors[tid]?.drawers?.some((d) => d.cid === id || d.pid === id),
  )

  return (
    <Popover>
      <PopoverTrigger asChild>
        <CptCard className={cn(selected && 'border-2 border-primary')}>
          <div className="w-full h-full relative">
            <img
              className="w-full h-full aspect-square object-contain rounded-xl"
              src={`https://img.zthd.io/an1/ats/${cover ?? id}`}
              alt=""
            />
            <div className="absolute top-1 right-2 flex items-center text-primary gap-0.5 bg-gray-100 rounded-md px-1 ">
              <StackIcon className="w-4 h-4 " />
              {childs.length + 1}
            </div>
          </div>
        </CptCard>
      </PopoverTrigger>
      <PopoverContent className="max-h-[80vh] min-w-[40vw] xl:min-w-[30vw]">
        <div className="overflow-y-auto">
          <div className="grid grid-cols-6 lg:grid-cols-5 xl:grid-cols-5 gap-2 p-1 ">
            <CptNormal cpt={cpt} cpts={cpts} childs={childs} />
            {childs.map((child) => (
              <CptNormal key={child.id} cpt={child} cpts={cpts} />
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default Cpt
