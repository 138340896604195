import { cn } from '@/lib/utils'
import React from 'react'

const Title = React.forwardRef(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn('font-semibold leading-none tracking-tight', className)}
    {...props}
  />
))

Title.displayName = 'Title'

export default Title

export const H2 = React.forwardRef(({ className, ...props }, ref) => (
  <h2
    ref={ref}
    className={cn('text-2xl font-semibold tracking-tight', className)}
    {...props}
  />
))

H2.displayName = 'H2'

export const H3 = React.forwardRef(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn('font-medium leading-none', className)}
    {...props}
  />
))

H3.displayName = 'H3'

export const Description = React.forwardRef(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
))

Description.displayName = 'Description'
