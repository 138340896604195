import MyLink from 'components/ui/MyLink'
import React from 'react'
import { Badge } from '../../@/components/ui/badge'
import { DataTableRowActions, DataTableRowText } from './data-table-row-action'
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons'
export const columns = [
  {
    header: '#',
    cell: ({ row }) => row.index + 1,
  },
  { accessorKey: 'id' },
  {
    header: 'User name',
    accessorKey: 'username',
  },
  {
    header: 'Nick name',
    accessorKey: 'nick_name',
    cell: ({ row }) => <DataTableRowText row={row} entityKey={'nick_name'} />,
  },
  {
    header: 'Template count',
    accessorKey: 'template_count',
    cell: ({ row }) => (
      <MyLink to={`${row.original.id}`}>
        {row.original.template_count}xxxx
      </MyLink>
    ),
  },
  {
    header: 'Role',
    accessorKey: 'roles',
    cell: ({ row }) => (
      <>
        {row.original?.roles?.map((role, index) => (
          <Badge variant="outline" key={index}>
            <small>{role}</small>
          </Badge>
        ))}
      </>
    ),
  },
  {
    header: 'State',
    accessorKey: 'state',
    cell: ({ row }) => (
      <>
        {row.original?.state === 0 ? (
          <CheckCircledIcon color="green" />
        ) : (
          <CrossCircledIcon color="red" />
        )}
      </>
    ),
  },
  {
    header: 'Actions',
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
