import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import './styles/globals.css' // 引入全局CSS

import { fetcher } from 'api/swr'
import { SWRConfig } from 'swr'
import App from './App'
import './i18n'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <SWRConfig
    value={{
      fetcher: fetcher,
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      dedupingInterval: 60000,
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SWRConfig>,
)

reportWebVitals()
