import React from 'react'
import { Button } from '@/components/ui/button'
import Dropdown from 'components/ui/Selector'
import { ReloadIcon } from '@radix-ui/react-icons'
import { useForm } from './index'

const RangeOptions = [
  {
    code: 7,
    name: 'Within 7 days',
  },
  {
    code: 30,
    name: 'Within 30 days',
  },
]

const DataTableToolbar = ({ mutate }) => {
  const { range, updateRange } = useForm()
  return (
    <div className="flex items-center w-full mt-2">
      <Dropdown
        datas={RangeOptions}
        showLabel
        labelName="range"
        value={range}
        update={updateRange}
      />
      <Button variant="outline" className="mx-2" onClick={() => mutate()}>
        <ReloadIcon className="h-4 w-4 mr-2" />
        Refetch
      </Button>
    </div>
  )
}

export default DataTableToolbar
