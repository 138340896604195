import React, { useEffect, useMemo, useState } from 'react'

import { useUI } from 'Editor'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { LayersIcon } from '@radix-ui/react-icons'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import Loader from '@/components/ui/Loader'
import { cn } from '@/lib/utils'
import MyDataTable from 'components/ui/my-data-table'
import { DataTableRowActions, DataTableRowName } from './data-table-row-actions'
import DataTableToolbar from './data-table-toolbar'

export const columns = [
  {
    accessorKey: 'name',
    header: <div></div>,
    cell: ({ row }) => <DataTableRowName row={row} />,
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'mark',
    header: <div className="flex items-center">Color count</div>,
    cell: ({ row }) => {
      const toning = row.original
      return (
        <div className="flex items-center gap-2">{toning.colors?.length}</div>
      )
    },
  },
  {
    id: 'linkedLayerCount',
    header: <div className="flex items-center ">Linked layers</div>,
    cell: ({ row }) => {
      const toning = row.original
      return (
        <div
          className={cn(
            'flex  items-center gap-2',
            toning.layers?.length <= 0 ? 'text-destructive' : '',
          )}
        >
          <LayersIcon className={cn('w-5 h-5')} />
          {toning.layers?.length}
        </div>
      )
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]

const ToningsPalnel = () => {
  const { tid } = useParams()

  const {
    data: tonings,
    isLoading,
    isValidating,
  } = useSWR(['/tonings', { tid }])
  const { data: layers } = useSWR(['/layers', { tid }])
  const toningId = useUI((state) => state.toningId)
  const toggleToning = useUI((state) => state.toggleToning)

  const [rowSelection, setRowSelection] = useState(
    toningId ? { [toningId]: true } : {},
  )

  const data = useMemo(() => {
    return (
      tonings?.map((toning) => ({
        ...toning,
        layers: layers?.filter((layer) => layer.toning === toning.id),
      })) ?? []
    )
  }, [tonings, layers])

  useEffect(() => {
    if (toningId) {
      setRowSelection({ [toningId]: true })
    }
  }, [toningId])

  useEffect(() => {
    const firstKey = Object.keys(rowSelection)[0]
    if (firstKey) {
      toggleToning(firstKey)
    }
  }, [rowSelection, toggleToning])

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    getRowId: (row) => row.id,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="w-full space-y-4">
      <DataTableToolbar table={table} tonings={tonings} />
      <div className="rounded-md border">
        {(isLoading || isValidating) && (
          <div className="mt-2 ml-2">
            <Loader>Load tonings...</Loader>
          </div>
        )}
        <MyDataTable table={table} />
      </div>
    </div>
  )
}

export default ToningsPalnel
