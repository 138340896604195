import React from 'react'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

const Alert = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className="flex items-center gap-2 px-2 w-full text-destructive mt-1 text-sm"
  >
    <ExclamationTriangleIcon className="h-4 w-4" />
    <span className="truncate" {...props} />
  </div>
))

Alert.displayName = 'Alert'

export default Alert
