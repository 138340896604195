import { toast } from '@/components/ui/use-toast'
import React from 'react'

function CopyedCode({ children, copyed }) {
  const clickToCopy = () => {
    navigator.clipboard.writeText(copyed)
    toast({
      title: 'Copied',
      description: copyed,
    })
  }

  return <span onClick={() => clickToCopy()}>{children}</span>
}

export default CopyedCode
