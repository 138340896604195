import React, { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'
import { mutate } from 'swr'
import { putFetcher } from 'api/swr'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
} from '@/components/ui/dialog'
import Divider from 'components/ui/Divider'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'
import Skeleton from '@/components/ui/skeleton'
import { CptCard } from '../ui'
import Loader from '@/components/ui/Loader'
import { DialogDescription } from '@radix-ui/react-dialog'
import { StackIcon } from '@radix-ui/react-icons'

function BindStyle({ cpt, cpts }) {
  const { tid } = useParams()
  const [open, setOpen] = useState(false)
  const childs = useMemo(
    () => cpts?.filter((c) => c.pid === cpt.id),
    [cpt.id, cpts],
  )

  const parents = useMemo(
    () => cpts?.filter((c) => !c.pid && c.id !== cpt.id),
    [cpts, cpt.id],
  )

  const parent = useMemo(
    () => cpts?.find((c) => c.id === cpt.pid),
    [cpt.pid, cpts],
  )

  const { trigger, isMutating } = useSWRMutation(
    `/components/${cpt.id}`,
    putFetcher,
  )

  const handleLinkStyle = (cid) => {
    if (isMutating) return false
    trigger(
      { tid, key: 'pid', value: cid },
      {
        onSuccess: () => {
          setOpen(false)
          mutate(['/components', { tid }])
        },
      },
    )
  }

  //没可选父
  if (parents.length === 0) {
    return null
  }
  //有子
  if (childs.length > 0) {
    return null
  }

  if (cpt.pid) {
    return (
      <DropdownMenuItem onClick={() => handleLinkStyle('')}>
        <StackIcon className="w-4 h-4 mr-2" /> unlink style
      </DropdownMenuItem>
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <StackIcon className="w-4 h-4 mr-2" />
          Link style
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>Link style</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="flex flex-col">
          <div className="flex gap-4">
            <div>
              curr cpt:
              <img
                alt="cpt original"
                src={`https://img.zthd.io/an1/ats/${cpt.cover ?? cpt.id}`}
                className="h-24 w-24 object-cover transition-all hover:scale-105 aspect-square border hover:border-primary rounded-xl"
              />
            </div>
            <div>
              curr parent:
              {parent ? (
                <img
                  key={parent?.cover ?? parent?.id}
                  src={`https://img.zthd.io/an1/ats/${
                    parent?.cover ?? parent?.id
                  }`}
                  alt="cpt curr layer"
                  className="h-24 w-24 object-cover transition-all hover:scale-105 aspect-square border hover:border-primary rounded-xl"
                />
              ) : (
                <Skeleton className="flex items-center justify-center h-24 w-24 object-cover transition-all hover:scale-105 aspect-square border hover:border-primary rounded-xl">
                  No style
                </Skeleton>
              )}
            </div>
          </div>
          <Divider className="mt-1" />
          <div className="flex items-center gap-2">
            <h3 className="text-xl font-bold">Parent cpts</h3>{' '}
            <span>(click to link style)</span>
            {isMutating && <Loader />}
          </div>
          <div className="flex-1 overflow-y-auto max-h-[50vh]">
            <div className="grid sm:grid-cols-4 md:grid-cols-4 gap-2 p-1 overflow-y-auto">
              {parents?.map((cpt) => (
                <CptCard key={cpt.id} onClick={() => handleLinkStyle(cpt.id)}>
                  <img
                    className="w-full h-full aspect-square object-contain rounded-xl"
                    src={`https://img.zthd.io/an1/ats/${cpt.cover ?? cpt.id}`}
                    alt="cpt cover"
                  />
                </CptCard>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default BindStyle
