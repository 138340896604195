import { Button } from '@/components/ui/button'
import Loader from '@/components/ui/Loader'
import Divider from 'components/ui/Divider'
import EmptyDatas from 'components/ui/EmptyDatas'
import React from 'react'
import useSWR from 'swr'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import DropdownOrder from './DropdownOrder'
import Item from './Item'

import MyPagination from 'components/ui/MyPagination'
import Create from './Create'

export const useForm = create(
  persist(
    (set) => ({
      order: 'updateTime',
      after: null,
      afters: [],
      setOrder: (order) => set({ order, after: null, afters: [] }),
      goAfter: (nextAfter) =>
        set((state) => {
          if (nextAfter === null) {
            return { after: null }
          }
          const index = state.afters.findIndex((a) => a === nextAfter)
          if (index === -1) {
            return {
              after: nextAfter,
              afters: [...state.afters, nextAfter],
            }
          } else {
            return { after: nextAfter }
          }
        }),
    }),
    {
      name: 'webimgs-form',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

const WebImgs = () => {
  const goAfter = useForm((state) => state.goAfter)
  const afters = useForm((state) => state.afters)
  const after = useForm((state) => state.after)

  const params = useForm((state) => {
    const order = state.order
    const after = state.after
    return after ? { order, after } : { order }
  })
  const { data, isLoading, isValidating, mutate } = useSWR(['/webimgs', params])

  const nextAfter = data?.[data.length - 1]?.id
  return (
    <>
      <div className="flex items-center gap-4 py-2">
        <DropdownOrder />
        <Button variant="outline" onClick={() => mutate()}>
          Refetch
        </Button>
        {(isLoading || isValidating) && <Loader />}
        <Create mutateCurrList={mutate} />
      </div>
      <Divider />

      <MyPagination
        className="py-1"
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
      {data?.length <= 0 && <EmptyDatas />}
      <div className="grid grid-cols-9 lg:grid-cols-6 gap-2">
        {data?.map((data, index) => (
          <Item id={data.id} key={data.id} index={index} data={data} />
        ))}
      </div>
      <MyPagination
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
    </>
  )
}

export default WebImgs
