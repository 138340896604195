import React, { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import { mutate } from 'swr'
import { postFetcher } from 'api/swr'
import { ReloadIcon } from '@radix-ui/react-icons'
import { PlusIcon } from '@radix-ui/react-icons'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

const DataTableToolbar = ({ mutate, path }) => {
  return (
    <div className="flex mb-2 justify-between">
      <Button variant="outline" onClick={() => mutate()}>
        <ReloadIcon className="h-4 w-4 mr-2" />
        {path} Refetch
      </Button>
      <DataTableRowCreate path={path} />
    </div>
  )
}

const DataTableRowCreate = ({ path }) => {
  const [isOpen, setIsOpen] = useState()
  const [tid, setTid] = useState('')
  const { trigger, isMutating } = useSWRMutation(path, postFetcher)

  const onSubmit = async () => {
    trigger(
      { tid: tid },
      {
        onSuccess: () => {
          mutate([path])
          setIsOpen(false)
        },
      },
    )
  }
  return (
    <div className="max-w-[500px] min-w-[130px] flex flex-col gap-2">
      <Dialog
        open={isOpen}
        onOpenChange={(isOpen) => {
          setTid('')
          setIsOpen(isOpen)
        }}
      >
        <DialogTrigger asChild>
          <Button className="h-8 lg:flex">
            <PlusIcon className="h-4 w-4 mr-2" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
              Create Ab Test
            </span>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create Ab Test</DialogTitle>
          </DialogHeader>
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="tid">tid</Label>
            <Input
              id="tid"
              type="text"
              value={tid}
              onChange={(e) => setTid(e.target.value)}
            />
          </div>
          <DialogFooter>
            <Button
              disabled={isMutating || tid.length === 0}
              onClick={onSubmit}
            >
              Submit{isMutating ? '...' : null}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DataTableToolbar
