import React from 'react'
import { cn } from '@/lib/utils'

const Divider = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-muted', className)}
    {...props}
  />
))

Divider.displayName = 'Divider'

export default Divider
