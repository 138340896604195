import React, { useMemo } from 'react'
import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { delUrlFetcher } from 'api/swr'
import { useParams } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { ColorCard } from 'components/ui/ColorCard'
import { toast } from '@/components/ui/use-toast'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  CircleBackslashIcon,
  CopyIcon,
  DotsHorizontalIcon,
  Pencil1Icon,
  PlusIcon,
  TrashIcon,
} from '@radix-ui/react-icons'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { useSelectedColor, useUI } from 'Editor'
import Alert from 'components/ui/Alert'
import Title from 'components/ui/Title'
import { CreateColorPanel } from '../CreateColorPanel'
import { DuplicateColorPanel } from '../DuplicateColorPanel'
import { UpdateColorPanel } from '../UpdateColorPanel'
import LinearColor from './LinearColor'

export const PICKER_COLOR = 'pickerColor'
const CREATE_COLOR = 'createColor'
const UPDATE_COLOR = 'updateColor'
const DUPLICATE_COLOR = 'duplicateColor'

const PickerColor = ({ tonings }) => {
  const panel = useUI((state) => state.panel)

  if (panel === CREATE_COLOR) {
    return <CreateColorPanel />
  }

  if (panel === UPDATE_COLOR) {
    return <UpdateColorPanel />
  }

  if (panel === DUPLICATE_COLOR) {
    return <DuplicateColorPanel />
  }

  return <SelectColorPanel tonings={tonings} />
}

const SelectColorPanel = ({ tonings }) => {
  const { tid } = useParams()
  const toningId = useUI((state) => state.toningId)
  const toning = useMemo(
    () => tonings?.find((t) => t.id === toningId),
    [tonings, toningId],
  )

  const togglePanel = useUI((state) => state.togglePanel)

  const selectedColor = useSelectedColor(tid, 'default')

  const hasCpt = useEditorStore((state) =>
    state.editors[tid]?.drawers?.some((d) => d.t === toningId),
  )

  const handleDuplicate = () => {
    togglePanel(DUPLICATE_COLOR)
  }

  const { trigger, isMutating } = useSWRMutation(
    `/tonings/${toningId}/colors`,
    delUrlFetcher,
    {
      onSuccess: () => {
        mutate([`/tonings`, { tid }])
        toast({
          title: 'Toning color Deleted',
          description: 'The Toning color has been successfully deleted.',
        })
      },
    },
  )

  //TODO: confirm
  const handleDelete = (e) => {
    e.preventDefault()
    trigger({ tid, colorId: selectedColor.id })
  }

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="flex items-center">
        <Title className="ml-1">Select color</Title>
        <div className="flex items-center ml-auto gap-2">
          {!selectedColor ? (
            <Button
              variant="outline"
              size="sm"
              onClick={() => navigator.clipboard.writeText(toning?.id)}
            >
              <CopyIcon className="mr-2 w-4 h-4" /> Copy toning id
            </Button>
          ) : (
            <>
              <Button
                variant="outline"
                size="sm"
                onClick={() => togglePanel(UPDATE_COLOR)}
              >
                <Pencil1Icon className="mr-2 w-4 h-4" /> Edit
              </Button>
              <Button variant="outline" size="sm" onClick={handleDuplicate}>
                <CopyIcon className="mr-2 w-4 h-4" /> Duplicate
              </Button>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                  >
                    <DotsHorizontalIcon className="h-4 w-4" />
                    <span className="sr-only">Open menu</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-[160px]">
                  <DropdownMenuLabel>Actions</DropdownMenuLabel>
                  <DropdownMenuItem
                    onClick={() =>
                      navigator.clipboard.writeText(selectedColor?.id)
                    }
                  >
                    <CopyIcon className="mr-2 h-4 w-4" /> Copy color id
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    className="text-destructive focus:bg-destructive focus:text-destructive-foreground"
                    onSelect={handleDelete}
                  >
                    <TrashIcon className="mr-2 h-4 w-4" />
                    Delete {isMutating ? '...' : null}
                    <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          )}
        </div>
      </div>
      {!hasCpt && (
        <Alert>If no components are selected, color won&#39;t apply.</Alert>
      )}

      <div className="grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 xl:grid-cols-9 grid-cols-9 gap-2 p-1">
        <CleanColorButton />
        <CreateButton />
        {toning?.colors?.map((c) => (
          <LinearColor key={c.id} color={c} />
        ))}
      </div>
    </div>
  )
}

const CleanColorButton = () => {
  const { tid } = useParams()
  const toningId = useUI((state) => state.toningId)
  const deselectColor = useEditorStore((state) => state.deselectColor)

  const handleClick = () => {
    deselectColor(tid, toningId)
  }
  return (
    <ColorCard onClick={handleClick}>
      <CircleBackslashIcon className="w-3/5 h-3/5 text-gray-400 group-hover:text-primary" />
    </ColorCard>
  )
}

const CreateButton = (props) => {
  const togglePanel = useUI((state) => state.togglePanel)

  return (
    <ColorCard onClick={() => togglePanel(CREATE_COLOR)} {...props}>
      <PlusIcon className="w-3/5 h-3/5 text-gray-400 group-hover:text-primary" />
    </ColorCard>
  )
}

export default PickerColor
