import { Card, CardContent } from '@/components/ui/card'
import Loader from '@/components/ui/Loader'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import EmptyDatas from 'components/ui/EmptyDatas'
import Table from 'components/ui/my-data-table'
import React from 'react'
import useSWR from 'swr'
import { columns } from './data-table-column'
import DataTableToolbar from './data-table-toolbar'

const Index = () => {
  return (
    <>
      <AiAbTest />
      <PreviewAbTest />
    </>
  )
}

const AiAbTest = () => {
  const path = '/ab-tests/ai'
  const { data, isLoading, isValidating, mutate } = useSWR([path])

  const table = useReactTable({
    data: data,
    columns: columns(path),
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  if (isLoading || isValidating) return <Loader>Load tests...</Loader>
  if (!data) return <EmptyDatas />

  return (
    <div>
      <DataTableToolbar mutate={mutate} path={path} />
      <Card>
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </div>
  )
}

const PreviewAbTest = () => {
  const path = '/ab-tests/preview'
  const { data, isLoading, isValidating, mutate } = useSWR([path])

  const table = useReactTable({
    data: data,
    columns: columns(path),
    enableRowSelection: false,
    getRowId: (row) => row.id,
    getCoreRowModel: getCoreRowModel(),
  })

  if (isLoading || isValidating) return <Loader>Load tests...</Loader>
  if (!data) return <EmptyDatas />

  return (
    <div>
      <DataTableToolbar mutate={mutate} path={path} />
      <Card>
        <CardContent>
          <Table table={table} />
        </CardContent>
      </Card>
    </div>
  )
}

export default Index
