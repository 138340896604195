import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Form } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { zodResolver } from '@hookform/resolvers/zod'
import { LapTimerIcon, MinusCircledIcon } from '@radix-ui/react-icons'
import { postFetcher, putFetcher } from 'api/swr'
import Navbar from 'components/Navbar'
import { MyFormDatePicker, MyFromTimePicker } from 'components/ui/MyFormField'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import useSWRMutation from 'swr/mutation'
import { z } from 'zod'
import { useError } from './RightPanel'
import dayjs from 'dayjs'

const FormSchema = z.object({
  date: z.date({
    required_error: 'A date of birth is required.',
  }),
  time: z.string({
    required_error: 'A time is required.',
  }),
})

const Nav = ({ draft }) => {
  //TODO: add Schedule timer to online
  return (
    <Navbar name="Neku workspace">
      <RejectDialog draft={draft} />
      <ScheduleTimerDialog draft={draft} />
    </Navbar>
  )
}

const ScheduleTimerDialog = ({ draft }) => {
  const [open, setOpen] = useState(false)
  const form = useForm({
    resolver: zodResolver(FormSchema),
  })

  const { trigger, isMutating } = useSWRMutation(
    `/publish/online`,
    postFetcher,
    {
      onSuccess: () => {
        setOpen(false)
        toast({
          title: 'Review submitted',
          description: 'Your review has been submitted successfully',
        })
      },
      onError: (error) => {
        console.log(error)
        toast({
          variant: 'destructive',
          title: 'Failed to submit review',
          description: error.message,
        })
      },
    },
  )
  const onSubmit = (values) => {
    if (isMutating) return
    const data = { tid: draft.id }
    if (draft.type === 'dlc') {
      data.dlc = draft.id
      data.tid = draft.tid
    }
    if (values) {
      const date = dayjs(values.date).format('YYYY-MM-DD')
      const onlineTime = new Date(`${date} ${values.time}:00`).getTime()
      data.onlineTime = onlineTime
    }
    trigger(data)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <LapTimerIcon className="mr-2 h-4 w-4" />
          <span>Setting timering</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>
          <DialogHeader>Schedule Timer</DialogHeader>
          <DialogDescription>
            Select date and time to schedule template&#39;s launch.
          </DialogDescription>
        </DialogTitle>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <MyFormDatePicker form={form} name="date" />
            <MyFromTimePicker form={form} name="time" />
            <Button type="submit">Schedule{isMutating && 'ing...'}</Button>
          </form>
        </Form>
        <div>
          <Button onClick={() => onSubmit()}>
            Online now{isMutating && 'ing...'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const RejectDialog = ({ draft }) => {
  const [reason, setReason] = useState('')
  const [open, setOpen] = useState(false)
  const errors = useError(draft)
  const path = draft.type === 'template' ? '/drafts' : '/dlc/drafts'
  const { trigger, isMutating } = useSWRMutation(
    `${path}/${draft.id}`,
    putFetcher,
    {
      onSuccess: () => {
        setOpen(false)
      },
    },
  )

  const handleReject = () => {
    const data = JSON.stringify([
      ...errors,
      reason && { label: 'reason', message: reason },
    ])
    trigger({ key: 'reject', value: { errors: data } })
  }
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive">
          <MinusCircledIcon className="mr-2 h-4 w-4 -rotate-45" />
          <span>Reject</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>
          <DialogHeader>Reject</DialogHeader>
          <DialogDescription>
            Are you sure you want to reject this draft?
          </DialogDescription>
        </DialogTitle>
        <ul className="list-disc px-4">
          {errors?.map((error, idx) => (
            <li key={idx}>
              {error.label} - {error.message}
            </li>
          ))}
        </ul>
        <Input
          placeholder="Reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <DialogFooter>
          <Button variant="destructive" onClick={handleReject}>
            Reject{isMutating && 'ing...'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default Nav
