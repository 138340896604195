import { Button } from '@/components/ui/button'
import Updating from '@/components/ui/Updating'
import {
  Component1Icon,
  Component2Icon,
  DownloadIcon,
  EraserIcon,
  LayersIcon,
  MagicWandIcon,
  ResetIcon,
  UploadIcon,
} from '@radix-ui/react-icons'
import { postFetcher } from 'api/swr'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { gradient2Toning } from 'CanvasCore/utils'
import { useUI } from 'Editor'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { Random } from './Random'

function downloadURI(uri, name) {
  var link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const Overlay = ({ drawRef, children }) => {
  const { tid } = useParams()
  const stage = useEditorStore((state) => state.editors[tid]?.stage)
  const { trigger, isMutating } = useSWRMutation('/avatars', postFetcher, {
    onSuccess: () => {},
  })

  const handleUpload = () => {
    if (drawRef.current) {
      const avatar = drawRef.current.toDataURL({
        mimeType: 'image/png',
        pixelRatio: 512 / stage.h,
        quality: 1,
      })

      const drawers = useEditorStore.getState().editors[tid]?.drawers
      const tonings = useEditorStore.getState().editors[tid]?.tonings

      const drawData = { canvas: { w: 512, h: 512 }, drawers, tonings: {} }

      if (tonings) {
        const _tonings = Object.keys(tonings).reduce((acc, key) => {
          const gradient = tonings[key]?.default?.color
          if (gradient) {
            acc[key] = {
              ...tonings[key],
              default: {
                ...tonings[key].default,
                toning: gradient2Toning(gradient),
              },
            }
            delete acc[key].default.color
          }
          return acc
        }, {})

        drawData.tonings = _tonings
        console.log(_tonings)
      }

      trigger({ avatar, tid, drawData })

      // we also can save uri as file
      // but in the demo on Konva website it will not work
      // because of iframe restrictions
      // but feel free to use it in your apps:
    }
  }

  const handleExport = () => {
    if (drawRef.current) {
      const avatar = drawRef.current.toDataURL({
        mimeType: 'image/png',
        pixelRatio: 1024 / stage.h,
        quality: 1,
      })
      // we also can save uri as file
      // but in the demo on Konva website it will not work
      // because of iframe restrictions
      // but feel free to use it in your apps:
      downloadURI(avatar, 'avatar.png')
    }
  }

  return (
    <>
      <Info />
      <div className="absolute top-0 right-0 flex items-center gap-2">
        <Button variant="outline" size="icon" onClick={handleExport}>
          <DownloadIcon className="w-4 h-4" />
        </Button>

        <Button variant="outline" onClick={handleUpload} disabled={isMutating}>
          {isMutating ? (
            <Updating />
          ) : (
            <>
              <UploadIcon className="w-4 h-4 mr-2" />
              Avatar
            </>
          )}
        </Button>
      </div>
      <div className="absolute bottom-0 left-0 w-full flex items-center gap-2">
        <ToggleButton />
        <div className="ml-auto flex items-center gap-2">
          <ResetButton />
          <Random />
        </div>
      </div>
      {children}
    </>
  )
}

const ResetButton = () => {
  const { tid } = useParams()
  const resetCanvas = useEditorStore((state) => state.resetCanvas)
  return (
    <Button variant="outline" size="icon" onClick={() => resetCanvas(tid)}>
      <EraserIcon className="h-4 w-4" />
    </Button>
  )
}

const Info = () => {
  const { tid } = useParams()
  const { data: cpts } = useSWR(['/components', { tid }])
  const drawerCptLength = useEditorStore(
    (state) => state.editors[tid]?.drawers?.length,
  )
  const aiCpts = cpts?.filter((cpt) => cpt.ai === 1) || []
  const aiCptCount = useEditorStore(
    (state) =>
      state.editors[tid]?.drawers?.filter((drawer) => drawer.ai === 1)?.length,
  )

  const { data: layers } = useSWR(['/layers', { tid }])
  const drawerLayerCount = useEditorStore(
    (state) =>
      Array.from(new Set(state.editors[tid]?.drawers?.map((d) => d.lid)))
        .length,
  )
  return (
    <div className="absolute top-0 left-0 flex flex-col">
      <div className="flex items-center">
        <Component1Icon className="w-4 h-4 mr-2" />
        {drawerCptLength || 0}/{cpts?.length || 0}
      </div>
      <div className="flex items-center">
        <Component2Icon className="w-4 h-4 mr-2" />
        {aiCptCount || 0}/{aiCpts.length || 0}
      </div>
      <div className="flex items-center">
        <LayersIcon className="w-4 h-4 mr-2" /> {drawerLayerCount || 0}/
        {layers?.length || 0}
      </div>
    </div>
  )
}

const ToggleButton = () => {
  const currTab = useUI((state) => state.currTab)
  const toggleTab = useUI((state) => state.toggleTab)
  if (currTab === 'tonings')
    return (
      <Button variant="outline" size="icon" onClick={() => toggleTab('layers')}>
        <ResetIcon className="h-4 w-4" />
      </Button>
    )
  return (
    <Button variant="outline" size="icon" onClick={() => toggleTab('tonings')}>
      <MagicWandIcon className="h-4 w-4" />
    </Button>
  )
}

export default Overlay
