import React, { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { create } from 'zustand'
import { columns } from './data-table-columns'
import DataTableToolbar from './data-table-toolbar'

import { useUI } from 'Editor'
import MyDataTable from 'components/ui/my-data-table'

export const useForm = create((set) => ({
  data: [],
  sorting: [],
  setSorting: (updateFn) =>
    set((state) => ({
      sorting: updateFn(state.sorting),
    })),
  setData: (data) => set({ data }),
}))

const Layers = () => {
  const { tid } = useParams()

  const { data: layers } = useSWR(['/layers', { tid }])
  const { data: cpts } = useSWR(['/components', { tid }])
  const lid = useUI((state) => state.lid)
  const toggleLayer = useUI((state) => state.toggleLayer)

  const { sorting, setSorting, setData } = useForm((state) => state)

  const [rowSelection, setRowSelection] = useState({ [lid]: true })

  const data = useMemo(() => {
    let list =
      layers?.map((layer) => ({
        ...layer,
        cptCount: cpts?.filter((cpt) => cpt.lid === layer.id)?.length ?? 0,
      })) ?? []
    setData(list)
    return list
  }, [layers, cpts])

  useEffect(() => {
    if (lid) {
      setRowSelection({ [lid]: true })
    }
  }, [lid])

  useEffect(() => {
    const firstKey = Object.keys(rowSelection)[0]
    if (firstKey) {
      toggleLayer(firstKey)
    }
  }, [rowSelection, toggleLayer])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    getRowId: (row) => row.id,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <div className="w-full space-y-4">
      <DataTableToolbar table={table} layers={layers} />
      <div className="rounded-md border">
        <MyDataTable table={table} />
      </div>
    </div>
  )
}

export default Layers
