import { Button } from '@/components/ui/button'
import { ShuffleIcon } from '@radix-ui/react-icons'
import { fetcher } from 'api/swr'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { color2Linear } from 'CanvasCore/utils'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

const random = (range) => {
  return Math.floor(Math.random() * range) + 1
}

export const Random = () => {
  const { tid } = useParams()
  const { data: layers } = useSWR(['/layers', { tid }], fetcher)
  const { data: cpts } = useSWR(['/components', { tid }], fetcher)
  const { data: tonings } = useSWR(['/tonings', { tid }], fetcher)

  const initEditor = useEditorStore((state) => state.initEditor)

  const randomLayers = () =>
    layers?.filter((layer) => {
      if (layer.state === -1) {
        return false
      }
      if (layer.opt) {
        const layerRandom = random(10)
        return layerRandom > 3
      }
      return true
    })

  const randomCpts = (layer) => {
    const _cpts = cpts?.filter((c) => c.lid === layer.id)
    if (!_cpts) {
      return null
    }
    const index = random(_cpts?.length) - 1
    const cpt = _cpts[index]
    if (!cpt) return null
    if (cpt.state === -1) return null
    const drawer = {
      cid: cpt.id,
      lid: layer.id,
      x: cpt.crop.x,
      y: cpt.crop.y,
      w: cpt.crop.w,
      h: cpt.crop.h,
      z: layer.z,
    }

    if (cpt.pid) {
      drawer.pid = cpt.pid
    }
    if (layer.toning) {
      drawer.t = layer.toning
    }

    return drawer
  }

  const randomToning = (t) => {
    const toningRandom = random(10)
    if (toningRandom > 7) {
      return null
    }
    const toning = tonings?.find((toning) => toning.id === t)
    if (!toning) return null
    const i = random(toning.colors.length) - 1
    const color = toning.colors[i]
    if (!color) return null
    return {
      key: t,
      value: { default: { id: color.id, color: color2Linear(color.colors) } },
    }
  }

  const randomAvatar = () => {
    const luckLayers = randomLayers()
    const drawers = luckLayers
      .map((layer) => randomCpts(layer))
      ?.filter((c) => c)
    const ts = drawers.map((d) => d.t).filter((t) => t)
    const toningIds = Array.from(new Set(ts))
    const tonings = toningIds
      .map((t) => randomToning(t))
      .filter((t) => t)
      .reduce((acc, curr) => {
        acc[curr.key] = curr.value
        return acc
      }, {})
    const editor = {
      drawers,
      tonings,
    }

    initEditor(tid, editor, true)
  }

  return (
    <Button variant="outline" size="icon" onClick={randomAvatar}>
      <ShuffleIcon className="h-4 w-4" />
    </Button>
  )
}
