import useResizeObserver from '@react-hook/resize-observer'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Image, Layer, Rect, Stage } from 'react-konva'
import { useParams } from 'react-router-dom'
import useImage from 'use-image'
import { useEditorStore } from './uiSlice'
import { filterIt } from './utils'

const TransparentBackground = ({ width, height, cellSize }) => {
  const rows = Math.ceil(height / cellSize)
  const cols = Math.ceil(width / cellSize)
  const rects = []

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      const isEven = (i + j) % 2 === 0
      rects.push(
        <Rect
          key={`${i}-${j}`}
          x={j * cellSize}
          y={i * cellSize}
          width={cellSize}
          height={cellSize}
          fill={isEven ? '#ffffff' : '#cccccc'}
        />,
      )
    }
  }

  return <Layer>{rects}</Layer>
}

const CanvasCore = ({ ow = 512, oh = 512, drawRef }) => {
  const stageRef = useRef(null)
  const containerRef = useRef(null)

  const { tid } = useParams()

  const drawersData = useEditorStore(
    (state) => state.editors[tid]?.drawers ?? [],
  )

  const setStage = useEditorStore((state) => state.setStage)
  const stage = useEditorStore((state) => state.editors[tid]?.stage)

  const drawers = useMemo(
    () => [...drawersData].sort((a, b) => a.z - b.z),
    [drawersData],
  )

  const cal = useCallback(
    (w, h) => {
      if (w > h) {
        const scale = h / oh
        setStage(tid, { w: ow * scale, h, scale, xOffset: 0 })
      } else {
        const scale = w / ow
        setStage(tid, { w, h: oh * scale, scale, xOffset: 0 })
      }
    },
    [tid, setStage, ow, oh],
  )

  useResizeObserver(containerRef, (entry) => {
    const w = entry.contentRect.width
    const h = entry.contentRect.height
    cal(w, h)
  })

  useEffect(() => {
    if (stageRef.current) {
      stageRef.current.draw()
    }
  }, [stage, drawRef])
  return (
    <div
      className="w-full h-full flex items-center justify-center"
      ref={containerRef}
    >
      {stage && (
        <Stage width={stage.w} height={stage.h} ref={stageRef}>
          <TransparentBackground
            width={stage.w}
            height={stage.h}
            cellSize={16}
          />
          <Layer ref={drawRef}>
            {drawers?.map((drawer) => (
              <Drawer
                key={drawer.cid}
                drawer={drawer}
                scale={stage.scale}
                xOffset={stage.xOffset}
                ow={ow}
                oh={oh}
                tid={tid}
              />
            ))}
          </Layer>
        </Stage>
      )}
    </div>
  )
}

const Drawer = ({ drawer, xOffset, scale, ow, oh, tid }) => {
  const { cid, t: toningId } = drawer
  const x = (drawer.x / 512) * ow * scale + xOffset
  const y = (drawer.y / 512) * oh * scale
  const w = (drawer.w / 512) * ow * scale
  const h = (drawer.h / 512) * oh * scale

  const toning = useEditorStore(
    (state) => state.editors[tid]?.tonings?.[toningId]?.default?.color,
  )
  const [image] = useImage(`https://img.zthd.io/an1/ats/${cid}`, 'Anonymous')
  const imageRef = React.useRef(null)

  const filter = useCallback(
    (imageData) => {
      if (!imageData) return
      if (!toning) return
      filterIt(toning, imageData)
    },
    [toning],
  )

  useEffect(() => {
    if (image) {
      // you many need to reapply cache on some props changes like shadow, stroke, etc.
      imageRef.current.cache()
    }
  }, [image, x, y, w, h])

  if (!image) return null
  return (
    <Image
      x={x}
      y={y}
      width={w}
      height={h}
      image={image}
      ref={imageRef}
      filters={[filter]}
    />
  )
}

export default CanvasCore
