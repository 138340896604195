import { cn } from '@/lib/utils'
import React from 'react'
import { Link } from 'react-router-dom'

const MyLink = React.forwardRef(({ className, children, ...props }, ref) => (
  <Link
    ref={ref}
    className={cn('font-medium underline underline-offset-4', className)}
    {...props}
  >
    {children}
  </Link>
))

MyLink.displayName = 'MyLink'

export default MyLink
