import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Badge } from '@/components/ui/badge'
import { cn } from '@/lib/utils'
import { H3 } from 'components/ui/Title'
import React, { useMemo } from 'react'
import LinearColor from './LinearColor'

const ToningPanel = ({ draft, errors }) => {
  const tonings = useMemo(
    () => JSON.parse(draft?.tonings) || [],
    [draft.tonings],
  )

  return (
    <div className="w-full h-full space-y-4 rounded-md border p-4 flex flex-col">
      <Accordion type="multiple">
        {tonings.map((toning) => (
          <Item
            key={toning.id}
            toning={toning}
            error={errors.some((error) => error.id === toning.id)}
          />
        ))}
      </Accordion>
    </div>
  )
}

const Item = ({ toning, error }) => {
  return (
    <AccordionItem value={toning.id}>
      <AccordionTrigger className="py-2">
        <div className="flex items-center gap-4 w-full">
          <div className="flex flex-col gap-2">
            <H3 className={cn(error && 'text-destructive')}>id:{toning.id}</H3>
            {toning.group && (
              <Badge variant="outline">{toning.group.name}</Badge>
            )}
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className="grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 xl:grid-cols-9 grid-cols-9 gap-2 p-1">
          {toning.colors.map((color) => (
            <LinearColor key={color.id} toningId={toning.id} color={color} />
          ))}
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

export default ToningPanel
