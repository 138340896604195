import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useUI } from 'Editor'
import React from 'react'
import InfoPanel from './Info'
import Layers from './Layers'
import Tonings from './Tonings'
import DLCs from './dlcs'

const datas = [
  { name: 'Info', to: 'info' },
  { name: `Layers`, to: 'layers' },
  { name: `Tonings`, to: 'tonings' },
  { name: `DLCs`, to: 'dlcs' },
]

const RightPanel = () => {
  const toggleTab = useUI((state) => state.toggleTab)
  const currTab = useUI((state) => state.currTab)
  return (
    <Tabs
      defaultValue="layers"
      value={currTab}
      onValueChange={(tab) => toggleTab(tab)}
    >
      <TabsList className="lg:gap-4 justify-between">
        {datas.map((data) => (
          <TabsTrigger key={data.to} value={data.to}>
            {data.name}
          </TabsTrigger>
        ))}
      </TabsList>
      <TabsContent value="info">
        <InfoPanel />
      </TabsContent>
      <TabsContent
        value="layers"
        className="overflow-y-auto h-full max-h-screen"
      >
        <Layers />
      </TabsContent>
      <TabsContent
        value="tonings"
        className="overflow-y-auto h-full max-h-screen"
      >
        <Tonings />
      </TabsContent>
      <TabsContent value="dlcs" className="overflow-y-auto h-full max-h-screen">
        <DLCs />
      </TabsContent>
    </Tabs>
  )
}

export default RightPanel
