import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  DotsHorizontalIcon,
  EraserIcon,
  Link2Icon,
  Pencil1Icon,
  ArrowUpIcon,
  EyeOpenIcon,
} from '@radix-ui/react-icons'
import useSWRMutation from 'swr/mutation'
import { postFetcher, putFetcher } from 'api/swr'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
} from '@/components/ui/dropdown-menu'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ContentLangs } from 'config'

export const DataTableRowActions = ({ row }) => {
  const navigate = useNavigate()
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <ClearCache row={row} />
        <StickyTop row={row} />
        <ShowLangs row={row} />
        <DropdownMenuItem
          onSelect={() => navigate(`/admin/frames/${row.original.id}/detail`)}
        >
          <Link2Icon className="w-4 h-4 mr-2" />
          Report
        </DropdownMenuItem>
        <DropdownMenuItem
          onSelect={() => navigate(`/editor/${row.original.id}`)}
        >
          <Pencil1Icon className="w-4 h-4 mr-2" />
          Editor
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const ClearCache = ({ row }) => {
  const data = row.original
  const { trigger, isMutating } = useSWRMutation(
    `/frames/clear/${data.id}`,
    postFetcher,
  )

  const handleClick = (e) => {
    e.preventDefault()
    trigger()
  }

  return (
    <>
      <DropdownMenuItem onSelect={handleClick}>
        <EraserIcon className="mr-2 h-4 w-4" />
        <span>Clear Cache{isMutating ? '...' : null}</span>
      </DropdownMenuItem>
    </>
  )
}

const StickyTop = ({ row }) => {
  const data = row.original
  const { trigger, isMutating } = useSWRMutation(
    `/frames/${data.id}`,
    putFetcher,
  )

  const handleClick = (e) => {
    e.preventDefault()
    trigger({ key: 'stickyTop' })
  }

  return (
    <>
      <DropdownMenuItem onSelect={handleClick}>
        <ArrowUpIcon className="mr-2 h-4 w-4" />
        <span>Sticky Top{isMutating ? '...' : null}</span>
      </DropdownMenuItem>
    </>
  )
}

const ShowLangs = ({ row }) => {
  const data = row.original
  const curLangs = data?.contentLangs || []

  return (
    <>
      <Dialog>
        <DialogTrigger className="w-full">
          <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
            <EyeOpenIcon className="mr-2 h-4 w-4" /> Langs
          </DropdownMenuItem>
        </DialogTrigger>
        <DialogContent className="max-h-screen overflow-y-auto">
          <DialogHeader className={'flex'}>
            <DialogTitle>Show Langs</DialogTitle>
          </DialogHeader>
          <div className="d-flex flex-wrap">
            {ContentLangs.slice(1).map((item) => (
              <Badge
                key={item.name}
                className={'me-1'}
                variant={!curLangs.includes(item.code) ? 'outline' : ''}
              >
                <small>
                  {item.name}-{item.code}
                </small>
              </Badge>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
