import { Button } from '@/components/ui/button'
import { ShuffleIcon } from '@radix-ui/react-icons'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { color2Linear } from 'CanvasCore/utils'
import React from 'react'
import { useParams } from 'react-router-dom'

const random = (range) => {
  return Math.floor(Math.random() * range) + 1
}

const getRandomElement = (arr) => {
  if (arr.length === 0) {
    throw new Error('The array is empty.')
  }
  const randomIndex = Math.floor(Math.random() * arr.length)
  return arr[randomIndex]
}

export const Random = ({ draft }) => {
  const { tid } = useParams()
  const layers = JSON.parse(draft.layers)
  const tonings = JSON.parse(draft.tonings)

  const initEditor = useEditorStore((state) => state.initEditor)

  const randomLayers = () =>
    layers?.filter((layer) => {
      if (layer.opt) {
        const layerRandom = random(10)
        return layerRandom > 3
      }
      return true
    })

  const randomToning = (t) => {
    const toningRandom = random(10)
    if (toningRandom > 7) {
      return null
    }
    const toning = tonings?.find((toning) => toning.id === t)
    if (!toning) return null
    const color = getRandomElement(toning.colors)
    if (!color) return null
    return {
      key: t,
      value: { default: { id: color.id, color: color2Linear(color.colors) } },
    }
  }

  const randomAvatar = () => {
    const luckLayers = randomLayers()
    const drawers = luckLayers.map((layer) => {
      const cpt = getRandomElement(layer.components)
      const drawer = {
        cid: cpt.id,
        lid: layer.id,
        x: cpt.crop.x,
        y: cpt.crop.y,
        w: cpt.crop.w,
        h: cpt.crop.h,
        z: layer.z,
      }

      if (cpt.pid) {
        drawer.pid = cpt.pid
      }
      if (layer.toning) {
        drawer.t = layer.toning
      }
      return drawer
    })
    const ts = drawers.map((d) => d.t).filter((t) => t)
    const toningIds = Array.from(new Set(ts))
    const tonings = toningIds
      .map((t) => randomToning(t))
      .filter((t) => t)
      .reduce((acc, curr) => {
        acc[curr.key] = curr.value
        return acc
      }, {})
    const editor = {
      drawers,
      tonings,
    }

    initEditor(tid, editor, true)
  }

  return (
    <Button variant="outline" size="icon" onClick={randomAvatar}>
      <ShuffleIcon className="h-4 w-4" />
    </Button>
  )
}
