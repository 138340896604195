import React from 'react'
import {
  DataTableRowActions,
  DataTableRowCover,
  DataTableRowCptCount,
  DataTableRowName,
} from './data-table-row-actions'
import { Checkbox } from '@/components/ui/checkbox'

export const columns = [
  {
    id: 'select',
    header: '',
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
  },
  {
    accessorKey: 'cover',
    header: '',
    cell: ({ row }) => <DataTableRowCover row={row} />,
  },
  {
    accessorKey: 'name',
    header: '',
    cell: ({ row }) => <DataTableRowName row={row} />,
  },
  {
    accessorKey: 'id',
    header: 'id',
  },
  {
    id: 'cptCount',
    header: <div className="flex items-center">Components</div>,
    cell: ({ row }) => <DataTableRowCptCount row={row} />,
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
