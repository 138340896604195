import { cn } from '@/lib/utils'
import React from 'react'

const Skeleton = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('rounded-md bg-primary/10', className)}
    {...props}
  />
))
Skeleton.displayName = 'Skeleton'

export default Skeleton
