import useSWR from 'swr'
import { create } from 'zustand'
import React, { useEffect } from 'react'
import DisplayAvatars from './DisplayAvatars'
import DropdownClientV0 from 'components/DropdownClientV0'
import DropdownLang from 'components/DropdownLang'
import DropdownOrder from 'components/DropdownOrder'
import DropdownState from 'components/DropdownState'
import Loader from '@/components/ui/Loader'
import { Button } from '@/components/ui/button'
import MyPagination from 'components/ui/MyPagination'

export const useForm = create((set) => ({
  lang: 9,
  order: 'updateTime',
  state: '401',
  clientVer: null,
  after: null,
  afters: [],
  updateLang: (value) => set({ lang: value, after: null, afters: [] }),
  updateOrder: (value) => set({ order: value, after: null, afters: [] }),
  updateState: (value) => set({ state: value, after: null, afters: [] }),
  updateClientVer: (value) =>
    set({ clientVer: value, after: null, afters: [] }),
  setOrder: (order) => set({ order, after: null, afters: [] }),
  goAfter: (nextAfter) =>
    set((state) => {
      if (nextAfter === null) {
        return { after: null }
      }
      const index = state.afters.findIndex((a) => a === nextAfter)
      if (index === -1) {
        return { after: nextAfter, afters: [...state.afters, nextAfter] }
      } else {
        return { after: nextAfter }
      }
    }),
}))

const Index = () => {
  //TODO: taglibs should be fetched in the background
  const { mutate: refetch } = useSWR(['/tagLibs'])

  const params = useForm((s) => {
    const { lang, order, state, clientVer, after } = s
    return { lang, order, state, clientVer, after }
  })

  const {
    afters,
    after,
    goAfter,
    lang,
    updateLang,
    order,
    updateOrder,
    state,
    updateState,
    clientVer,
    updateClientVer,
  } = useForm((s) => s)

  const {
    data: avatars,
    isLoading,
    isValidating,
    mutate,
  } = useSWR(['/avatars', params])

  useEffect(() => {
    refetch()
  }, [refetch])

  const nextAfter = avatars?.[avatars.length - 1]?.id
  return (
    <>
      <div className="flex items-center gap-4 py-2">
        <DropdownLang value={lang} update={updateLang} />
        <DropdownOrder value={order} update={updateOrder} />
        <DropdownState value={state} update={updateState} />
        <DropdownClientV0 value={clientVer} update={updateClientVer} />
        <Button variant="outline" onClick={() => mutate()}>
          Fetch
        </Button>
      </div>
      {(isLoading || isValidating) && <Loader />}
      <MyPagination
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
      {avatars?.length <= 0 && <div>no avatars</div>}
      <DisplayAvatars avatars={avatars} />
      <MyPagination
        nextAfter={nextAfter}
        after={after}
        goAfter={goAfter}
        afters={afters}
      />
    </>
  )
}

export default Index
