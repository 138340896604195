import { Button } from '@/components/ui/button'
import { ReloadIcon } from '@radix-ui/react-icons'
import React from 'react'

const DataTableToolbar = ({ mutate }) => {
  return (
    <div className="mb-2">
      <Button variant="outline" onClick={() => mutate()}>
        <ReloadIcon className="h-4 w-4 mr-2" />
        Refetch
      </Button>
    </div>
  )
}

export default DataTableToolbar
