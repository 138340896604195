import { Separator } from '@/components/ui/separator'
import {
  Component1Icon,
  DimensionsIcon,
  LayersIcon,
} from '@radix-ui/react-icons'
import MyLink from 'components/ui/MyLink'
import { Description, H2 } from 'components/ui/Title'
import React from 'react'

const InfoPanel = ({ draft }) => {
  return (
    <div className="w-full h-full space-y-4 rounded-md border p-4 flex flex-col">
      <H2>{draft.name}</H2>
      <Description>ID: {draft.id}</Description>
      {draft.type === 'template' && (
        <Description>Short id: {draft.shortId}</Description>
      )}
      <Description>Intro: {draft.intro}</Description>
      <div className="flex items-center gap-4">
        <div>
          <p>Cover</p>
          <img
            alt="..."
            className="aspect-square rounded-md object-cover w-24 h-24"
            src={draft.cover}
          />
        </div>
        {draft.type === 'template' && (
          <div>
            <p>Preview</p>
            <img
              alt="..."
              className="aspect-square rounded-md object-cover w-24 h-24"
              src={draft.preview}
            />
          </div>
        )}
      </div>
      <div className="flex items-center gap-4">
        {draft.type === 'template' && (
          <div className="flex items-center">
            <DimensionsIcon className="w-4 h-4 mr-2" />
            {draft.ow} x {draft.oh}
          </div>
        )}
        <div className="flex items-center">
          <LayersIcon className="w-4 h-4 mr-2" />
          {draft.layerCount}
        </div>
        <div className="flex items-center">
          <Component1Icon className="w-4 h-4 mr-2" />
          {draft.componentCount}
        </div>
      </div>
      <div className="flex gap-2">
        <MyLink to={`/admin/publishers/${draft.painter || draft.author}`}>
          {draft.publisher}
        </MyLink>
        <MyLink to={`/admin/publishers/${draft.author}`}>
          {draft.onlinePublisher}
        </MyLink>
      </div>
      <Separator className="my-4" />
      {draft.type === 'template' && (
        <Description>level: {draft?.ext?.level}</Description>
      )}
      <Description>
        tags:{' '}
        {draft?.ext?.tags?.map((item) => (
          <span key={item}>{item}、</span>
        ))}
      </Description>
      {draft.type === 'template' && (
        <Description>
          labels:{' '}
          {draft?.ext?.labels?.map((item) => (
            <span key={item}>{item}、</span>
          ))}
        </Description>
      )}
      {draft.type === 'template' && (
        <Description>
          contentLangs:{' '}
          {draft?.ext?.contentLangs?.map((item) => (
            <span key={item}>{item}、</span>
          ))}
        </Description>
      )}

      {draft.type === 'template' && (
        <Description>simpleLimit: {draft?.ext?.simpleLimit}</Description>
      )}
      {draft.type === 'dlc' && (
        <Description>sku: {draft?.ext?.sku}</Description>
      )}
    </div>
  )
}

export default InfoPanel
