import React from 'react'
import { DataTableRowActions } from './data-table-row-action'

export const columns = (path) => {
  return [
    {
      header: '#',
      cell: ({ row }) => row.index + 1,
    },
    {
      header: 'tid',
      cell: ({ row }) => row.original,
    },
    {
      header: 'Actions',
      cell: ({ row }) => <DataTableRowActions row={row} path={path} />,
    },
  ]
}
