import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import {
  DotsHorizontalIcon,
  Link2Icon,
  Pencil1Icon,
} from '@radix-ui/react-icons'

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ErrorsNote } from 'components/template-data-table-column'

export const DataTableRowActions = ({ row }) => {
  const navigate = useNavigate()
  return (
    <div className="flex gap-3 items-center">
      <Button asChild>
        <Link to={'/editor/' + row.original.tid}>
          <Pencil1Icon className="h-4 w-4 mr-2" /> Paint now
        </Link>
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem
            onSelect={() =>
              navigate(`/admin/frames/${row.original.tid}/avatars`)
            }
          >
            <Link2Icon className="w-4 h-4 mr-2" />
            Avatar
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ErrorsNote row={row} />
    </div>
  )
}
