import React from 'react'
import Dropdown from 'components/ui/Selector'
const Items = [
  { code: 'updateTime', name: 'updateTime' },
  {
    code: 'dailyCount.reCreateCount',
    name: 'dailyCount.reCreateCount',
  },
  { code: 'reCreateCount', name: 'reCreateCount' },
  {
    code: 'dailyCount.downloadCount',
    name: 'dailyCount.downloadCount',
  },
  { code: 'dCount', name: 'dCount' },
]

const CustomDropdown = ({ value, update }) => {
  return (
    <Dropdown
      datas={Items}
      showLabel
      labelName="Order"
      value={value}
      update={update}
    />
  )
}

export default CustomDropdown
