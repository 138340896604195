import { Button } from '@/components/ui/button'
import { Component1Icon, EraserIcon, LayersIcon } from '@radix-ui/react-icons'
import { useEditorStore } from 'CanvasCore/uiSlice'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Random } from './Random'

const Overlay = ({ draft }) => {
  return (
    <div className="absolute top-0 left-0 w-full">
      <div className="flex items-center gap-4">
        <div className="flex items-center">
          <Component1Icon className="w-4 h-4 mr-2" />
          {draft.componentCount}
        </div>
        <div className="flex items-center">
          <LayersIcon className="w-4 h-4 mr-2" /> {draft.layerCount}
        </div>
        <ResetButton />
        <Random draft={draft} />
      </div>
    </div>
  )
}

const ResetButton = () => {
  const { tid } = useParams()
  const resetCanvas = useEditorStore((state) => state.resetCanvas)
  return (
    <Button
      className="ml-auto"
      variant="outline"
      size="icon"
      onClick={() => resetCanvas(tid)}
    >
      <EraserIcon className="h-4 w-4" />
    </Button>
  )
}

export default Overlay
