import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

const Layout = () => {
  const { tag } = useParams()
  return (
    <div className="flex flex-col">
      <div className="my-4">Tag:{tag}</div>
      <Outlet />
    </div>
  )
}

export default Layout
