import { Button } from '@/components/ui/button'
import { AvatarIcon } from '@radix-ui/react-icons'
import { useAuthStore } from 'api/swr'
import Navbar from 'components/Navbar'
import React from 'react'
import { Link } from 'react-router-dom'

const Nav = () => {
  const user = useAuthStore((state) => state.auth?.user)
  const logout = useAuthStore((state) => state.removeToken)

  return (
    <Navbar sticky name="Neku">
      <Button variant="ghost" asChild>
        <Link to="/login" onClick={logout}>
          <AvatarIcon className="w-6 h-6 mr-2" />
          {user.username} Log Out
        </Link>
      </Button>
    </Navbar>
  )
}

export default Nav
