import { formatTime } from 'commons'
import React from 'react'

const DisplayUpdateTime = ({ updateTime }) => {
  const [showTime, ago] = formatTime(updateTime)
  if (!showTime) {
    return null
  }
  return (
    <span>
      {showTime} ({ago})
    </span>
  )
}

export default DisplayUpdateTime
