import React from 'react'
import ReactGPicker from 'react-gcolor-picker'

const AdjustColor = ({ value, onChange }) => {
  if (!value || !onChange) return null
  return (
    <div className="flex flex-col w-full items-center justify-center gap-1">
      <ReactGPicker
        value={value}
        onChange={(v) => onChange(v)}
        gradient
        debounceMS={10}
        solid={false}
        format="hex"
        showAlpha={false}
        showInputs
        allowAddGradientStops
        showGradientMode={false}
        showGradientAngle={false}
        showGradientResult={false}
        defaultColors={[]}
      />
    </div>
  )
}

export default AdjustColor
