import { Badge } from '@/components/ui/badge'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { ImageIcon, StackIcon } from '@radix-ui/react-icons'
import { useEditorStore } from 'CanvasCore/uiSlice'
import { CptCard } from 'Editor/BottomPanel/LayerPanel/ui'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

const Cpt = ({ cpt, cpts, layer }) => {
  const { id } = cpt

  const childs = useMemo(() => cpts?.filter((c) => c.pid === id), [id, cpts])

  if (cpt.pid) return null

  if (childs?.length > 0) {
    return <CptStack cpt={cpt} layer={layer} childs={childs} />
  }

  if (id === '229e99b6') {
    console.log('why?', cpt)
    console.log(childs)
  }

  return <CptNormal cpt={cpt} layer={layer} />
}

const CptNormal = ({ cpt, layer }) => {
  const { tid } = useParams()
  const { id, cover } = cpt

  const selected = useEditorStore((state) =>
    state.editors[tid]?.drawers?.some((d) => d.cid === id),
  )
  const selectCpt = useEditorStore((state) => state.selectCpt)
  const deselectCpt = useEditorStore((state) => state.deselectCpt)

  const handleClick = () => {
    if (selected) {
      deselectCpt(tid, cpt.id)
    } else {
      selectCpt(tid, layer, cpt)
    }
  }

  return (
    <CptCard
      onClick={handleClick}
      className={cn(selected && 'border-2 border-primary')}
    >
      <div className="w-full h-full relative">
        <img
          className="w-full h-full aspect-square object-contain rounded-xl"
          src={`https://img.zthd.io/an1/ats/${cover ?? id}`}
          alt=""
        />
        {cpt.cover && (
          <ImageIcon className="w-4 h-4 text-primary absolute top-1 left-1" />
        )}
        <Badge variant="outline" className="absolute bottom-1 left-1 px-1">
          {cpt.pro}
        </Badge>
        <Badge variant="outline" className="absolute bottom-1 right-1 px-1">
          {cpt.iOSPro}
        </Badge>
      </div>
    </CptCard>
  )
}

const CptStack = ({ cpt, layer, childs }) => {
  const { tid } = useParams()
  const { id, cover } = cpt

  const selected = useEditorStore((state) =>
    state.editors[tid]?.drawers?.some((d) => d.cid === id || d.pid === id),
  )

  return (
    <Popover>
      <PopoverTrigger asChild>
        <CptCard className={cn(selected && 'border-2 border-primary')}>
          <div className="w-full h-full relative">
            <img
              className="w-full h-full aspect-square object-contain rounded-xl"
              src={`https://img.zthd.io/an1/ats/${cover ?? id}`}
              alt=""
            />
            <div className="absolute top-1 right-2 flex items-center text-primary gap-0.5 bg-gray-100 rounded-md px-1 ">
              <StackIcon className="w-4 h-4 " />
              {childs.length + 1}
            </div>
          </div>
        </CptCard>
      </PopoverTrigger>
      <PopoverContent className="max-h-[80vh] min-w-[40vw] xl:min-w-[30vw]">
        <div className="overflow-y-auto">
          <div className="grid grid-cols-6 lg:grid-cols-5 xl:grid-cols-5 gap-2 p-1 ">
            <CptNormal cpt={cpt} layer={layer} />
            {childs?.map((child) => (
              <CptNormal key={child.id} cpt={child} layer={layer} />
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default Cpt
