import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu'

import useSWRMutation from 'swr/mutation'
import useSWR, { mutate } from 'swr'
import { fetcher, putFetcher } from 'api/swr'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog'
import Divider from 'components/ui/Divider'
import Loader from '@/components/ui/Loader'
import { LayersIcon } from '@radix-ui/react-icons'

const ChangeLayer = ({ cpt, cpts }) => {
  const { tid } = useParams()
  const [open, setOpen] = useState(false)

  const { data: layers, isLoading } = useSWR(['/layers', { tid }], fetcher)
  const childs = useMemo(
    () => cpts?.filter((c) => c.pid === cpt.id),
    [cpt.id, cpts],
  )
  const { trigger, isMutating } = useSWRMutation(
    `/components/${cpt.id}`,
    putFetcher,
  )

  const currLayer = useMemo(
    () => layers?.find((layer) => layer.id === cpt.lid),
    [cpt.lid, layers],
  )

  const handleUpdate = (lid) => {
    if (isMutating) return false
    trigger(
      { tid, key: 'lid', value: lid },
      {
        onSuccess: () => {
          setOpen(false)
          mutate(['/components', { tid }])
        },
      },
    )
  }

  if (isLoading) {
    return (
      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
        fetch layers...
      </DropdownMenuItem>
    )
  }

  if (cpt.pid) {
    return (
      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
        <LayersIcon className="w-4 h-4 mr-2" /> Change layer:has pid
      </DropdownMenuItem>
    )
  }
  if (childs.length > 0) {
    return (
      <DropdownMenuItem onClick={(e) => e.preventDefault()}>
        <LayersIcon className="w-4 h-4 mr-2" /> Bound layer:has childs
      </DropdownMenuItem>
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <LayersIcon className="w-4 h-4 mr-2" />
          Change layer
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh]">
        <DialogHeader>
          <DialogTitle>Change layer</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <div className="flex flex-col">
          <div className="flex gap-4">
            <div>
              curr cpt:
              <img
                alt="cpt original"
                src={`https://img.zthd.io/an1/ats/${cpt.cover ?? cpt.id}`}
                className="h-24 w-24 object-cover transition-all hover:scale-105 aspect-square border hover:border-primary rounded-xl"
              />
            </div>
            {currLayer && (
              <div>
                curr layer:
                <img
                  src={`https://img.zthd.io/an1/acs/${currLayer?.cover}`}
                  alt="cpt curr layer"
                  className="h-24 w-24 object-cover transition-all hover:scale-105 aspect-square border hover:border-primary rounded-xl"
                />
              </div>
            )}
          </div>
          <Divider className="mt-1" />
          <div className="flex items-center gap-2">
            <h3 className="text-xl font-bold">Layers</h3>{' '}
            <span>(click to change layer)</span>
            {isMutating && <Loader />}
          </div>
          <div className="flex-1 overflow-y-auto max-h-[50vh]">
            <div className="grid sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 grid-cols-9 gap-2 p-1 overflow-y-auto">
              {layers?.map((layer) => (
                <div
                  key={layer.id}
                  onClick={() => handleUpdate(layer.id)}
                  className="flex flex-col border hover:border-primary"
                >
                  <img
                    src={`https://img.zthd.io/an1/acs/${layer.cover}`}
                    alt="layer cover"
                  />
                  {layer.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ChangeLayer
