import { cn } from '@/lib/utils'
import React, { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  DialogHeader,
} from '@/components/ui/dialog'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { Separator } from '@/components/ui/separator'
import { Button } from '@/components/ui/button'

const AvatarPicker = ({ original, children, onSubmit }) => {
  const { tid } = useParams()
  const { data: avatars } = useSWR(['/avatars', { tid, state: 0 }])
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState({ id: original })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-h-[80vh] gap-4">
        <DialogHeader>
          <DialogTitle>Update Cover</DialogTitle>
          <DialogDescription>
            Modify and update your cover image.
          </DialogDescription>
        </DialogHeader>
        <Separator className="my-4" />
        <div className="flex-1 overflow-y-auto max-h-[50vh]">
          <div className="grid grid-cols-4 gap-2 px-1">
            {avatars?.map((avatar) => (
              <AvatarItem
                key={avatar.id}
                avatar={avatar}
                selected={selected?.id === avatar.id}
                onClick={() => setSelected(avatar)}
              />
            ))}
          </div>
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              onSubmit(selected)
              setOpen(false)
            }}
          >
            Select
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const AvatarItem = ({ avatar, selected, ...props }) => {
  return (
    <div className="flex flex-col items-center" {...props}>
      <div
        className={cn(
          'bg-white border-2 border-transparent rounded-xl w-full',
          'flex items-center justify-center group',
          'hover:border-primary',
          selected && 'border-primary',
        )}
      >
        <div className="w-full h-full relative">
          <img
            alt="..."
            className="w-full h-full aspect-square object-contain rounded-xl"
            src={avatar?.thumb ?? avatar.url}
          />
        </div>
      </div>
    </div>
  )
}

export default AvatarPicker
